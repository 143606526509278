// Footer.js
import React from 'react';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook'; 
import './Footer.css';
import { useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme()
  return (
    <footer>
      <div className="footerContainer" style={{
      background: `${theme.palette.background.default}`,
      color:`${theme.palette.text.primary}`,
    }}>
        <div className="footerSection">
          <div className='footerCompanyName'>LIST</div>
          <div>&copy; {new Date().getFullYear()} Link Insertion Suggestions Tool. All rights reserved</div>
          <div>
            <p>26 No. Binodini Avenue, Hatiara Dhankol Road, Near Binodini Avenue park Kolkata - 700157</p>
          </div>
        </div>

        <div className="footerSection">
          <div className='footerContact'>
            <div>+91 6290 382 261</div>
            <div>hr.roboanalytics@gmail.com</div>
          </div>
          {/* <div className='footerIcons'>
            <LinkedInIcon className='footerIcon' />
            <InstagramIcon className='footerIcon' />
            <FacebookIcon className='footerIcon' />
          </div> */}
        </div>
      </div>

      {/* <div className='footerPoweredBy'>Powered by Your Company</div> */}
    </footer>
  );
}

export default Footer;
