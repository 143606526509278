import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FiMenu,
  FiX,
  FiUser,
  FiSettings,
  FiInfo,
  FiPhone,
} from "react-icons/fi";
import {
  FaAngleDoubleUp,
  FaAngleDoubleDown,
  FaUsersCog,
  FaHome,
} from "react-icons/fa";
import {
  GrProjects,
  GrHomeRounded,
  GrDocumentVerified,
  GrUserSettings,
} from "react-icons/gr";
import { MdCardMembership } from "react-icons/md";
import { VscHome } from "react-icons/vsc";
import { FaPeopleLine } from "react-icons/fa6";
import PeopleIcon from "@mui/icons-material/People";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { FcManager } from "react-icons/fc";
import { MdCampaign } from "react-icons/md";
import { ImCoinDollar } from "react-icons/im";
import { RiDeleteBin6Fill } from "react-icons/ri";

import "./AdminSideBar.css";
import Point_deduction from "../../Point_Deduction/Point_deduction";

const AdminSideBar = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);
  const drawerWidth = 240;
  const handleHomeClick = () => {
    navigate("/");
  };

  const menuItems = [
    {
      text: "Home",
      icon: <GrHomeRounded />,
      link: "/",
    },

    {
      text: "Roles",
      icon: <GrDocumentVerified />,
      link: "/admin/roles",
    },
    {
      text: "Users",
      icon: <GrUserSettings />,
      link: "/admin/users",
    },
    {
      text: "Teams",
      icon: <PersonOutlineIcon />,
      link: "/admin/teams",
    },

    // {
    //   text: 'Team Members',
    //   icon: <FaPeopleLine  />,
    //   link: '/admin/team_members'
    // },
    {
      text: "Workspaces",
      icon: <GrProjects />,
      link: "/admin/workspaces",
    },
    {
      text: "Campaign",
      icon: <MdCampaign />,
      link: "/admin/campaigns",
    },
    {
      text: "Check Deductions",
      icon: <ImCoinDollar />,
      link: "/admin/credit_point_manage",
    },
    {
      text: "Set Deduction",
      icon: <MdCardMembership />,
      link: "/admin/points_deduction",
    },
    {
      text: "Subscriptions",
      icon: <MdCardMembership />,
      link: "/admin/subscriptions",
    },
  ];
  if (localStorage.getItem("role_name") === "super_admin") {
    menuItems.push({
      text: "All Delete Data",
      icon: <RiDeleteBin6Fill />,
      link: "/admin/all_delete_data",
    });
  }
  // const additionalItems = [
  //   { text: 'About Us', icon: <FiInfo />, link: '/aboutUs' },
  //   { text: 'Contact Us', icon: <FiPhone />, link: '/contact' },
  //   { text: 'Account', icon: <FiUser />, link: '/account' },
  //   { text: 'Settings', icon: <FiSettings />, link: '/drafts' },
  // ];

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const [showAdminSubMenu, setShowAdminSubMenu] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState({});

  useEffect(() => {
    // Reset sub-menu state when the drawer is closed
    setShowAdminSubMenu(false);
  }, [drawerOpen]);

  const handleDrawerEnter = () => {
    setDrawerOpen(true);
  };

  const handleDrawerLeave = () => {
    setDrawerOpen(false);
  };

  const handleMenuIconClick = () => {
    setDrawerOpen(false);
  };

  const navigate = useNavigate();

  const handleAdminPanelClick = () => {
    setShowAdminSubMenu(!showAdminSubMenu);
  };
  const handleSubMenuClick = (text) => {
    setShowSubMenu((prev) => ({
      ...prev,
      [text]: !prev[text],
    }));
  };
  useEffect(() => {
    if (!drawerOpen) {
      // Reset submenu state when the drawer is closed
      setShowSubMenu({});
    }
  }, [drawerOpen]);
  const theme = useTheme();
  const HeaderItem = (
    <div className="drawerHeader">
      <div className="headerLeft icon">
        {drawerOpen ? (
          <FiX onClick={handleMenuIconClick} />
        ) : (
          <FiMenu onClick={handleMenuIconClick} />
        )}
      </div>
      {drawerOpen && (
        <div className="headerRight">
          <div className="companyName" onClick={handleHomeClick}>
            <div
              style={{
                fontFamily: "'Arial Black', Gadget, sans-serif",
                letterSpacing: "2px",
              }}
            >
              LiNKoMaTiC
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Drawer
      variant="permanent"
      aria-label="open drawer"
      open={drawerOpen}
      onMouseEnter={handleDrawerEnter}
      onMouseLeave={handleDrawerLeave}
      className="drawer"
    >
      <div>
        <List id="leftsection_list">
          {HeaderItem}
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding className="listItem">
              <ListItemButton
                component={Link}
                to={item.link}
                className={`listItemButton ${drawerOpen ? "drawerOpen" : ""} ${
                  currentPage === item.link ? "active" : ""
                }`}
              >
                <ListItemIcon
                  className="listItemIcon icon"
                  style={{
                    color:
                      currentPage === item.link
                        ? "#5E92D1"
                        : theme.palette.logo.text,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <List>
          {additionalItems.map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              className='listItem'
            >
              <ListItemButton
                component={Link}
                to={item.link}
                className={`listItemButton ${drawerOpen ? 'drawerOpen' : ''}`}
              >
                <ListItemIcon
                  className='listItemIcon icon'
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </div>
    </Drawer>
  );
};

export default AdminSideBar;
