import AdminSideBar from "../common/AdminSideBar/AdminSideBar";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";

import {
  ADD_NEW_TEAM_BY_OWNER_ID,
  DELETE_TEAM,
  UPDATE_TEAM,
  GET_ALL_USERS,
  getFullApiUrl,
  GET_ALL_TEAM_BY_OWNER_ID,
  GET_ALL_TEAM,
} from "../../../utils/apiPath"
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link as MuiLink
} from "@mui/material";
import { IoMdCloseCircle } from "react-icons/io";
import { LiaExpeditedssl } from "react-icons/lia";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import { BsPersonWorkspace } from "react-icons/bs";


import { IoMdOpen } from "react-icons/io";
import Workspace from "../Team_Workspace/Team_Workspace";
import Team_Members from "../Team_Members/Team_Members"
import { Home, NavigateNext } from "@mui/icons-material";
import Team_Workspace from "../Team_Workspace/Team_Workspace";
import Header from "../../common/Header/Header";


const Teams = ({ toggleTheme }) => {
  const theme = useTheme();
  const [team_data, set_team_data] = useState([]);
  const [selected_team, set_selected_team] = useState([]);
  const [selectedTeamsForDelete, setselectedTeamsForDelete] = useState([]); // State to store selected teams for deletion
  const [showDeleteTeamModal, setshowDeleteTeamModal] = useState(false);
  const [selectedTeamsForBulkDelete, setselectedTeamsForBulkDelete] = useState(
    []
  );
  const owner_id = localStorage.getItem("user_id");

  // State to store the selected team for deletion

  const [selectedTeam, setselectedTeam] = useState({
    team_id: "",
    team_name: "",
    team_description: "",
    owner_id: "",
  });
  const [newTeamData, setnewTeamData] = useState({
    owner_id: localStorage.getItem("user_id"),
    team_name: "",
    team_description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedTeam({ ...selectedTeam, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setnewTeamData({ ...newTeamData, [name]: value });
  };
  //   add team...

  const handleAddTeamSubmit = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(ADD_NEW_TEAM_BY_OWNER_ID)}?owner_id=${
          newTeamData.owner_id
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTeamData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add new team");
      }

      // Clear the form data after successful submission
      setnewTeamData({
        owner_id: localStorage.getItem("user_id"), // Ensure it's set before using it
        team_name: "",
        team_description: "",
      });

      fetchData(); // Assuming fetchData is defined somewhere
      setShowAddteamForm(false);

      console.log("New Team added successfully");
    } catch (error) {
      console.error("Error adding new Team:", error.message);
    }
  };
  const fetchData = async () => {
    await fetchteamList();
  };
  useEffect(() => {
    fetchData();
  }, [1000]);

  // list.....

  const fetchteamList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_TEAM)}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("data", data);
      set_team_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleIndividualDeleteClick = (team) => {
    setselectedTeamsForDelete([team]); // Select only the clicked team
    setshowDeleteTeamModal(true);
  };

  const handleBulkTeamDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAM));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allteams = await response.json();

      // Extract team details including name and email
      const TeamDetails = params.map((teamId) => {
        const teams = allteams.find((u) => u.team_id === teamId);
        console.log("ss", teams);
        return teams ? `${teams.team_name} (${teams.team_description})` : "";
      });
      console.log("detail", TeamDetails);

      setselectedTeamsForBulkDelete(params);
      setselectedTeamsForDelete(TeamDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedTeamsForBulkDelete)
    try {
      if (
        !selectedTeamsForBulkDelete ||
        !Array.isArray(selectedTeamsForBulkDelete)
      ) {
        console.error("Invalid or empty params:", selectedTeamsForBulkDelete);
        return;
      } else {
        await handleBulkDelete(selectedTeamsForBulkDelete);
        // Close modal and refresh team list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting teams in bulk:", error.message);
    }
  };

  const handleDeleteteamConfirm = async () => {
    try {
      await Promise.all(
        selectedTeamsForDelete.map((team) => handleDeleteteam(team.team_id))
      );
      setselectedTeamsForDelete([]);
      setshowDeleteTeamModal(false);
      fetchData(); // Refresh team list after deletion
    } catch (error) {
      console.error("Error deleting teams:", error.message);
    }
  };

  const handleBulkDelete = async (selected_team) => {
    set_selected_team(selected_team);
    try {
      // Iterate over selected teams and delete each one
      for (let teamId of selected_team) {
        await handleDeleteteam(teamId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the team list
      fetchData();
    } catch (error) {
      console.error("Error deleting teams in bulk:", error.message);
    }
  };

  const handleDeleteteam = async (teamId) => {
    try {
      const response = await fetch(getFullApiUrl(DELETE_TEAM), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ team_id: teamId }),
      });

      if (!response.ok) {
        const data = await response.json();
        alert(data.error);

        throw new Error("Failed to delete team");
      }

      console.log("team deleted successfully:", teamId);
      // Refresh team list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting team:", error.message);
    }
  };
  // Edit..

  const handleEditteam = (team) => {
    setselectedTeam(team);
    setShowEditteamModal(true);
  };

  const handleEditteamData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_TEAM), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTeam),
      });

      if (!response.ok) {
        throw new Error("Failed to edit team");
      }

      console.log("team edited successfully");
      setShowEditteamModal(false);
      // Refresh team list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing team:", error.message);
    }
  };

  //model open

  const [showAddteamForm, setShowAddteamForm] = React.useState(false);
  const [showEditteamModal, setShowEditteamModal] = React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddteamForm(true);
  const handleAddClose = () => setShowAddteamForm(false);
  const handleEditOpen = () => setShowEditteamModal(true);
  const handleEditClose = () => setShowEditteamModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const handleDeleteteamModalClose = () => {
    setshowDeleteTeamModal(false);
  };

  // search.....
  const [selectedUser, setSelectedUser] = useState(null);
  const [user_data, set_user_data] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_user_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // useEffect(() => {
  //   fetchUserList();
  // }, []);
  // const handleSearchInputChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);

  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewTeamData({
      ...newTeamData,
      owner_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };
  const [teamId, setTeamId] = useState(null);
  const navigate = useNavigate();

  const handleOpenTeamWorkspaceSection = (team) => {
    const team_id = team.team_id;
    // Set the teamId state
    setTeamId(team_id);
    console.log(teamId)

    // Navigate to the MyTeam page
    navigate(`/admin/team_workspace/${team_id}`);
  };
  const handleOpenTeamMemberSection = (team) => {
    const team_id = team.team_id;
    // Set the teamId state
    setTeamId(team_id);
    console.log(teamId)

    // Navigate to the MyTeam page
    navigate(`/admin/team_members/${team_id}`);
  };

  return (
    <div className="page_Section">
    <div className="leftSection_withMenu">
      <AdminSideBar />
    </div>
    <div className="rightSection_withMenu">
    <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
        <Breadcrumbs id="Breadcrumbs" separator={<NavigateNext />} aria-label="breadcrumb">
            <MuiLink component={Link} to="/admin" color="inherit" underline="hover">
              
              Admin
            </MuiLink>
            <Typography color="textPrimary"> Teams</Typography>
          </Breadcrumbs>
          <div className="admin_page_heading">Teams Management</div>
          <div>
            <TableWithPagination
              data={team_data}
              fields={[
                {
                  type: "id",
                  field: "team_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 380,
                },
                {
                  type: "field",
                  field: "team_name",
                  headerName: "Team Name",
                  search_type: "text_search",
                  minWidth: 250,
                  width: 300,
                  maxWidth: 1800,
                },
                { 
                  type: "field",
                  field: "team_description",
                  headerName: "Team Description",
                  search_type: "text_search",
                  minWidth: 250,
                  width: 500,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "owner_name",
                  headerName: "Owner Name",
                  search_type: "text_search",
                  minWidth: 250,
                  width: 300,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "created_at",
                  headerName: "Created At",
                  search_type: "date_search",
                  minWidth: 250,
                  width: 300,
                  maxWidth: 1800,
                },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  width: 175,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEditteam(params)}>
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Open Workspace">
                          <IconButton
                            onClick={() => handleOpenTeamWorkspaceSection(params)}
                          >
                            <IoMdOpen className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Open Team Members ">
                          <IconButton
                            onClick={() => handleOpenTeamMemberSection(params)}
                          >
                            <BsPersonWorkspace className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add New Team">
                        <div
                          onClick={handleAddOpen}
                          className="bulk_classify_button download_icons"
                        >
                          <RiAddLine />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Team">
                        <div
                          onClick={() => handleBulkTeamDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30}/>
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            {teamId && <Team_Workspace teamId={teamId}  />}
            {teamId && <Team_Members teamId={teamId}  />}

            {/* Add team.... */}    

            <Modal                
              open={showAddteamForm}                                                              
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New Team Name</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="team_name"
                      label="Team Name"
                      variant="standard"
                      name="team_name"
                      value={newTeamData.team_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="team_description"
                      label="Team Description"
                      variant="standard"
                      name="team_description"
                      value={newTeamData.team_description}
                      onChange={handleNewInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                    />
                  </Box>
                  
                  <button
                    onClick={handleAddTeamSubmit}
                    className="add-user-btn"
                  >
                    Add Team{" "}
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update.... */}

            <Modal
              open={showEditteamModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Team</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="team_name"
                      label="Team Name"
                      variant="standard"
                      name="team_name"
                      value={selectedTeam ? selectedTeam.team_name : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="team_description"
                      label="Team Description"
                      variant="standard"
                      name="team_description"
                      value={selectedTeam ? selectedTeam.team_description : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <button onClick={handleEditteamData} className="add-user-btn">
                    Update Team
                  </button>
                </div>
              </Box>

              {/* delete... */}
            </Modal>

            {/* delete... */}

            <Modal
              open={showDeleteTeamModal}
              onClose={handleDeleteteamModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Team(s)</div>
                  {selectedTeamsForDelete.map((team) => (
                    <div key={team.team_id}>
                      <div className="modal_text">
                        Team: {team.team_name} ({team.team_description})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleDeleteteamConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleDeleteteamModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Bulk delete.... */}

            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Team(s)</div>

                  {selectedTeamsForDelete.map((team) => (
                    <div key={team}>
                      <div className="modal_text">Team Name: {team} </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
