import React, { useState, useEffect, useRef } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  Snackbar,
  Modal,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from '@mui/icons-material/Save';
import { saveAs } from "file-saver";
import "./BulkProcessPage.css";
import Header from "../common/Header/Header";
import {
  DOWNLOAD_ANALYZED_BLOG_DATA_BY_CLIENT_URLS,
  GET_BLOG_URLS_DATA,
  GET_CLIENT_URLS_DATA,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  CHECK_CREDIT_CONDITION,
  SAVE_SIMILARITY_SCORE,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  getFullApiUrl,
  PROCESS_SELECTED_CLIENTS,
  getFullApiUrlForBulkProcess,
  GET_BLOG_URLS_LENGTH,
  GET_CLIENT_URLS_LIST,
  BUCKET_NAME,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import CloseIcon from "@mui/icons-material/Close";

const BulkProcessPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [show_should_we_bulk_process_urls_modal, set_show_should_we_bulk_process_urls_modal] = useState(false);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] = useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] = useState("");
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] = useState(0);
  const [showSaveModal, setShowSaveModal] = useState(false);
  useEffect(() => {
    getCampaignDetails();
  }, []); // Fetch credit point details on component mount

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );
      fetchpointsDetails();
      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_bulk_process_urls_close = () => {
    set_show_should_we_bulk_process_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const [client_urls_data, set_client_urls_data] = useState([]);
  const [selected_client_urls, set_selected_client_urls] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [progress, set_progress] = useState([]);
  //const [progress_not, set_progress_not] = useState([]);

  // const [blog_urls_data, set_blog_urls_data] = useState([]);

  const [initial_data_loaded_client, set_initial_data_loaded_client] = useState(0);
  const [blog_url_length, set_blog_url_length] = useState(0)
  useEffect(() => {
    set_initial_data_loaded_client(0);

    const fetchClientUrlsData = fetch(getFullApiUrl(GET_CLIENT_URLS_LIST), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Set the client URLs data with the response data
        set_client_urls_data(data);
    
        // Initialize the progress array based on the length of the received data
        const initialProgress = data.map(() => ({
          progress: 0,
          scored: 0,
          not_scored: 0,
        }));
    
        // Set the progress state with the initialized progress array
        set_progress(initialProgress);
      })
      .catch((error) => {
        console.error("Error fetching client URLs data:", error);
        // Handle any errors appropriately here
      });
    

    const fetchBlogUrlsLength = fetch(getFullApiUrl(GET_BLOG_URLS_LENGTH), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        set_blog_url_length(data.length);
      });

    Promise.all([fetchClientUrlsData, fetchBlogUrlsLength])
      .then(() => {
        set_initial_data_loaded_client(1);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_initial_data_loaded_client(-1);
      });
      

  }, [campaignId]);

  const validate_bulk_process_urls = async (button) => {
    const deduction_point = await getCreditPointToDeductByButton(button);

    const totalRows = selected_client_urls.length * blog_url_length;
    // Check if any URLs are selected
    if (selected_client_urls.length === 0) {
      setNotificationMessage("Please select at least one URL to process");
      setOpenSnackbar(true);
      return;
    }

    const credits_to_be_deducted = parseFloat((totalRows * parseFloat(deduction_point)).toFixed(3));
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);

    const threshold_value = data.threshold_value;

    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }
    if (shouldOpenModal) {
      set_show_should_we_bulk_process_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await Process_Selected_Clients();

      await updateCreditPoints(credits_to_be_deducted); // Deduct points only if the process was successful
    } else {
      set_show_should_we_bulk_process_urls_modal(true);
    }
  };


  const processBulkProcessAndUpdate = async () => {
    await Process_Selected_Clients();
    if (!bulkProcessInProgress) {
      await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    }
    setBulkProcessInProgress(true);
  };
  const toggleRowSelect = (index) => {
    const updatedSelectedRows = [...selected_client_urls];
    if (updatedSelectedRows.includes(index)) {
      const indexToRemove = updatedSelectedRows.indexOf(index);
      updatedSelectedRows.splice(indexToRemove, 1);
    } else {
      updatedSelectedRows.push(index);
    }
    set_selected_client_urls(updatedSelectedRows);
    if (updatedSelectedRows.length === client_urls_data.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const toggleSelectAll = () => {
    console.log("client_urls_data ",client_urls_data)
      console.log("progress ",progress)
    if (selectAll) {
      set_selected_client_urls([]);
    } else {
      const allIndexes = Array.from(
        { length: client_urls_data.length },
        (_, i) => i
      );
      set_selected_client_urls(allIndexes);
    }
    setSelectAll(!selectAll);
  };

  const Process_Selected_Clients = async () => {
    set_show_should_we_bulk_process_urls_modal(false);
    set_bulk_process_loading(true)
    const updatedProgress = new Array(client_urls_data.length)
      .fill(null)
      .map(() => ({ progress: 0, scored: 0, not_scored: 0 }));

    const processNextUrl = async (currentIndex) => {
      if (Math.max(...selected_client_urls) < currentIndex) {
        return;
      }
      if (selected_client_urls.includes(currentIndex)) {
        const client_url_data = client_urls_data[currentIndex];
        await processClientUrlsSequentially(
          client_url_data,
          currentIndex,
          updatedProgress
        );
        processNextUrl(currentIndex + 1);
      } else {
        processNextUrl(currentIndex + 1);
      }
    };

    await processNextUrl(0);
    set_bulk_process_loading(false)
  };

  const apiEndpoints = [
    "https://y1oxjjpfx6.execute-api.us-east-2.amazonaws.com",
    "https://v7glcip0oc.execute-api.us-east-2.amazonaws.com",
    "https://1rywkx193j.execute-api.us-east-2.amazonaws.com",
    "https://xy2h6u8ypb.execute-api.us-east-2.amazonaws.com",
    "https://ywrjzw35i3.execute-api.us-east-2.amazonaws.com",
    "https://81y6yfloue.execute-api.us-east-2.amazonaws.com",
    "https://b17xmalcq9.execute-api.us-east-2.amazonaws.com",
    "https://o55ssq7cif.execute-api.us-east-2.amazonaws.com",
    "https://iarx06w3d9.execute-api.us-east-2.amazonaws.com",
    "https://prg3ei0xoe.execute-api.us-east-2.amazonaws.com",
  ];
  const [bulkProcessInProgress, setBulkProcessInProgress] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [bulk_process_loading, set_bulk_process_loading] = useState(false)



  const processClientUrlsSequentially = async (client_url_data, currentIndex, updatedProgress) => {
    const lambdaUrlForBulkProcess = "https://xopl76khyeekinvmhkjicssx740lqkei.lambda-url.us-east-2.on.aws/";
    // const lambdaUrlForBulkProcess = "https://2fcjl7u4blkaudxek6idbcsnvy0fhutp.lambda-url.us-east-2.on.aws/"; // testing
    try {
        const response = await fetch(lambdaUrlForBulkProcess, {
            method: 'POST',
            body: JSON.stringify({
                campaign_id: campaignId,
                client_url_data: client_url_data,
                bucket_name: BUCKET_NAME
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const result = await response.json();

        if (result.success) {
            // Assuming the backend processes the URLs and returns a success message
            const { message } = result;

            // Store the processed data in the state array
            setProcessedData((prevData) => [
                ...prevData,
                {
                    client_url_data,
                    currentIndex,
                    message: message,
                },
            ]);

            // Update progress
            updatedProgress[currentIndex]["progress"] = 100;
            set_progress([...updatedProgress]);
        } else {
            console.error("Failed to process client URL:", result.message);
        }
    } catch (error) {
        console.error("Error processing client URL:", error);
    }
};


  const [isSaving, setIsSaving] = useState(false);


  const save_scored_data = async (client_url_data, currentIndex, updatedRows) => {
    try {
      const response = await fetch(getFullApiUrl(SAVE_SIMILARITY_SCORE), {
        method: "POST",
        body: JSON.stringify({
          request_data: {
            campaign_id: campaignId,
            analyzed_client_data: {
              client_url: client_url_data["url"],
              blog_urls: updatedRows,
            },
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success) {
        saveProjectData(); // Assuming this function saves other project-related data
      } else {
        throw new Error('Save failed');
      }
    } catch (error) {
      console.error("Save error:", error);
    }
  };

  const download_analyzed_blog_data = async () => {
    console.log(selected_client_urls);
    console.log(client_urls_data);

    const selected_urls = client_urls_data
      .filter((data, index) => selected_client_urls.includes(index))
      .map((data) => data.url);

    console.log(selected_urls);

    fetch(getFullApiUrl(DOWNLOAD_ANALYZED_BLOG_DATA_BY_CLIENT_URLS), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, selected_urls: selected_urls },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "match_prospects_with_target_urls_data.csv");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const saveProjectData = () => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, run_bulk_similarity_check: 1 },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  const handleSave = async () => {
    setShowSaveModal(false);
    setIsSaving(true);
    if (processedData.length > 0) {
      for (const data of processedData) {
        const { client_url_data, currentIndex, updatedRows } = data;
        await save_scored_data(client_url_data, currentIndex, updatedRows);
      }
    } else {
    }
    // Clear the stored data after saving
    setProcessedData([]);
    setIsSaving(false);
  };


  // sx={{ flex-basis: calc( 100% - 240px ); max-width: calc( 100% - 240px ); )}}
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection campaign_bodysection">
          <FloatingMenuBar campaignId={campaignId} />
          {/* <FloatingMenuBar campaignId={campaignId} /> */}
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                All-In-One
              </Link>
            </div>
            <div className="breadcrumb_text">Match Prospects with Target URLs</div>
          </Breadcrumbs>

          <div className="bulk_process_container">
            {initial_data_loaded_client === 1 ? (
              <>
                <div className="bulk_process_heading_and_sub_heading_container">
                  <div className="bulk_process_heading_container">
                    <div className="bulk_process_heading_text">
                      Match Prospects with Target URLs
                    </div>
                  </div>

                  {/* Subdescription Section */}
                  <div className="bulk_process_sub_heading_container">
                    <div className="bulk_process_sub_heading_text">
                      <div>🚀 Start by selecting the client URLs you want to process using the 'Process Selected Clients' button.</div>
                      <div>📥 After processing, you can download the selected processed data in Excel format using the download button.</div>
                    </div>
                  </div>
                </div>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Object.keys(client_urls_data).length === 0 ? (
                    <Alert
                      severity="info"
                      style={{
                        width: "98%",
                      }}
                    >
                      Feed Client urls to the system
                    </Alert>
                  ) : (
                    <div className="Client_Urls_Bulk_Process_Section">
                      <div className="Client_Urls_Action_Section">
                        <div className="Client_Url_Select_All">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={toggleSelectAll}
                          />
                        </div>
                        <div className="process_scrape_save_download_button_container">
                          <div className="Process_Button_Container">
                            {bulk_process_loading ?  ( <Button
                              variant="contained"
                              color="secondary"
                              className="Process_Selected_Clients"
                              onClick={() =>
                                validate_bulk_process_urls(
                                  "Process Selected Clients"
                                )
                              }
                              disabled={bulk_process_loading}
                            >
                              <div className="Process_Button_Container_text">
                              Processing Selected Clients <CircularProgress  height={24} width={24}/>
                              </div>
                            </Button>
                            ):(
                              <Button
                              variant="contained"
                              color="secondary"
                              className="Process_Selected_Clients"
                              onClick={() =>
                                validate_bulk_process_urls(
                                  "Process Selected Clients"
                                )
                              }
                            >
                              <div className="Process_Button_Container_text">
                              Process Selected Clients
                                
                              </div>
                            </Button>
                            )

                            }
                           
                          </div>
                          <div className="Save_Button_Container">
                            {/* {isSaving ? (
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={true} // Disabled while saving
                                startIcon={<CircularProgress size={20} />} // Show CircularProgress while saving
                              >
                                ......Saving
                              </Button>
                            ) : (
                              <Tooltip title="Save Scored Data">
                                <SaveIcon onClick={() => setShowSaveModal(true)} />
                              </Tooltip>
                            )} */}
                          </div>

                          <div className="Download_Button_Container">
                            <Tooltip title="Download in CSV format">
                              <DownloadIcon
                                className="OpenInNewIcon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  download_analyzed_blog_data();
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="Client_Urls_Section">
                        {client_urls_data.map((keywordObj, index) => (
                          <div className="Client_Url_Row" key={index}>
                            <div className="Client_Url_Select">
                              <input
                                type="checkbox"
                                checked={selected_client_urls.includes(index)}
                                onChange={() => toggleRowSelect(index)}
                              />
                            </div>
                            <div className="Client_Url">{keywordObj.url}</div>
                            <div className="Client_Url_Progress">
                              <div
                                style={{ width: "500px", position: "relative" }}
                              >
                                <LinearProgress
                                  sx={{
                                    height: "5px",
                                    "& .MuiLinearProgress-barColorPrimary": {
                                      backgroundColor: "#26d426",
                                    },
                                    "& .MuiLinearProgress-bar2Buffer": {
                                      backgroundColor: "#f15757",
                                    },
                                    "& .css-8ub8io-MuiLinearProgress-dashed": {
                                      backgroundImage:
                                        "radial-gradient(rgb(255 255 255) 0%, rgb(0 127 255) 16%, #ffffff 42%);",
                                    },
                                  }}
                                  variant="determinate"
                                  value={progress[index]["progress"]}
                                />{" "}
                                <Typography
                                  style={{
                                    position: "absolute",
                                    color: "#00f",
                                    top: -22,
                                    left: progress[index]["progress"] + "%",
                                    transform: "translateX(-25%)",
                                  }}
                                >
                                  {`${Math.floor(
                                    progress[index]["progress"]
                                  )}%`}
                                </Typography>

                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </Box>
              </>
            ) : initial_data_loaded_client === -1  ? (
              <>
                <Alert
                  severity="error"
                  style={{
                    width: "98%",
                  }}
                >
                  Issue on Initial Data Loading
                </Alert>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Loading Initial Data........
                  <CircularProgress style={{ marginLeft: "10px" }} />
                </Box>
              </>
            )}
          </div>
          <Modal
            open={show_should_we_bulk_process_urls_modal}
            onClose={handle_should_we_bulk_process_urls_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="LinkExchange_model_box">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={handle_should_we_bulk_process_urls_close}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="LinkExchange_modal_header">Current Credits</div>
              <div className="LinkExchange_modal_text_heading">
                Before proceeding with this action, please review the following:
              </div>
              <div className="LinkExchange_modal_text">
                Available Credits:{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForTotalCredits ? "red" : "inherit",
                    }}
                  >
                    {totalCreditPoints}
                  </div>
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left:{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForCreditWeekLimits
                        ? "red"
                        : "inherit",
                    }}
                  >
                    {creditPointsLimit}
                  </div>
                </div>
              </div>
              {errorMessage !==
                "There are no blog URLs available for scraping at the moment." && (
                  <div className="LinkExchange_modal_text_deduct">
                    Performing this action will deduct{" "}
                    <div className="LinkExchange_modal_deduct_number">
                      {" "}
                      {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                    </div>{" "}
                    from your balance.
                  </div>
                )}
              {(errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits) && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",

                      fontWeight: "bolder",
                      fontFamily: "monospace",
                      fontSize: "larger",
                    }}
                  >
                    <div>{errorMassageForTotalCredits}</div>
                    <div>{errorMassageForCreditWeekLimits}</div>
                    <div>{errorMessage}</div>
                  </div>
                )}

              {!(
                errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits
              ) && (
                  <>
                    <div className="LinkExchange_modal_header">
                      After Deduction
                    </div>
                    <div className="LinkExchange_modal_text">
                      Balance Credits :
                      <div className="LinkExchange_modal_number">
                        {totalCreditAfterDeduction}
                      </div>
                    </div>
                    <div className="LinkExchange_modal_text">
                      Weekly Credits Limit Left :{" "}
                      <div className="LinkExchange_modal_number">
                        {creditWeekLimitAfterDeduction}
                      </div>
                    </div>

                    <div className="LinkExchange_form_group">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={processBulkProcessAndUpdate}
                        color="primary"
                      >
                        Confirm Action
                      </Button>
                    </div>
                  </>
                )}
            </Box>
          </Modal>
          <Modal
            open={showSaveModal}
            onClose={() => setShowSaveModal(false)}
            aria-labelledby="save-confirmation-modal"
            aria-describedby="save-confirmation-description"
          >
            <Box id="LinkExchange_model_box">
              <div className="bulk_process_save_modal_container">
                <div className="bulk_process_save_modal_header">
                  Confirm Save 📝
                </div>
                <div className="bulk_process_save_modal_sub_header">
                  Are you sure you want to save the Match Prospects with Target URLs data? 📊 We have calculated the similarity score between target URLs and prospect URLs. 🌐
                </div>
                <div className="bulk_process_save_modal_actions">
                  <Button onClick={handleSave} color="primary" variant="contained">
                    Yes ✔️
                  </Button>
                  <Button onClick={() => setShowSaveModal(false)} color="secondary" variant="outlined">
                    No ❌
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={notificationMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default BulkProcessPage;
