import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  Breadcrumbs,
  Link,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import "./LinkInsertionSuggestionPage.css";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import {
  BASE_API_URL_MAIN,
  GET_BLOG_URLS_DATA,
  GET_CLIENT_URLS_DATA,
  GET_IDENTIFIED_PARAGRAPHS_DATA,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  SUGGEST_LINK_INSERTION,
  getFullApiUrl,
} from "../../utils/apiPath";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";

const LinkInsertionSuggestionPage = ({ toggleTheme }) => {
  //********************************************************** State variable - Start
  const { campaignId } = useParams();

  const [client_urls_data, set_client_urls_data] = useState([]);
  const [blog_urls_data, set_blog_urls_data] = useState([]);
  const [analyzed_client_urls_data, set_analyzed_client_urls_data] = useState(
    []
  );
  const [analyzed_blog_urls_data, set_analyzed_blog_urls_data] = useState([]);

  const [selected_client_url, set_selected_client_url] = useState(null);
  const [selected_blog_url, set_selected_blog_url] = useState(null);

  const [
    blog_urls_for_selected_client_url,
    set_blog_urls_for_selected_client_url,
  ] = useState([]);
  const [
    client_urls_for_selected_blog_url,
    set_client_urls_for_selected_blog_url,
  ] = useState([]);

  const [selected_client_url_2, set_selected_client_url_2] = useState(null);
  const [selected_blog_url_2, set_selected_blog_url_2] = useState(null);

  const [clientUrl_for_link_insertion, set_clientUrl_for_link_insertion] =
    useState("");
  const [blogUrl_for_link_insertion, set_blogUrl_for_link_insertion] =
    useState("");

  const [link_insertion_prompt, set_link_insertion_prompt] = useState("");
  const [link_insertion_suggestion, set_link_insertion_suggestion] =
    useState("");

  //********************************************************** State variable - End

  //********************************************************** On page load - Start
  useEffect(() => {
    fetch(getFullApiUrl(GET_CLIENT_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //*******************************************************************************************
        console.log("get_client_urls_data");
        console.log(data["data"]);
        set_client_urls_data(data["data"]);
        // save to state client_urls_data
        //*******************************************************************************************
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
      });

    fetch(getFullApiUrl(GET_BLOG_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //*******************************************************************************************
        console.log("get_blog_urls_data");
        console.log(data["data"]);
        set_blog_urls_data(data["data"]);
        // save to state client_urls_data
        //*******************************************************************************************
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
      });

    fetch(getFullApiUrl(GET_IDENTIFIED_PARAGRAPHS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const analyzed_client_urls_data_temp = data["data"].map(
          (item, index) => ({
            client_url_id: index + 1,
            client_url: item.client_url,
            selected_blog_urls: item.blog_urls.filter(
              (blog) => blog.selected === 1
            ),
          })
        );
        set_analyzed_client_urls_data(analyzed_client_urls_data_temp);

        /*
      const analyzed_blog_urls_data_temp = data["data"].reduce((result, item) => {
        item.blog_urls.forEach((blog) => {
          const existingEntry = result.find((entry) => entry.blog_urls === blog.blog_url);
          if (existingEntry)
          {
            existingEntry.selected_client_urls.push(item.client_url);
          }
          else
          {
            result.push({
              blog_urls: blog.blog_url,
              selected_client_urls: [item.client_url]
            });
          }
        });
        return result;
      }, []);
      */
        const analyzed_blog_urls_data_temp = data["data"].reduce(
          (result, item) => {
            item.blog_urls.forEach((blog) => {
              const existingEntry = result.find(
                (entry) => entry.blog_urls === blog.blog_url
              );
              if (existingEntry) {
                if (blog.selected === 1) {
                  existingEntry.selected_client_urls.push(item.client_url);
                }
              } else {
                if (blog.selected === 1) {
                  result.push({
                    blog_urls: blog.blog_url,
                    selected_client_urls: [item.client_url],
                  });
                } else {
                  /*
                result.push({
                  blog_urls: blog.blog_url,
                  selected_client_urls: []
                });
                */
                }
              }
            });
            return result;
          },
          []
        );

        //console.log("******************************************************");
        //console.log(analyzed_blog_urls_data_temp);
        set_analyzed_blog_urls_data(analyzed_blog_urls_data_temp);
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
      });
  }, []);
  //********************************************************** On page load - End
  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const select_client_url = (index) => {
    console.log(index);
    set_selected_client_url(index);
    var clientUrl_for_link_insertion_temp =
      analyzed_client_urls_data[index]["client_url"];
    set_clientUrl_for_link_insertion(clientUrl_for_link_insertion_temp);

    set_selected_blog_url_2(null);
    set_blogUrl_for_link_insertion("");
    set_blog_urls_for_selected_client_url(
      analyzed_client_urls_data[index]["selected_blog_urls"]
    );
    console.log(blog_urls_for_selected_client_url);
  };

  const select_blog_url = (index) => {
    console.log(index);
    set_selected_blog_url(index);
    set_selected_client_url_2(null);
    set_client_urls_for_selected_blog_url(
      analyzed_blog_urls_data[index]["selected_client_urls"]
    );
    console.log(client_urls_for_selected_blog_url);
  };

  const select_client_url_2 = (index) => {
    console.log(index);
    set_selected_client_url_2(index);
    //set_blog_urls_for_selected_client_url_2(analyzed_client_urls_data[index]);
    //console.log(blog_urls_for_selected_client_url_2)
  };

  const select_blog_url_2 = (index) => {
    console.log(index);
    set_selected_blog_url_2(index);

    var blogUrl_for_link_insertion_temp =
      analyzed_client_urls_data[selected_client_url]?.selected_blog_urls[index]
        ?.blog_url;
    set_blogUrl_for_link_insertion(blogUrl_for_link_insertion_temp);

    //set_client_urls_for_selected_blog_url_2(analyzed_blog_urls_data[index])
    //console.log(client_urls_for_selected_blog_url_2)
    get_link_insertion_prompt(blogUrl_for_link_insertion_temp);
  };

  const find_related_client_links = (blog_url) => {
    var selectedClientURLs = analyzed_blog_urls_data.find(
      (obj) => obj.blog_urls === blog_url
    )?.selected_client_urls;
    set_client_urls_for_selected_blog_url(selectedClientURLs);
  };

  const get_link_insertion_prompt = (blogUrl_for_link_insertion_temp) => {
    console.log(
      "****************************************************************"
    );
    //console.log(clientUrl_for_link_insertion);
    //console.log(blogUrl_for_link_insertion);
    console.log(
      "****************************************************************"
    );
    //console.log(client_urls_data);
    //console.log(blog_urls_data);
    console.log(
      "****************************************************************"
    );
    //console.log(blog_urls_data[index]);

    /*
    console.log("****************************************************************");
    console.log(analyzed_client_urls_data);
    var index1 = analyzed_client_urls_data.findIndex(obj => obj.client_url === clientUrl_for_link_insertion);
    var index2 = analyzed_client_urls_data[index1]["selected_blog_urls"].findIndex(obj => obj.blog_url === blogUrl_for_link_insertion);
    console.log(analyzed_client_urls_data[index1]["selected_blog_urls"][index2]);
    console.log(analyzed_client_urls_data[index1]["selected_blog_urls"][index2]["matched_paragraph"]);
    console.log("****************************************************************");
    */

    var index = client_urls_data.findIndex(
      (obj) => obj.url === clientUrl_for_link_insertion
    );
    var selected_client_urls_data = client_urls_data[index];
    var client_page_keywords = selected_client_urls_data["keywords"];

    var index = blog_urls_data.findIndex(
      (obj) => obj.url === blogUrl_for_link_insertion_temp
    );
    var selected_blog_urls_data = client_urls_data[index];

    var index1 = analyzed_client_urls_data.findIndex(
      (obj) => obj.client_url === clientUrl_for_link_insertion
    );
    var index2 = analyzed_client_urls_data[index1][
      "selected_blog_urls"
    ].findIndex((obj) => obj.blog_url === blogUrl_for_link_insertion_temp);
    var matched_paragraph =
      analyzed_client_urls_data[index1]["selected_blog_urls"][index2][
        "matched_paragraph"
      ];

    if (matched_paragraph == "") {
      var prompts = "No matching paragraph identified in previous step";
    } else {
      var prompts = "";
      prompts =
        prompts + "Client Target Page = " + clientUrl_for_link_insertion;
      prompts = prompts + "\n";
      prompts =
        prompts + "Client Target Page keywords = " + client_page_keywords;
      prompts = prompts + "\n";
      prompts =
        prompts + "Blogger's post Page = " + blogUrl_for_link_insertion_temp;
      prompts = prompts + "\n";
      prompts =
        prompts +
        "In a previous discussion you have identified below keywords and related paragraphs from the blogger's post page that are matching with Client Target Page keywords";
      prompts = prompts + "\n";
      prompts = prompts + matched_paragraph;
      prompts = prompts + "\n";
      prompts =
        prompts +
        "Based on the paragraphs you identified, pick the most relevant paragraphs and suggest a link insertion to my client's page, based on which keyword you identified:";
      prompts = prompts + "\n";
      //prompts = prompts + client_urls_Input
      prompts = prompts + "\n";
      prompts =
        prompts +
        "Here are your rules for making link insertion suggestions:" +
        "\n" +
        "Page Relevance to Client Target Page: We want the content on the page to align with, or have something in common with, the content on the client's target page. This makes the link appear natural and meaningful to users." +
        "\n" +
        "Page Content Quality: We value pages with good aesthetics, including quality images, a reasonable length, and well-written content. This indicates that the page creator has put effort into making it useful and enjoyable for visitors." +
        "\n" +
        "Page Exclusivity: We prefer pages that aren't crowded with promotional links from other sites. It's easy to spot when a page has too many random links that don't fit the content." +
        "\n" +
        "Link Placement: We prefer the link to be located at least in the middle or higher up on the page, away from the crowd of other links. We also discourage link placement within bullet points or introductions as it can decrease the likelihood of users clicking on the inserted link." +
        "\n" +
        "Anchor Text Usage: We want to use appropriate and relevant anchor text for link insertion. This includes keyword, partial match, keyword+word, and branded and generic anchors." +
        "\n" +
        "Sentence-level Relevance: We want the inserted link to fit well within its context and make sense within the sentence. This helps users and search engines better understand the link, making it more useful." +
        "\n" +
        "Note: The page should meet all these criteria to be suitable for link insertion." +
        "\n" +
        "Your output instructions:" +
        "\n" +
        "Remember - we don’t want any forced links insertion. “No opportunities found is an acceptable answer”. Avoiding adding a suggestion in the introduction or conclusion of the article. Don’t add suggestions right after a subheading." +
        "\n" +
        "A bad suggestion is one that is too promotional or disrupts the flow of the original article. A good one is a logical addition to the section of the article and adds additional value to the article in the form of a data point, valuable resource, or adding educational information." +
        "\n" +
        "Make 3-5 suggestions. Here are the rules for the output format. You can use any format that matches your suggestion." +
        "\n" +
        "Format 1: Link insertion suggestion with no text modification or addition necessary. " +
        "\n" +
        "Link from: (just the URL of blogger's post)" +
        "\n" +
        "Link to: (just the URL of my client)" +
        "\n" +
        "Anchor text: (text exactly as it already appears on the blog post)" +
        "\n" +
        "Format 2: Link insertion suggestion with text modification necessary." +
        "\n" +
        "Link from: (just the URL of blogger's post)" +
        "\n" +
        "Link to: (just the URL of my client)" +
        "\n" +
        "Anchor text: (hyperlink text to use)" +
        "\n" +
        "Change from: (text exactly as already it appears on the blog post)" +
        "\n" +
        "To: (updated text)" +
        "\n" +
        "Format 3: Link insertion suggestion with text addition necessary. " +
        "\n" +
        "Link from: (just the URL of blogger's post)" +
        "\n" +
        "Link to: (just the URL of my client)" +
        "\n" +
        "Anchor text: (hyperlink text to use)" +
        "\n" +
        "After this sentence: (text exactly as it already appears on the blog post)" +
        "\n" +
        "Add this: (write the new sentence that includes the anchor text for the client’s link)";
    }
    //console.log(prompts);
    set_link_insertion_prompt(prompts);
    //document.getElementById('link_insertion_suggestion').textContent = prompts;
    //document.getElementById('link_insertion_suggestion').value = prompts;
  };
  const get_link_insertion_suggestion = () => {
    // set_link_insertion_prompt

    fetch(getFullApiUrl(SUGGEST_LINK_INSERTION), {
      method: "POST",
      body: JSON.stringify({ request_data: { prompt: link_insertion_prompt } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //*******************************************************************************************
        console.log("get_client_urls_data");
        console.log(data["response"]);
        set_link_insertion_suggestion(data["response"]);
        // save to state client_urls_data
        //*******************************************************************************************
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
      });
  };

  const copy_text = (text) => {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };
  const open_in_new_tab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection campaign_bodysection">
          <FloatingMenuBar campaignId={campaignId} />
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                All-In-One
              </Link>
            </div>
            <div className="breadcrumbs_text">
                Link Insertion Suggestion
            </div>
          </Breadcrumbs>

          <div className="link_insertion_container">
            <Box className="link_matching_check_header">
              <Box className="column_header">Select Client Link</Box>
              <Box className="column_header">Choose Blog Link</Box>
              <Box sx={{ width: "10px" }}>&nbsp;</Box>
              <Box className="column_header">Related Client Links</Box>
              <Box className="column_header">Blog Links</Box>
            </Box>

            <Box className="link_matching_check_container">
              <Box className="extreme_left">
                {analyzed_client_urls_data.map((row, index) => (
                  <Box
                    className={`link_container ${
                      selected_client_url === index
                        ? "link_container_selected"
                        : ""
                    }`}
                    onClick={() => select_client_url(index)}
                  >
                    <Tooltip title="Copy the url">
                      <ContentCopyIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          copy_text(row["client_url"]);
                        }}
                      />
                    </Tooltip>
                    &nbsp;
                    {row["client_url"]}
                    &nbsp;
                    <Tooltip title="Open in New Tab">
                      <OpenInNewIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          open_in_new_tab(row["client_url"]);
                        }}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Box>

              <Box className="left">
                {blog_urls_for_selected_client_url.map((row, index) => (
                  <Box
                    className={`link_container ${
                      selected_blog_url_2 === index
                        ? "link_container_selected"
                        : ""
                    }`}
                    onClick={() => select_blog_url_2(index)}
                  >
                    <Tooltip title="Copy the url">
                      <ContentCopyIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          copy_text(row["blog_url"]);
                        }}
                      />
                    </Tooltip>
                    &nbsp;
                    {row["blog_url"]}
                    &nbsp;
                    <Tooltip title="Open in New Tab">
                      <OpenInNewIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          open_in_new_tab(row["blog_url"]);
                        }}
                      />
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Get all clients for which you have selected this blog">
                      <ManageSearchIcon
                        className="serach"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          find_related_client_links(row["blog_url"]);
                        }}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Box>

              <Box className="devider"></Box>

              <Box className="right">
                {client_urls_for_selected_blog_url.map((row, index) => (
                  <Box
                    className={`link_container ${
                      selected_client_url_2 === index
                        ? "link_container_selected"
                        : ""
                    }`}
                    onClick={() => select_client_url_2(index)}
                  >
                    <Tooltip title="Copy the url">
                      <ContentCopyIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          copy_text(row);
                        }}
                      />
                    </Tooltip>
                    &nbsp;
                    {row}
                    &nbsp;
                    <Tooltip title="Open in New Tab">
                      <OpenInNewIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          open_in_new_tab(row);
                        }}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Box>

              <Box className="extreme_right">
                {analyzed_blog_urls_data.map((row, index) => (
                  <Box
                    className={`link_container ${
                      selected_blog_url === index
                        ? "link_container_selected"
                        : ""
                    }`}
                    onClick={() => select_blog_url(index)}
                  >
                    <Tooltip title="Copy the url">
                      <ContentCopyIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          copy_text(row["blog_urls"]);
                        }}
                      />
                    </Tooltip>
                    &nbsp;
                    {row["blog_urls"]}
                    &nbsp;
                    <Tooltip title="Open in New Tab">
                      <OpenInNewIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          open_in_new_tab(row["blog_urls"]);
                        }}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </Box>

            {clientUrl_for_link_insertion != "" ? (
              <Box className="link_insertion_suggestion_section">
                <Box className="selected_client_url">
                  {"Selected client url : -  " + clientUrl_for_link_insertion}
                </Box>

                <Box className="selected_blog_url">
                  {"Selected blog url : -  " + blogUrl_for_link_insertion}
                </Box>

                {blogUrl_for_link_insertion != "" ? (
                  <>
                    <Box className="buttonContainer">
                      <Button
                        variant="contained"
                        color="success"
                        style={{ textTransform: "none" }}
                        onClick={() => get_link_insertion_suggestion()}
                      >
                        Get Link Instertion Suggestion
                      </Button>
                    </Box>

                    <Box className="link_insertion_generated">
                      <Box className="link_insertion_prompt">
                        <Typography variant="subtitle1">
                          Link Instertion Prompt
                        </Typography>
                        <TextField
                          label="Prompt"
                          InputLabelProps={{
                            shrink: link_insertion_prompt !== "",
                          }}
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          value={link_insertion_prompt}
                          onChange={(event) => {
                            set_link_insertion_prompt(event.target.value);
                          }}
                        />
                      </Box>

                      <Box className="link_insertion_suggestion">
                        <Typography variant="subtitle1">
                          Link Instertion Suggestion
                        </Typography>
                        <TextField
                          label="Suggestion"
                          InputLabelProps={{
                            shrink: link_insertion_suggestion !== "",
                          }}
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          value={link_insertion_suggestion}
                          onChange={(event) => {
                            set_link_insertion_suggestion(event.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
/*




const [clientUrl_for_link_insertion, set_clientUrl_for_link_insertion] = useState(null);
const [blogUrl_for_link_insertion, set_blogUrl_for_link_insertion] = useState(null);

<Box className="link_insertion_suggestion_section">
  <Box className="selected_client_url">
    {JSON.stringify(analyzed_client_urls_data[selected_client_url]["client_url"], null, 2)}
  </Box>
  <Box className="selected_blog_url">
    {JSON.stringify(analyzed_client_urls_data[selected_client_url]["selected_blog_urls"][selected_blog_url_2]["blog_url"], null, 2)}
  </Box>
</Box>



*/
export default LinkInsertionSuggestionPage;
