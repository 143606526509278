import React, { useEffect, useState } from "react";

import { LiaEdit } from "react-icons/lia";
import { RiAddLine } from "react-icons/ri";

import Modal from "@mui/material/Modal";

import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";

import AdminSideBar from "../common/AdminSideBar/AdminSideBar";
import Header from "../../common/Header/Header";
import TableWithPagination from "../../../utils/TableWithPagination";
import {
  ADD_POINT,
  GET_ALL_POINTS,
  UPDATE_POINT,
  getFullApiUrl,
} from "../../../utils/apiPath";

const Point_deduction = ({ toggleTheme }) => {
  const [Credit_deduction_data, set_Credit_deduction_data] = useState([]);
  const [showAddcreditPoint, setShowAddcreditPoint] = React.useState(false);
  const handleAddOpen = () => setShowAddcreditPoint(true);
  const handleAddClose = () => setShowAddcreditPoint(false);

  const handleEditOpen = () => setShowEditcreditPoint(true);
  const handleEditClose = () => setShowEditcreditPoint(false);
  const [showEditcreditPoint, setShowEditcreditPoint] = React.useState(false);

  const fetchpointList = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_ALL_POINTS)}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("data", data);
      set_Credit_deduction_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await fetchpointList();
  };
  useEffect(() => {
    fetchData();
  }, [1000]);

  const [newPoint, setnewPoint] = useState({
    button_name: "",
    credit_points: "",
  });

  const handleAddPoint = async () => {
    try {
      const response = await fetch(getFullApiUrl(ADD_POINT), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPoint),
      });

      if (!response.ok) {
        throw new Error("Failed to add new credit point");
      }
      handleAddClose();

      setnewPoint({
        button_name: "",
        credit_points: "",
      });
      fetchpointList();

      console.log("New credit point added successfully");
    } catch (error) {
      console.error("Error adding new credit point:", error.message);
    }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewPoint({ ...newPoint, [name]: value });
  };

  const [selectedPoint, setselectedPoint] = useState({});
  const [credit_point_to_update, set_credit_point_to_update] = useState({
    point_id: "",
    button_name: "",
    credit_points: "",
  });

  const handleEditpont = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_POINT), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credit_point_to_update),
      });

      if (!response.ok) {
        throw new Error("Failed to edit subscription");
      }

      console.log("subscription edited successfully");
      setShowEditcreditPoint(false);
      // Refresh subscription list after editing
      fetchpointList();
    } catch (error) {
      console.error("Error editing subscription:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    set_credit_point_to_update({ ...credit_point_to_update, [name]: value });
  };
  const handleEditcreditPoint = (point) => {
    set_credit_point_to_update(point);
    setShowEditcreditPoint(true);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="admin_page_heading">Points Deduction</div>

          <div>
            <TableWithPagination
              data={Credit_deduction_data}
              fields={[
                {
                  type: "id",
                  field: "point_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 240,
                },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  width: 120,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEditcreditPoint(params)}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
                {
                  type: "field",
                  field: "button_name",
                  headerName: "Function",
                  minWidth: 250,
                  width: 450,
                  maxWidth: 1800,
                  search_type: "text_search",
                },
                {
                  type: "field",
                  field: "credit_points",
                  headerName: "Credit Point Deduction",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 340,
                  search_type: "number_search",
                },
                {
                  type: "field",
                  field: "threshold_value",
                  headerName: "Threshold Value",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 340,
                  search_type: "number_search",
                },
              ]}threshold_value
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add New Point">
                        <div
                          onClick={handleAddOpen}
                          className="bulk_classify_button download_icons"
                        >
                          <RiAddLine />
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />

            <Modal
              open={showAddcreditPoint}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add Points</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="button_name"
                      label="Button Name"
                      variant="standard"
                      name="button_name"
                      value={newPoint.button_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="credit_points"
                      label="Credits"
                      variant="standard"
                      name="credit_points"
                      value={newPoint.credit_points}
                      onChange={handleNewInputChange}
                      className="modal_form_input modal_textarea"
                     
                    />
                  </Box>
                

                  <button
                    onClick={handleAddPoint}
                    className="add-user-btn"
                  >
                    Add Points{" "}
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showEditcreditPoint}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update point</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="button_name"
                      label="Button Name"
                      variant="standard"
                      name="button_name"
                      value={
                        credit_point_to_update
                          ? credit_point_to_update.button_name
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="credit_points"
                      label="Credits"
                      variant="standard"
                      name="credit_points"
                      value={
                        credit_point_to_update
                          ? credit_point_to_update.credit_points
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <button onClick={handleEditpont} className="add-user-btn">
                    Update Point
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Point_deduction;
