import React, { useState, useEffect } from "react";
import {
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../common/Header/Header";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import { HiExternalLink } from "react-icons/hi";
import "./IndividualWorkspaceDetails.css";
import {
  GET_WORKSPACE_DETAILS,
  getFullApiUrl,
  CHECK_WORKSPACE_ACCESS,
} from "../../utils/apiPath";
import { FaChevronDown } from "react-icons/fa";

const IndividualWorkspaceDetails = ({ toggleTheme }) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };

  const fetchDataAndCheckAccess = async () => {
    if (!workspaceId) {
      navigate("/");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          workspace_id: workspaceId,
        }),
      };

      const response = await fetch(
        getFullApiUrl(CHECK_WORKSPACE_ACCESS),
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setHasAccess(data === true);
      if (data !== true) {
        alert("You don't have access to this page.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/");
    }
  };
  const [workspaceData, setWorkspaceData] = useState({});

  const getWorkspaceDetails = async (workspaceId) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_WORKSPACE_DETAILS)}?workspace_id=${workspaceId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch workspace details");
      }

      const data = await response.json();
      setWorkspaceData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchDataAndCheckAccess();
    getWorkspaceDetails(workspaceId);
  }, [workspaceId]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
                className="breadcrumb_link"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
                className="breadcrumb_link"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${workspaceData.team_id}`}
                color="inherit"
                underline="hover"
                className="breadcrumb_link"
              >
                {workspaceData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${workspaceData.team_id}`}
                color="inherit"
                underline="hover"
                className="breadcrumb_link"
              >
                {workspaceData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              {workspaceData.workspace_name}
            </div>
          </Breadcrumbs>

          <Accordion id="accordion_container">
            <AccordionSummary expandIcon={<FaChevronDown />}>
              <Typography variant="h6">
                Workspace Name : {workspaceData.workspace_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="workspace_details_container">
                <div className="workspace_record workspace_description">
                  <div className="total_record_value">
                    {workspaceData.workspace_description}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion id="accordion_container">
            <AccordionSummary expandIcon={<FaChevronDown />}>
              <Typography variant="h6">
                Team Name : {workspaceData.team_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="workspace_details_container">
                <div className="workspace_details_container">
                  <div className="workspace_record workspace_description">
                    <div className="total_record_value">
                      {workspaceData.team_description}
                    </div>
                  </div>
                </div>
                {/* <div className="workspace_record">
                  <div className="workspace_record_heading">
                    Owner of this Team
                  </div>
                  <div className="total_record_value">
                    {workspaceData.owner_name}
                  </div>
                </div> */}
                <div className="workspace_details_container">
                  <div className="workspace_record_heading_team_member">
                    Team Members
                  </div>
                  {workspaceData.member_details?.map((member, index) => (
                    <div key={index} className="workspace_record">
                      <div className="workspace_record_heading">
                        {member.member_designation}
                      </div>
                      <div className="total_record_value">
                        {member.member_name} ( {member.member_email})
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion id="accordion_container">
            <AccordionSummary expandIcon={<FaChevronDown />}>
              <Typography variant="h6">Workspace Status and Dates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="workspace_details_container">
                <div className="workspace_record">
                  <div className="workspace_record_heading">Status</div>
                  <div className="total_record_value">
                    {workspaceData.status}
                  </div>
                </div>
                <div className="workspace_record">
                  <div className="workspace_record_heading">Start Date</div>
                  <div className="total_record_value">
                    {formatDate(workspaceData.start_date)}
                  </div>
                </div>
                <div className="workspace_record">
                  <div className="workspace_record_heading">Deadline</div>
                  <div className="total_record_value">
                    {formatDate(workspaceData.deadline)}
                  </div>
                </div>
                <div className="workspace_record">
                  <div className="workspace_record_heading">Created On</div>
                  <div className="total_record_value">
                    {formatDate(workspaceData.created_at)}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <div className="link_to_next_page_container">
            <div className="Link">
              <div className="LinkDescription">
                <div className="workspace_record_heading">
                  Total Number of Workspace Members
                </div>
                <div className="link_value">
                  {workspaceData.total_workspace_members}
                </div>
              </div>
              <div className="LinkButton">
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<HiExternalLink />}
                  onClick={() => goto(`/my_workspace_members/${workspaceId}`)}
                >
                  Check It
                </Button>
              </div>
            </div>
            <div className="Link">
              <div className="LinkDescription">
                <div className="workspace_record_heading">
                  Total Number of Campaigns
                </div>
                <div className="link_value">
                  {workspaceData.total_campaigns}
                </div>
              </div>
              <div className="LinkButton">
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<HiExternalLink />}
                  onClick={() => goto(`/my_workspace_campaign/${workspaceId}`)}
                >
                  Check It
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualWorkspaceDetails;
