import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GET_CAMPAIGNS_DETAILS_OF_USER, GET_CREDIT_POINTS_OF_USER, UPDATE_USED_CREDIT_POINTS, getFullApiUrl } from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import { TextField, Grid, Box, Snackbar,Button } from "@mui/material";
import PointDeductionComponent from '../components/PointDeductionComponent/PointDeductionComponent'
import RemoveIcon from "@mui/icons-material/Remove";


const MyWorkspaceCampaignTesting = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const [campaignDetails, setCampaignDetails] = useState({});
  const [totalCreditPointsLimitThisWeek, setTotalCreditPointsLimitThisWeek] = useState(0);
  const [totalCreditPointsOfUser, setTotalCreditPointsOfUser] = useState();
  const [usedCreditPointsThisWeek, setUsedCreditPointsThisWeek] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const fetchCampaignDetails = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_CAMPAIGNS_DETAILS_OF_USER)}?campaign_id=${campaignId}&user_id=${localStorage.getItem('user_id')}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCampaignDetails(data);
      setTotalCreditPointsLimitThisWeek(data.credit_points_week_limit);
      setUsedCreditPointsThisWeek(data.used_credit_points_this_week);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUserCreditPoints = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_CREDIT_POINTS_OF_USER)}?user_id=${localStorage.getItem('user_id')}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTotalCreditPointsOfUser(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await fetchCampaignDetails();
    await fetchUserCreditPoints();
  };

  useEffect(() => {
    fetchData();
  }, [campaignId]);

  const handlePointsDeducted = async () => {
    await fetchData();
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="page-section">
      <div className="left-section-with-menu">
        <LeftSectionWithMenu />
      </div>
      <div className="right-section-with-menu">
        <div className="header-section-with-menu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="body-section">
          <div className="campaign_details">
            Total Credit points of {campaignDetails.user_name}: {totalCreditPointsOfUser}
          </div>

          <div className="credit_points_container">
            <h3>Total Credit Points Limit This week</h3>
            <TextField
              id="outlined-basic"
              label="Total Credit Points"
              variant="outlined"
              value={totalCreditPointsLimitThisWeek}
              disabled
            />

            <div className="deduction-buttons">
              <h3>Deduct Points</h3>
              <Grid container spacing={2} alignItems="center">
                {[
                  { points: 10, label: "Deduct 10" },
                  { points: 5, label: "Deduct 5" },
                  { points: 2, label: "Deduct 2" },
                  { points: 3, label: "Deduct 3" }
                ].map((item, index) => (
                  <Grid item xs={3} key={index}>

                    <Box>
                      
                   
                      <PointDeductionComponent
                        user_id={localStorage.getItem('user_id')}
                        campaign_id={campaignId}
                        points={item.points}
                        label={item.label}
                        onPointsDeducted={handlePointsDeducted}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={notificationMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWorkspaceCampaignTesting;
