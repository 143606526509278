import React, { useState, useEffect } from "react";
import {
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../common/Header/Header";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import { HiExternalLink } from "react-icons/hi";
import { FaChevronDown } from "react-icons/fa";
import {
  CHECK_TEAM_ACCESS,
  GET_TEAM_DETAILS,
  getFullApiUrl,
} from "../../utils/apiPath";
import "./TeamDetails.css";

const TeamDetails = ({ toggleTheme }) => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };

  const [teamData, setTeamData] = useState({});

  const getTeamDetails = async (teamId) => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_TEAM_DETAILS)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          team_id: teamId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch team details");
      }

      const data = await response.json();
      setTeamData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchDataAndCheckAccess = async () => {
    if (!teamId) {
      navigate("/");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          team_id: teamId,
        }),
      };

      const response = await fetch(
        getFullApiUrl(CHECK_TEAM_ACCESS),
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setHasAccess(data === true);
      if (data !== true) {
        alert("You don't have access to this page.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/");
    }
  };

  useEffect(() => {
    fetchDataAndCheckAccess();
    getTeamDetails(teamId);
  }, [teamId]);

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/my_teams"
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">{teamData.team_name}</div>
          </Breadcrumbs>

          <Accordion id="accordion_container_for_team">
            <AccordionSummary expandIcon={<FaChevronDown />}>
              <Typography variant="h6">
                Team Name : {teamData.team_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="team_details_container">
                <div className="team_record">
                  <div className="total_record_value">
                    {teamData.team_description}
                  </div>
                </div>
                {/* <div className="team_record">
                      <div className="team_record_heading">Owner Name</div>
                      <div className="total_record_value">{teamData.owner_name}</div>
                    </div> */}
                {/* <div className="team_record">
                      <div className="team_record_heading">Created on</div>
                      <div className="total_record_value">
                        {formatDate(teamData.created_at)}
                      </div>
                    </div> */}

                {/* Accordion for Team Members */}

                {/* End of Accordion for Team Members */}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion id="accordion_container_for_team">
            <AccordionSummary expandIcon={<FaChevronDown />}>
              <Typography variant="h6">Team Members</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="team_details_container">
                {teamData.member_details?.map((member, index) => (
                  <div key={index} className="team_record">
                    <div className="team_record_heading">
                      {" "}
                      {member.member_designation}
                    </div>
                    <div className="total_record_value">
                      {member.member_name} ({member.member_email})
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>

          <div className="link_to_next_page_container">
            <div className="Link">
              <div className="LinkDescription">
                <div className="team_record_heading">
                  Total Number of Members
                </div>
                <div className="link_value">{teamData.total_team_members}</div>
              </div>
              <div className="LinkButton">
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<HiExternalLink />}
                  onClick={() => goto(`/my_team_members/${teamId}`)}
                >
                  Check It
                </Button>
              </div>
            </div>
            <div className="Link">
              <div className="LinkDescription">
                <div className="team_record_heading">
                  Total Number of Workspaces
                </div>
                <div className="link_value">{teamData.total_workspaces}</div>
              </div>
              <div className="LinkButton">
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<HiExternalLink />}
                  onClick={() => goto(`/my_team_workspaces/${teamId}`)}
                >
                  Check It
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
