import React, { useState, useEffect } from 'react';
import { Box, Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from '../../../assets/logo.png';
//import user from '../../../assets/user_1.png';
import './Robo.css';



const Robo = (props) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [minimized, setMinimized] = useState(false); // New state for minimization

  useEffect(() => {
    // Simulate an initial bot greeting
    addBotResponse('Hello! I am Abhishek. \n How may I help you today');
    toggleMinimize()
  }, []);

  const addBotResponse = (responseText) => {
    setMessages([...messages, { text: responseText, user_message: false }]);
    console.log("messages");
    //console.log(messages[0]["text"]);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputText.trim() === '') return;
  
    // Add the user's message to the chat
    const userMessage = { text: inputText, user_message: true };
  
    // Add the user's message first
    setMessages((prevMessages) => [...prevMessages, userMessage]);
  
    // Simulate a bot response
    setTimeout(() => {
      let botResponse;
      switch (inputText.toLowerCase()) {
        case 'hello':
          botResponse = { text: 'Hi there! How can I assist you today?', user_message: false };
          break;
        case 'how are you?':
          botResponse = { text: ' just a bot, but thanks for asking!', user_message: false };
          break;
        case 'goodbye':
          botResponse = { text: 'Goodbye! Have a great day!', user_message: false };
          break;
        default:
          botResponse = { text: 'I’m sorry, I didn’t understand that.', user_message: false };
      }
      setMessages((prevMessages) => [...prevMessages, botResponse]);
    }, 1000);
  
    // Clear the input field
    setInputText('');
  };
  

  const toggleMinimize = () => {
    setMinimized(!minimized);
  };

  return (
    <div className={`bot-component ${minimized ? 'minimized' : ''}`}>
      <div className="bot-header">
        <img
          src="https://w7.pngwing.com/pngs/453/623/png-transparent-robot-free-computer-icons-robot-electronics-text-rectangle-thumbnail.png"
          alt="Bot Avatar"
          onClick={minimized ? toggleMinimize : undefined}
        />
        <button className="minimize-button" onClick={toggleMinimize}></button>
      </div>
      {!minimized && (
        <>
          <div className="chat-container">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.user_message ? 'user_message' : 'bot'}`}>
              {
                message.text ? (<div dangerouslySetInnerHTML={{ __html: message.text.replace(/\n/g, '<br/>') }} />) : ('')
              }
              </div>
            ))}
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type your message..."
              value={inputText}
              onChange={handleInputChange}
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </>
      )}
    </div>
  );
};

export default Robo;
