// SubscriptionPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SubscriptionPage.css';
import { useTheme } from '@mui/material';
import useAuth from '../../hooks/authentication/useAuth';
import FAQsSection from '../LandingPage/FAQsSection/FAQsSection';
import Footer from '../common/Footer/Footer';
import LeftSectionWithMenu from '../common/Header/LeftSectionWithMenu/LeftSectionWithMenu';
import Header from '../common/Header/Header';

const subscriptionData = [
  { type: 'Basic', price: '$9.99/month', details: ['Basic features', 'Limited content'] },
  { type: 'Monthly', price: '$19.99/month', details: ['All basic features', 'Unlimited content'] },
  { type: 'Yearly', price: '$99.99/year', details: ['All monthly features','Discounted price'] },
];

const SubscriptionItem = ({ subscription, onSelect, selected, onNext }) => (
  <div className='subscription-container-box' onClick={() => onSelect(subscription)}>
    <div className='basic-container'>
      <div className='container-upper'>
        <h2 className='subscription-container-box-primary-head'>
          {subscription.type}
        </h2>
        <p className='subscription-container-box-secondary-desc'>
          {subscription.details.join(' ')}
        </p>
        <p className='subscription-price'>{subscription.price}</p>
      </div>
      <div className='container-lower'>
        <ul className='subscription-container-desc'>
          {subscription.details.map((detail, index) => (
            <li key={index}>
              <ul className='subscriptionContainerDescList'>
                <li><span className='check-icon'>✔</span></li>
                <li><span className='feature-text'>{detail}</span></li>
              </ul>
            </li>
          ))}
        </ul>
        <button className='next-button' onClick={onNext} disabled={!selected}>
          Start Exploring
          <span className='arrow-icon'>&rarr;</span>
        </button>
      </div>
    </div>
  </div>
);


const SubscriptionPage = ({ toggleTheme }) => {
  const navigate = useNavigate();
  const { loggedIn } = useAuth();
  const theme = useTheme(); 

  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleSelect = (subscription) => {
    setSelectedSubscription(subscription);
  };

  const handleNext = () => {
    if (selectedSubscription) {
      navigate(`/payment?type=${selectedSubscription.type}&price=${selectedSubscription.price}`);
    }
  };

  return (
    <>
    <div className='page_Section'>
      {loggedIn ?  
        <div className='leftSection_withMenu'>
          <LeftSectionWithMenu />
        </div> 
        : null
      }
      <div className='rightSection_withMenu' >
          <div className='headerSection_withMenu'>
            <Header onToggleTheme={toggleTheme}  loggedIn={loggedIn}/>
          </div>
          <div className='bodySection subscriptionBody'>
            <div className='subscriptionPrimaryHeading'>
              <h3>Choose the Plans That Fit Your Needs</h3>
            </div>
            <div className='subscriptionPrimaryHeadingDesc'>
              <p>Discover a world of ease and comfort, where every moment is crafted for your delight.</p>
            </div>
            <div className='subscription-container'>
              {subscriptionData.map((subscription, index) => (
                <SubscriptionItem
                  key={index}
                  subscription={subscription}
                  onSelect={() => handleSelect(subscription)}
                  selected={selectedSubscription === subscription}
                  onNext={handleNext}
                />
              ))}
            </div>
            {/* <FAQsSection /> */}
            <Footer />
          </div>
      </div> 
    </div>
    </>
  );
};

export default SubscriptionPage;
