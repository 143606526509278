import { useEffect, useState } from 'react';
import { GET_PASSCODE, getFullApiUrl } from '../../utils/apiPath';

const useAuth = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('loggedIn') === 'true' || false
  );

  const login = () => {
    setLoggedIn(true);
    localStorage.setItem('loggedIn', 'true');
    window.location.reload();
  };

  const logout = () => {
    setLoggedIn(false);
    localStorage.clear();
    window.location.reload();
  };

  const getPasscode = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_PASSCODE)}?user_id=${localStorage.getItem("user_id")}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.passcode;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Return null or handle error appropriately
    }
  };

  const [current_passcode, set_current_passcode] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Check if passcode is already in localStorage
      const storedPasscode = localStorage.getItem('passcode');
      if (storedPasscode) {
        set_current_passcode(storedPasscode); // Use localStorage passcode if available
      } else {
        // Fetch passcode from API if not in localStorage
        // const passcode = await getPasscode();
        // set_current_passcode(passcode);
      }
    };
  
    if (loggedIn) {
      fetchData();
    }
  }, [loggedIn]);
  

  useEffect(() => {
    if (loggedIn && current_passcode) {
      // Delay passcode check for 5 seconds after login
      const delayPasscodeCheck = setTimeout(() => {
        // Function to check passcode in localStorage
        const checkPasscode = () => {
          const storedPasscode = localStorage.getItem('passcode');
          if (storedPasscode && current_passcode !== storedPasscode) {
            logout();
          }
        };

        // Initial check when component mounts
        checkPasscode();

        // Set up interval to check passcode every 5 seconds
        const intervalId = setInterval(checkPasscode, 5000); // Check every 5 seconds

        // Clean up interval on component unmount or logout
        return () => clearInterval(intervalId);
      }, 1000); // Delay check for 1 seconds after login

      return () => clearTimeout(delayPasscodeCheck);
    }
  }, [current_passcode, loggedIn, logout]);

  return { loggedIn, login, logout };
};

export default useAuth;
