import "./Roles.css";
import AdminSideBar from "../common/AdminSideBar/AdminSideBar";

import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import {
  getFullApiUrl,
  ADD_NEW_ROLES,
  GET_ALL_ROLES,
  DELETE_ROLES,
  UPDATE_ROLES,
} from "../../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import Header from "../../common/Header/Header";

const Roles = ({ toggleTheme }) => {
  const theme = useTheme();
  const [role_data, set_role_data] = useState([]);
  const [selectedrolesForDelete, setselectedrolesForDelete] = useState([]);
  const [selected_role, set_selected_role] = useState([]); // State to store selected roles for deletion
  const [showDeleteroleModal, setshowDeleteroleModal] = useState(false);
  const [selectedrolesForBulkDelete, setselectedrolesForBulkDelete] = useState(
    []
  );

  // State to store the selected role for deletion

  const [selectedrole, setselectedrole] = useState({
    role_id: "",
   
    description: "",
    permissions: "",
  });
  const [newroleData, setnewroleData] = useState({
    role_name: "",
    description: "",
    permissions: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedrole({ ...selectedrole, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewroleData({ ...newroleData, [name]: value });
  };
  const handleAddroleSubmit = async () => {
    console.log(newroleData);

    try {
      const response = await fetch(getFullApiUrl(ADD_NEW_ROLES), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newroleData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new role");
      }
      handleAddClose();

      setnewroleData({
        role_name: "",
        description: "",
        permissions: "",
      });
      fetchroleList();

      console.log("New role added successfully");
    } catch (error) {
      console.error("Error adding new role:", error.message);
    }
  };
  useEffect(() => {
    fetchroleList();
  }, [1000]);

  const fetchroleList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_ROLES));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("data", data);
      set_role_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleIndividualDeleteClick = (role) => {
    setselectedrolesForDelete([role]); // Select only the clicked role
    setshowDeleteroleModal(true);
  };

  const handleBulkroleDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_ROLES));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allroles = await response.json();

      const roleDetails = params.map((roleId) => {
        const roles = allroles.find((u) => u.role_id === roleId);
        console.log("ss", roles);
        return roles ? `${roles.role_name} (${roles.description})` : "";
      });
      console.log("detail", roleDetails);

      setselectedrolesForBulkDelete(params);
      setselectedrolesForDelete(roleDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedrolesForBulkDelete)
    try {
      if (
        !selectedrolesForBulkDelete ||
        !Array.isArray(selectedrolesForBulkDelete)
      ) {
        console.error("Invalid or empty params:", selectedrolesForBulkDelete);
        return;
      } else {
        await handleBulkDelete(selectedrolesForBulkDelete);
        // Close modal and refresh role list
        setShowBulkDeleteModal(false);
        fetchroleList();
      }
    } catch (error) {
      console.error("Error deleting roles in bulk:", error.message);
    }
  };
  // const handleDeleteroleConfirm = async () => {
  //   try {
  //     await Promise.all(
  //       selectedrolesForDelete.map((role) => handleDeleterole(role.role_id))
  //     );
  //     setselectedrolesForDelete([]);
  //     setshowDeleteroleModal(false);
  //     fetchroleList(); // Refresh role list after deletion
  //   } catch (error) {
  //     console.error("Error deleting roles:", error.message);
  //   }
  // };
  const handleBulkDelete = async (selected_role) => {
    set_selected_role(selected_role);
    try {
      // Iterate over selected roles and delete each one
      for (let roleId of selected_role) {
        await handleDeleterole(roleId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the role list
      fetchroleList();
    } catch (error) {
      console.error("Error deleting roles in bulk:", error.message);
    }
  };

  const handleDeleterole = async (roleId) => {
    console.log("delete click");
    console.log(roleId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_ROLES), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role_id: roleId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete role");
      }

      console.log("role deleted successfully:", roleId);
      // Refresh role list after deletion
      fetchroleList();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };
  const handleEditrole = (role) => {
    setselectedrole(role);
    setShowEditroleModal(true);
  };

  const handleEditroleData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_ROLES), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedrole),
      });

      if (!response.ok) {
        throw new Error("Failed to edit role");
      }

      console.log("role edited successfully");
      setShowEditroleModal(false);
      // Refresh role list after editing
      fetchroleList();
    } catch (error) {
      console.error("Error editing role:", error.message);
    }
  };

  //model open

  const [showAddroleForm, setShowAddroleForm] = React.useState(false);
  const [showEditroleModal, setShowEditroleModal] = React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddroleForm(true);
  const handleAddClose = () => setShowAddroleForm(false);
  const handleEditOpen = () => setShowEditroleModal(true);
  const handleEditClose = () => setShowEditroleModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const setPlaceholderColor = () => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      input::placeholder,textarea::placeholder {
        color: ${theme.palette.text.primary};
      }
    `;
    document.head.appendChild(styleTag);
  };

  setPlaceholderColor();

  const handleDeleteroleModalClose = () => {
    setshowDeleteroleModal(false);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
      <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          
          <div className="admin_page_heading">Roles Management</div>

          <div>
            <TableWithPagination
              data={role_data}
              fields={[
                {
                  type: "id",
                  field: "role_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 380,
                },
                {
                  type: "field",
                  field: "role_name",
                  headerName: "Role Name",
                  search_type: "text_search",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 400,
                },
                {
                  type: "field",
                  field: "description",
                  search_type: "text_search",
                  headerName: "Description",
                  minWidth: 290,
                  width: 300,
                  maxWidth: 350,
                },
                {
                  type: "field",
                  field: "permissions",
                  headerName: "Permissions",
                  search_type: "text_search",
                  minWidth: 290,
                  width: 250,
                  maxWidth: 350,
                },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  minWidth: 200,
                  width: 200,
                  maxWidth: 500,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEditrole(params)}>
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add Role">
                        <div
                          className="bulk_classify_button download_icons"
                          onClick={handleAddOpen}
                        >
                          <IoIosAddCircleOutline />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Roles">
                        <div
                          onClick={() => handleBulkroleDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30}/>
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            <Modal
              open={showAddroleForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New role Name</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="role_name"
                      label="role Name"
                      variant="standard"
                      name="role_name"
                      value={newroleData.role_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="description"
                      label="role Description"
                      variant="standard"
                      name="description"
                      value={newroleData.description}
                      onChange={handleNewInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="permissions"
                      label="Permissions"
                      variant="standard"
                      name="permissions"
                      value={newroleData.coins}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <button
                    onClick={handleAddroleSubmit}
                    className="add-user-btn"
                  >
                    Add role{" "}
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showEditroleModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update role</div>
                 
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="description"
                      label="role Description"
                      variant="standard"
                      name="description"
                      value={selectedrole ? selectedrole.description : ""}
                      onChange={handleInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="permissions"
                      label="Permissions"
                      variant="standard"
                      name="permissions"
                      value={selectedrole ? selectedrole.permissions : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  </Box>
                  <button onClick={handleEditroleData} className="add-user-btn">
                    Update role
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showDeleteroleModal}
              onClose={handleDeleteroleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete role(s)</div>
                  {/* {selectedrolesForDelete.map((role) => (
                    <div key={role.role_id}>
                      <div className="modal_text">
                        role: {role.role_name} ({role.description})
                      </div>
                    </div>
                  ))} */}
                  <div className="modal_text">
                    Does not delete selected role(s)?
                  </div>
                  <div className="form-group">
                    {/* <Button onClick={handleDeleteroleConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button> */}
                    <Button onClick={handleDeleteroleModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
            {/*Bulk Delete Roles Model */}
            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete role(s)</div>

                  {selectedrolesForDelete.map((role) => (
                    <div key={role}>
                      <div className="modal_text">role Name: {role} </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected role(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
