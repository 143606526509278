import AdminSideBar from "../common/AdminSideBar/AdminSideBar";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import {
  getFullApiUrl,
  ADD_NEW_WORKSPACE_MEMBER,
  GET_ALL_WORKSPACE_MEMBERS,
  DELETE_WORKSPACE_MEMBER,
  UPDATE_WORKSPACE_MEMBER,
  GET_WORKSPACE_MEMBER_BY_MEMBER,
  ADD_WORKSPACE_MEMBER_BY_MEMBER,
  ADD_NEW_CREDIT_POINTS_DETAIL,
  GET_ALL_CREDIT_POINTS_DETAILS,
  GET_ALL_CAMPAIGNS,
  GET_ALL_USERS,
  GET_ALL_TEAM,
  GET_ALL_WORKSPACE,
} from "../../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { LiaEdit } from "react-icons/lia";
import Header from "../../common/Header/Header";

const Credits_Points_Manage = ({ toggleTheme }) => {
  const theme = useTheme();
  const [credit_points_detail, set_credit_points_detail] = useState([]);
  const [
    selected_credit_point_holder_details_for_delete,
    setselected_credit_point_holder_details_for_delete,
  ] = useState([]);
  const [
    selected_credit_point_holder_details,
    set_selected_credit_point_holder_details,
  ] = useState([]); // State to store selected credit_point_holder_details for deletion
  const [
    show_credit_point_holder_details_modal,
    setshowDeleteworkspace_memberModal,
  ] = useState(false);
  const [
    selected_credit_point_holder_detailsForBulkDelete,
    set_selectedcredit_point_holder_detailsForBulkDelete,
  ] = useState([]);
  // const memberId = localStorage.getItem('user_id')

  // State to store the selected workspace_member for deletion

  // const [selected_credit_point_holder_details, set_selected_credit_point_holder_details] = useState({
  //   workspace_id: "",
  //   campaign_id: "",
  //   used_credit_points: "",
  //   user_id: "",
  // });

  const [new_credit_point_holder_details, set_new_credit_point_holder_details] =
    useState({
      workspace_id: "",
      campaign_id: "",
      used_credit_points: "",
      user_id: "",
      date: "",
    });
  const fetchData = async () => {
    // const campaigns = await fetchCampaignList();
    // const workspaces = await fetchworkspaceList();
    // const users = await fetchUserList();
    await fetchCreditPointDetailsList();
  };

  useEffect(() => {
    fetchData();
  }, [1000]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    set_selected_credit_point_holder_details({
      ...selected_credit_point_holder_details,
      [name]: value,
    });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    set_new_credit_point_holder_details({
      ...new_credit_point_holder_details,
      [name]: value,
    });
  };
  const handleAddworkspace_memberSubmit = async () => {
    try {
      // Prepare the data object to be sent to the server

      // Send a POST request to the server
      const response = await fetch(
        `${getFullApiUrl(ADD_NEW_CREDIT_POINTS_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(new_credit_point_holder_details),
        }
      );

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to add new workspace member");
      }

      // Handle success
      console.log("New workspace member added successfully");

      // Close the modal or perform any other actions as needed
      handleAddClose();

      // Fetch the updated list of workspace members
      fetchCreditPointDetailsList();
    } catch (error) {
      // Handle errors
      console.error("Error adding new workspace member:", error.message);
    }
  };

  const fetchCreditPointDetailsList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_CREDIT_POINTS_DETAILS)}`
      );

      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const creditPointsListData = await response.json();
      // const updatedCreditPointsListData = await creditPointsListData.map(
      //   (creditPointsRow) => {
      //     const userCreditPoints = users.find(
      //       (u) => u.user_id === creditPointsRow.user_id
      //     );
      //     if (userCreditPoints) {
      //       creditPointsRow.user_name = userCreditPoints.user_name;
      //     }
      //     const campaignCreditPoints = campaigns.find(
      //       (t) => t.campaign_id === creditPointsRow.campaign_id
      //     );
      //     if (campaignCreditPoints) {
      //       creditPointsRow.campaign_name = campaignCreditPoints.campaign_name;
      //     }
      //     const workspaceCreditPoints = workspaces.find(
      //       (t) => t.workspace_id === creditPointsRow.workspace_id
      //     );
      //     if (workspaceCreditPoints) {
      //       creditPointsRow.workspace_name =
      //         workspaceCreditPoints.workspace_name;
      //     }
      //     return creditPointsRow;
      //   }
      // );

      set_credit_points_detail(creditPointsListData);
      // (usedCreditPointsTotal);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchworkspaceList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const workspaces = await response.json();
      return workspaces;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCampaignList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_CAMPAIGNS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const handleIndividualDeleteClick = (workspace_member) => {
  //   setselected_credit_point_holder_details_for_delete([workspace_member]); // Select only the clicked workspace_member
  //   setshowDeleteworkspace_memberModal(true);
  // };

  // const handleBulkworkspace_memberDeleteClick = async (params) => {
  //   if (!params || params.length === 0 || !Array.isArray(params)) {
  //     console.error("Invalid or empty params:", params);
  //     return;
  //   }

  //   try {
  //     const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE_MEMBERS));
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const allcredit_point_holder_details = await response.json();

  //     const workspace_memberDetails = params.map((workspace_memberId) => {
  //       const credit_point_holder_details = allcredit_point_holder_details.find((u) => u.workspace_member_id === workspace_memberId);
  //       console.log("ss", credit_point_holder_details);
  //       return credit_point_holder_details ? `${credit_point_holder_details.user_id} (${credit_point_holder_details.description})` : "";
  //     });
  //     console.log("detail", workspace_memberDetails);

  //     setselectedcredit_point_holder_detailsForBulkDelete(params);
  //     setselected_credit_point_holder_details_for_delete(workspace_memberDetails);
  //     setShowBulkDeleteModal(true);
  //   } catch (error) {
  //     console.error("Error fetching workspace_member data:", error);
  //   }
  // };

  // const handleBulkDeleteConfirm = async () => {
  //   // console.log(selectedcredit_point_holder_detailsForBulkDelete)
  //   try {
  //     if (
  //       !selectedcredit_point_holder_detailsForBulkDelete ||
  //       !Array.isArray(selectedcredit_point_holder_detailsForBulkDelete)
  //     ) {
  //       console.error("Invalid or empty params:", selectedcredit_point_holder_detailsForBulkDelete);
  //       return;
  //     } else {
  //       await handleBulkDelete(selectedcredit_point_holder_detailsForBulkDelete);
  //       // Close modal and refresh workspace_member list
  //       setShowBulkDeleteModal(false);
  //       fetchWorkspaceMemberList();
  //     }
  //   } catch (error) {
  //     console.error("Error deleting credit_point_holder_details in bulk:", error.message);
  //   }
  // };
  // const handleDeleteworkspace_memberConfirm = async () => {
  //   try {
  //     await Promise.all(
  //       selected_credit_point_holder_details_for_delete.map((workspace_member) => handleDeleteworkspace_member(workspace_member.workspace_member_id))
  //     );
  //     setselected_credit_point_holder_details_for_delete([]);
  //     setshowDeleteworkspace_memberModal(false);
  //     fetchWorkspaceMemberList(); // Refresh workspace_member list after deletion
  //   } catch (error) {
  //     console.error("Error deleting credit_point_holder_details:", error.message);
  //   }
  // };
  // const handleBulkDelete = async (selected_credit_point_holder_details) => {
  //   set_selected_credit_point_holder_details(selected_credit_point_holder_details);
  //   try {
  //     // Iterate over selected credit_point_holder_details and delete each one
  //     for (let workspace_memberId of selected_credit_point_holder_details) {
  //       await handleDeleteworkspace_member(workspace_memberId);
  //     }
  //     console.log("Bulk delete successful");
  //     // After bulk deletion, refresh the workspace_member list
  //     fetchWorkspaceMemberList();
  //   } catch (error) {
  //     console.error("Error deleting credit_point_holder_details in bulk:", error.message);
  //   }
  // };

  // const handleDeleteworkspace_member = async (workspace_memberId) => {
  //   console.log("delete click");
  //   console.log(workspace_memberId);
  //   try {
  //     const response = await fetch(getFullApiUrl(DELETE_WORKSPACE_MEMBER), {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ workspace_member_id: workspace_memberId }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to delete workspace_member");
  //     }

  //     console.log("workspace_member deleted successfully:", workspace_memberId);
  //     // Refresh workspace_member list after deletion
  //     fetchWorkspaceMemberList();
  //   } catch (error) {
  //     console.error("Error deleting workspace_member:", error.message);
  //   }
  // };
  // const handleEditworkspace_member = (workspace_member) => {
  //   set_selected_credit_point_holder_details(workspace_member);
  //   setShowEditworkspace_memberModal(true);
  // };

  // const handleEditworkspace_memberData = async () => {
  //   try {
  //     const response = await fetch(getFullApiUrl(UPDATE_WORKSPACE_MEMBER), {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(selected_credit_point_holder_details),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to edit workspace_member");
  //     }

  //     console.log("workspace_member edited successfully");
  //     setShowEditworkspace_memberModal(false);
  //     // Refresh workspace_member list after editing
  //     fetchWorkspaceMemberList();
  //   } catch (error) {
  //     console.error("Error editing workspace_member:", error.message);
  //   }
  // };

  //model open

  const [showAddworkspace_memberForm, setShowAddworkspace_memberForm] =
    React.useState(false);
  const [showEditworkspace_memberModal, setShowEditworkspace_memberModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddworkspace_memberForm(true);
  const handleAddClose = () => setShowAddworkspace_memberForm(false);
  const handleEditOpen = () => setShowEditworkspace_memberModal(true);
  const handleEditClose = () => setShowEditworkspace_memberModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const handleDeleteworkspace_memberModalClose = () => {
    setshowDeleteworkspace_memberModal(false);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="admin_page_heading">Get Deductions Management</div>

          <div>
            <TableWithPagination
              data={credit_points_detail}
              fields={[
                {
                  type: "id",
                  field: "_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 380,
                },
                {
                  type: "field",
                  field: "user_name",
                  headerName: "User",
                  search_type: "text_search",
                  minWidth: 230,
                  width: 260,
                  maxWidth: 1400,
                },
                {
                  type: "field",
                  field: "campaign_name",
                  headerName: "Campaign",
                  search_type: "text_search",
                  minWidth: 220,
                  width: 260,
                  maxWidth: 1400,
                },
                
               
                {
                  type: "field",
                  field: "workspace_name",
                  headerName: "Workspace",
                  search_type: "text_search",
                  minWidth: 230,
                  width: 260,
                  maxWidth: 1400,
                },
                {
                  type: "field",
                  field: "team_name",
                  headerName: "Team",
                  search_type: "text_search",
                  minWidth: 230,
                  width: 260,
                  maxWidth: 1400,
                },
                {
                  type: "field",
                  field: "date",
                  headerName: "Date",
                  search_type: "date_search",
                  minWidth: 230,
                  width: 260,
                  maxWidth: 1400,
                },
                {
                  type: "field",
                  field: "used_credit_points",
                  headerName: "Used Credit Points",
                  search_type: "number_search",
                  minWidth: 230,
                  width: 260,
                  maxWidth: 1400,
                },
                
                

                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  minWidth: 200,
                  width: 200,
                  maxWidth: 500,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton
                          // onClick={() => handleEditworkspace_member(params)}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                          // onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
                {
                  type: "field",
                  field: "user_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 380,
                },
                {
                  type: "field",
                  field: "workspace_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 380,
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add Workspace_member">
                        <div
                          className="bulk_classify_button download_icons"
                          onClick={handleAddOpen}
                        >
                          <IoIosAddCircleOutline />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Workspace_members">
                        <div
                          // onClick={() => handleBulkworkspace_memberDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30} />
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            <Modal
              open={showAddworkspace_memberForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">
                    Add New Credit Point Holder Details
                  </div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="workspace_id"
                      label="workspace"
                      variant="standard"
                      name="workspace_id"
                      value={new_credit_point_holder_details.workspace_id}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="campaign_id"
                      label="Campaign"
                      variant="standard"
                      name="campaign_id"
                      value={new_credit_point_holder_details.campaign_id}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="user_id"
                      label="User"
                      variant="standard"
                      name="user_id"
                      value={new_credit_point_holder_details.user_id}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="date"
                      label="Date"
                      variant="standard"
                      name="date"
                      value={new_credit_point_holder_details.date}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="used_credit_points"
                      label="Used Credit Points"
                      variant="standard"
                      name="used_credit_points"
                      value={new_credit_point_holder_details.used_credit_points}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <button
                    onClick={handleAddworkspace_memberSubmit}
                    className="add-user-btn"
                  >
                    Add{" "}
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showEditworkspace_memberModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update workspace_member</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="user_id"
                      label="workspace_member Name"
                      variant="standard"
                      name="user_id"
                      value={
                        selected_credit_point_holder_details
                          ? selected_credit_point_holder_details.user_id
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="description"
                      label="workspace_member Description"
                      variant="standard"
                      name="description"
                      value={
                        selected_credit_point_holder_details
                          ? selected_credit_point_holder_details.description
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="permissions"
                      label="Permissions"
                      variant="standard"
                      name="permissions"
                      value={
                        selected_credit_point_holder_details
                          ? selected_credit_point_holder_details.permissions
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  </Box>
                  <button
                    // onClick={handleEditworkspace_memberData}
                    className="add-user-btn"
                  >
                    Update workspace_member
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={show_credit_point_holder_details_modal}
              onClose={handleDeleteworkspace_memberModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete workspace_member(s)</div>
                  {selected_credit_point_holder_details_for_delete.map(
                    (workspace_member) => (
                      <div key={workspace_member.workspace_id}>
                        <div className="modal_text">
                          workspace_member: {workspace_member.user_id} (
                          {workspace_member.description})
                        </div>
                      </div>
                    )
                  )}
                  <div className="modal_text">
                    Are you sure you want to delete selected
                    workspace_member(s)?
                  </div>
                  <div className="form-group">
                    <Button
                      // onClick={handleDeleteworkspace_memberConfirm}
                      color="error"
                    >
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button
                    // onClick={handleDeleteworkspace_memberModalClose}
                    >
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
            {/*Bulk Delete Workspace_members Model */}
            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete workspace_member(s)</div>

                  {selected_credit_point_holder_details_for_delete.map(
                    (workspace_member) => (
                      <div key={workspace_member}>
                        <div className="modal_text">
                          workspace_member Name: {workspace_member}{" "}
                        </div>
                      </div>
                    )
                  )}
                  <div className="modal_text">
                    Are you sure you want to delete selected
                    workspace_member(s)?
                  </div>
                  <div className="form-group">
                    <Button
                      // onClick={handleBulkDeleteConfirm}
                      color="error"
                    >
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button
                    // onClick={handleBulkDeleteClose}
                    >
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credits_Points_Manage;
