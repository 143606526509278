import React, { useEffect, useState } from "react";

import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import {
  GET_USER_DETAILS,
  UPDATE_CREDIT_POINTS_OF_USER,
  getFullApiUrl,
} from "../../utils/apiPath";
import "./BuyCreditsPage.css";
import {
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import SimCardRoundedIcon from "@mui/icons-material/SimCardRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";

const FormGrid = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));
const BuyCreditsPage = ({ toggleTheme }) => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [user_info, set_user_info] = useState([]);
  const fetchuserInfo = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_USER_DETAILS)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
        }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const userData = await response.json();
      set_user_info(userData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateCreditsOfUser = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(UPDATE_CREDIT_POINTS_OF_USER)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: localStorage.getItem("user_id"),
            credit_points: creditPoints,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      fetchuserInfo();
      setCreditPoints(100);
      set_show_payment_modal(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchuserInfo();
  }, []);
  const [show_payment_modal, set_show_payment_modal] = useState(false);
  const handle_payment_modal_close = () => {
    set_show_payment_modal(false);
  };
  const handleAddCreditPoints = () => {
    set_show_payment_modal(true);
  };
  const [creditPoints, setCreditPoints] = useState(100);
  const [price, setPrice] = useState("");

  useEffect(() => {
    // Calculate price when creditPoints state changes
    const newPrice = calculatePrice(creditPoints);
    setPrice(newPrice);
  }, [creditPoints]);

  const handleCreditPointsChange = (event) => {
    let newCreditPoints = parseInt(event.target.value);

    // Ensure the entered value is not less than 50 or negative
    if (newCreditPoints < 100 || isNaN(newCreditPoints)) {
      newCreditPoints = 100; // Set default value to 50 if less than 50 or not a number
    }

    setCreditPoints(newCreditPoints);
  };

  const calculatePrice = (creditPoints) => {
    // Your calculation logic here
    // For example, if you want the price to be double the credit points
    return creditPoints * 0.01;
  };
  const handle_payment = () => {
    updateCreditsOfUser();
  };
  const handleOpenTeamInfoSection = (team_id) => {
    return `/my_team/${team_id}`;
  };
  const [paymentType, setPaymentType] = React.useState("creditCard");
  const [cardNumber, setCardNumber] = React.useState("");
  const [cvv, setCvv] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
  };

  const handleCardNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    const formattedValue = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    if (value.length <= 16) {
      setCardNumber(formattedValue);
    }
  };

  const handleCvvChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 3) {
      setCvv(value);
    }
  };

  const handleExpirationDateChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    const formattedValue = value.replace(/(\d{2})(?=\d{2})/, "$1/");
    if (value.length <= 4) {
      setExpirationDate(formattedValue);
    }
  };
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="credit_record_page_container">
            <div className="credit_details_contailer">
              <div className="credit_record_container">
                <div className="credit_record">
                  <div className="credit_heading">TOTAL CREDITS</div>
                  <div className="credit_value">{user_info.credits}</div>
                </div>
                <div className="credit_record">
                  <div
                    className="add-credit-points"
                    onClick={handleAddCreditPoints}
                  >
                    <FaPlus />
                    <span>Add Credit Points</span>
                  </div>
                </div>
              </div>
              <div className="team_record_container">
                {/* <div className="team_record_in_buy_page">
                <div className="credit_heading"></div>
                <div className="credit_value"></div>
              </div> */}
                <div className="team_records_of_user">
                  <Accordion id="accordion_container_for_users_team">
                    <AccordionSummary expandIcon={<FaChevronDown />}>
                      <Typography variant="h6">
                        TOTAL NUMBER OF TEAMS YOU OWN :{" "}
                        {user_info.total_number_of_team}{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="users_team_details_container">
                        {user_info.team_details?.map((team, index) => (
                          <div key={index} className="users_team_record">
                            <Accordion>
                              <AccordionSummary expandIcon={<FaChevronDown />}>
                                <div className="users_team_record_heading">
                                  {team.team_name}
                                  <Tooltip title="Team Info">
                                    <IconButton>
                                      <RouterLink
                                        to={handleOpenTeamInfoSection(
                                          team.team_id
                                        )}
                                      >
                                        <PeopleAltIcon className="table_action_icon" />
                                      </RouterLink>
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="users_total_record_value">
                                  {team.team_description}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="credit_record_billing_container">
              <div className="credit_form_for_billing">
                <Stack spacing={{ xs: 3, sm: 6 }} useFlexGap>
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      aria-label="Payment options"
                      name="paymentType"
                      value={paymentType}
                      onChange={handlePaymentTypeChange}
                      sx={{
                        flexDirection: { sm: "column", md: "row" },
                        gap: 2,
                      }}
                    >
                      <Card
                        raised={paymentType === "creditCard"}
                        sx={{
                          maxWidth: { sm: "100%", md: "50%" },
                          flexGrow: 1,
                          outline: "1px solid",
                          outlineColor:
                            paymentType === "creditCard"
                              ? "primary.main"
                              : "divider",
                          backgroundColor:
                            paymentType === "creditCard"
                              ? "background.default"
                              : "",
                        }}
                      >
                        <CardActionArea
                          onClick={() => setPaymentType("creditCard")}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <CreditCardRoundedIcon
                              color="primary"
                              fontSize="small"
                            />
                            <Typography fontWeight="medium">Card</Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                      <Card
                        raised={paymentType === "bankTransfer"}
                        sx={{
                          maxWidth: { sm: "100%", md: "50%" },
                          flexGrow: 1,
                          outline: "1px solid",
                          outlineColor:
                            paymentType === "bankTransfer"
                              ? "primary.main"
                              : "divider",
                          backgroundColor:
                            paymentType === "bankTransfer"
                              ? "background.default"
                              : "",
                        }}
                      >
                        <CardActionArea
                          onClick={() => setPaymentType("bankTransfer")}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <AccountBalanceRoundedIcon
                              color="primary"
                              fontSize="small"
                            />
                            <Typography fontWeight="medium">
                              Bank account
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </RadioGroup>
                  </FormControl>
                  {paymentType === "creditCard" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          p: 3,
                          height: { xs: 300, sm: 350, md: 375 },
                          width: "100%",
                          borderRadius: "20px",
                          border: "1px solid ",
                          borderColor: "divider",
                          backgroundColor: "background.paper",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="subtitle2">
                            Credit card
                          </Typography>
                          <CreditCardRoundedIcon
                            sx={{ color: "text.secondary" }}
                          />
                        </Box>
                        <SimCardRoundedIcon
                          sx={{
                            fontSize: { xs: 48, sm: 56 },
                            transform: "rotate(90deg)",
                            color: "text.secondary",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: 2,
                          }}
                        >
                          <FormGrid sx={{ flexGrow: 1 }}>
                            <FormLabel htmlFor="card-number" required>
                              Card number
                            </FormLabel>
                            <OutlinedInput
                              id="card-number"
                              autoComplete="card-number"
                              placeholder="0000 0000 0000 0000"
                              required
                              value={cardNumber}
                              onChange={handleCardNumberChange}
                            />
                          </FormGrid>
                          <FormGrid sx={{ maxWidth: "20%" }}>
                            <FormLabel htmlFor="cvv" required>
                              CVV
                            </FormLabel>
                            <OutlinedInput
                              id="cvv"
                              autoComplete="CVV"
                              placeholder="123"
                              required
                              value={cvv}
                              onChange={handleCvvChange}
                            />
                          </FormGrid>
                        </Box>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <FormGrid sx={{ flexGrow: 1 }}>
                            <FormLabel htmlFor="card-name" required>
                              Name
                            </FormLabel>
                            <OutlinedInput
                              id="card-name"
                              autoComplete="card-name"
                              placeholder="John Smith"
                              required
                            />
                          </FormGrid>
                          <FormGrid sx={{ flexGrow: 1 }}>
                            <FormLabel htmlFor="card-expiration" required>
                              Expiration date
                            </FormLabel>
                            <OutlinedInput
                              id="card-expiration"
                              autoComplete="card-expiration"
                              placeholder="MM/YY"
                              required
                              value={expirationDate}
                              onChange={handleExpirationDateChange}
                            />
                          </FormGrid>
                        </Box>
                      </Box>
                      <FormControlLabel
                        control={<Checkbox name="saveCard" />}
                        label="Remember credit card details for next time"
                      />
                    </Box>
                  )}

                  {paymentType === "bankTransfer" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Alert severity="warning" icon={<WarningRoundedIcon />}>
                        Your order will be processed once we receive the funds.
                      </Alert>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Bank account
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Please transfer the payment to the bank account details
                        shown below.
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant="body1" color="text.secondary">
                          Bank:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          Mastercredit
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant="body1" color="text.secondary">
                          Account number:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          123456789
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant="body1" color="text.secondary">
                          Routing number:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          987654321
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={show_payment_modal}
        onClose={handle_payment_modal_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="model_box">
          <div className="delete-user-form">
            <div className="modal_heading_container">
              <div className="payment_modal_heading">Add credit point(s)</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={handle_payment_modal_close}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  m: 2,
                  width: "80%",
                  marginLeft: "10%",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Credit Points"
                type="number"
                variant="standard"
                value={creditPoints}
                onChange={handleCreditPointsChange}
                className="modal_form_input"
                onInput={(e) => {
                  e.target.value = Math.max(
                    0,
                    parseInt(e.target.value)
                  ).toString();
                }}
                min={0}
              />
            </Box>

            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  m: 2,
                  width: "80%",
                  marginLeft: "10%",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Price"
                type="text"
                variant="standard"
                value={"$" + price}
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{ min: 50 }}
                className="modal_form_input"
              />
            </Box>

            <div className="form-group">
              <Button
                onClick={handle_payment}
                variant="contained"
                color="primary"
                size="small"
              >
                Add Credits
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BuyCreditsPage;
