import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/authentication/useAuth";
import "./RegisterPage.css";
import {
  getFullApiUrl,
  REGISTRATION_API,
  VERIFY_EMAIL,
} from "../../utils/apiPath";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import LoginRegisterHeader from "../common/Header/LoginRegisterHeader/LoginRegisterHeader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const RegisterPage = ({ toggleTheme }) => {
  const { login } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [user_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Function to set the placeholder color dynamically
    const setPlaceholderColor = () => {
      const styleTag = document.createElement("style");
      styleTag.innerHTML = `
        input::placeholder {
          color: ${theme.palette.text.primary};
        }
      `;
      document.head.appendChild(styleTag);
    };

    // Call the function to set the placeholder color
    setPlaceholderColor();

    // Cleanup function to remove the style tag
    return () => {
      const styleTag = document.querySelector("style");
      if (styleTag) {
        document.head.removeChild(styleTag);
      }
    };
  }, [theme.palette.text.primary]);

  const isStrongPassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleRegister = async () => {
    try {
      if (
        !firstName ||
        !lastName ||
        !user_name ||
        !email ||
        !password ||
        password !== confirmPassword ||
        !acceptTerms
      ) {
        throw new Error(
          "Please fill in all fields, make sure passwords match, and accept the terms and conditions."
        );
      }

      if (!isStrongPassword(password)) {
        throw new Error(
          "Use a strong password with at least one uppercase letter, one lowercase letter, one special character, and a numeric value."
        );
      }

      if (user_name !== user_name.toLowerCase() || user_name.includes(" ")) {
        throw new Error(
          "User name should be in lowercase and should not contain spaces."
        );
      }

      setLoading(true);
      setError(null);

      const userData = {
        user_name: user_name,
        email_id: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
      };

      const apiUrl = getFullApiUrl(REGISTRATION_API);
      console.log("Registering with API:", apiUrl);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error(`Registration failed with status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Registration successful:", data);

      // If registration requires OTP verification, show OTP input
      if (
        data.message ===
        "OTP sent to your email. Please verify to complete registration."
      ) {
        setOtpVisible(true);
        // Disable input fields after successful registration
      } else {
        navigate("/registration-success");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setLoading(false);
      setError(error.message || "Registration failed");
    }
  };

  const handleConfirmOTP = async () => {
    try {
      setLoading(false);
      const otpData = { email: email, otp: otp };
      const apiUrl = getFullApiUrl(VERIFY_EMAIL);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpData),
      });

      if (!response.ok) {
        throw new Error(
          `OTP verification failed with status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("OTP verification successful:", data);

      if (data.message === "Email registered successfully") {
        setFirstName("");
        setLastName("");
        setFullName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        navigate("/registration-success");
      } else {
        setError("OTP not matched. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setError("Invalid OTP. Please try again.");
    }
  };

  const { loggedIn } = useAuth();

  return (
    <div className="page_Section">
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <LoginRegisterHeader
            onToggleTheme={toggleTheme}
            loggedIn={loggedIn}
          />
        </div>
        <div className="bodySection">
          <div className="register_container">
            <form className="form-container">
              <div className="center-align">
                <div className="container_header">Sign Up</div>
                <div className="container_text">
                  Please fill this form to create an account!
                </div>
              </div>
              <div className="input-container">
                <TextField
                  label="First Name"
                  variant="standard"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="input"
                  required
                  disabled={otpVisible}
                />
              </div>
              <div className="input-container">
                <TextField
                  label="Last Name"
                  variant="standard"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="input"
                  required
                  disabled={otpVisible}
                />
              </div>
              <div className="input-container">
                <TextField
                  label="User Name"
                  variant="standard"
                  type="text"
                  value={user_name}
                  onChange={(e) => setFullName(e.target.value)}
                  className="input"
                  required
                  disabled={otpVisible}
                />
              </div>
              <div className="input-container">
                <TextField
                  label="Email ID"
                  variant="standard"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input"
                  required
                  disabled={otpVisible}
                />
              </div>
              <div className="input-container">
                <TextField
                  label="Password"
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input"
                  required
                  disabled={otpVisible}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="input-container">
                <TextField
                  label="Confirm Password"
                  variant="standard"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="input"
                  required
                  disabled={otpVisible}
                />
              </div>
              <div className="termsAndConditionBox">
                <div className="checkboxInput">
                  <input
                    type="checkbox"
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    className="checkbox"
                    disabled={otpVisible}
                  />
                </div>
                <label className="label">
                  I accept the terms and conditions.
                </label>
              </div>
              {otpVisible && (
                <div className="input-container">
                  <TextField
                    label="OTP"
                    variant="standard"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              )}

              {/* Confirm OTP button */}
              {otpVisible && (
                <div className="button-container">
                  <button
                    type="button"
                    onClick={handleConfirmOTP}
                    // disabled={loading}
                    className="button"
                  >
                    Confirm OTP
                  </button>
                </div>
              )}

              {error && <div className="error_message">{error}</div>}
              {!otpVisible && (
                <div className="button-container">
                  <button
                    type="button"
                    onClick={handleRegister}
                    disabled={loading}
                    className="button"
                  >
                    {loading ? "Registering..." : "Register"}
                  </button>
                </div>
              )}
            </form>
            <div className="link-container">
              Already have an account? <Link to="/login">Login here</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterPage;
