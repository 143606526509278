// ThemeSwitcher.js
import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const ThemeSwitcher = ({ onToggleTheme, isDarkMode }) => {
  const [localDarkMode, setLocalDarkMode] = useState(isDarkMode);

  const handleToggleTheme = () => {
    const newMode = !localDarkMode;
    setLocalDarkMode(newMode);
    onToggleTheme(newMode);
  };

  return (
      <IconButton
        color="inherit"
        onClick={handleToggleTheme}
      >
        {localDarkMode ? <Brightness7Icon className="theme-switcher-icon" /> : <Brightness4Icon className="theme-switcher-icon" />}
      </IconButton>

  );
};

export default ThemeSwitcher;
