// LeftSectionWithMenu.js
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiMenu,FiX, FiUser, FiSettings, FiInfo, FiPhone } from 'react-icons/fi';
import { FaAngleDoubleUp, FaAngleDoubleDown, FaUserCog , FaHome, FaRegCreditCard   } from 'react-icons/fa';
import { GrProjects  , GrDocumentVerified  , GrHomeRounded} from "react-icons/gr";
import { MdCardMembership  , MdOutlineAdminPanelSettings  , MdOutlineProductionQuantityLimits  } from "react-icons/md";
import { VscHome } from "react-icons/vsc";
import { CgWorkAlt } from "react-icons/cg";
import { RiTeamFill } from "react-icons/ri";
import { MdOutlineCampaign } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { PiTicketDuotone } from "react-icons/pi";





import './LeftSectionWithMenu.css';
import { Divider } from '@mui/material';

const LeftSectionWithMenu = () => {
  const role_name = localStorage.getItem('role_name');
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('');

  const drawerWidth = 240
  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);
  

  const menuItems = [
    // {
    //   text: 'Home',
    //   icon: <VscHome   />,
    //   link: '/'
    // },
    {
      text: 'Campaigns',
      icon: <MdOutlineCampaign />,
      link: '/user_campaign'
    },
    {
      text: 'Workspaces',
      icon: <CgWorkAlt />,
      link: '/my_workspaces'
    },
    {
      text: 'Teams',
      icon: <RiTeamFill />,
      link: '/my_teams'
    },
    
    
 
    // { 
    //   text: 'Link Exchange',
    //   icon: <MdOutlineProductionQuantityLimits />,
    //   link: '/link_exchange_campaign'
    // },
    // { 
    //   text: 'Ticket',
    //   icon: <PiTicketDuotone />,
    //   link: '/ticket'
    // },
    
    
  ];
  
  if (role_name === 'admin' || role_name === 'super_admin') {
    menuItems.splice(1, 0, {
      text: 'Admin Panel',
      icon: <MdOutlineAdminPanelSettings   />,
      link: '/admin'
    });
  }
    
  
  const accountRelatedItems = [
    
    
    { 
      text: 'Account',
      icon: <FiUser />,
      link: '/my_account'
    },
    
    
    { 
      text: 'Billing',
      icon: <RiBillLine />,
      link: '/billing'
    },
    // { 
    //   text: 'Membership',
    //   icon: <MdCardMembership   />,
    //   link: '/subscription'
    // },
    { 
      text: 'Buy Credits',
      icon: <FaRegCreditCard   />,
      link: '/buy_credits'
    },


  ];
  const additionalItems = [
    { 
      text: 'Settings',
      icon: <FiSettings />,
      link: '/settings'
    },
    { 
      text: 'Ticket',
      icon: <PiTicketDuotone />,
      link: '/ticket'
    },
    
    { 
      text: 'Contact Us',
      icon: <FiPhone />,
      link: '/contact'
    },
    { 
      text: 'About Us',
      icon: <FiInfo />,
      link: '/about-us'
    },
  ];

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const [showAdminSubMenu, setShowAdminSubMenu] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState({});

  useEffect(() => {
    // Reset sub-menu state when the drawer is closed
    setShowAdminSubMenu(false);
  }, [drawerOpen]);

  const handleDrawerEnter = () => {
    setDrawerOpen(true);
  };

  const handleDrawerLeave = () => {
    setDrawerOpen(false);
  };

  const handleMenuIconClick = () => {
    setDrawerOpen(false);
  };

  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleAdminPanelClick = () => {
    setShowAdminSubMenu(!showAdminSubMenu);
  };
  const handleSubMenuClick = (text) => {
    setShowSubMenu((prev) => ({
      ...prev,
      [text]: !prev[text]
    }));
  };
  useEffect(() => {
    if (!drawerOpen) {
      // Reset submenu state when the drawer is closed
      setShowSubMenu({});
    }
  }, [drawerOpen]);
  const theme = useTheme()
  const HeaderItem = (
    <div className='drawerHeader'>
      <div className='headerLeft icon'>
      {drawerOpen ? (
        <FiX onClick={handleMenuIconClick} />
      ) : (
        <FiMenu onClick={handleMenuIconClick} />
      )}
      </div>
      {drawerOpen && (
        <div className='headerRight'>
          <div className='companyName' onClick={handleHomeClick}>
          <div style={{ fontFamily: "'Arial Black', Gadget, sans-serif", letterSpacing: '2px' }}>
          LiNKoMaTiC
          </div>
          </div>
        </div>
      )}
  </div>
);


  return (
    <Drawer
      variant="permanent"
      aria-label="open drawer"
      open={drawerOpen}
      onMouseEnter={handleDrawerEnter}
      onMouseLeave={handleDrawerLeave}
      className='drawer'
    >
      <div>
        
        
        <List id='leftsection_list top_section'>
        {HeaderItem}
        <Divider />

          {menuItems.map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              className='listItem'
            >
              <ListItemButton
                component={Link}
                to={item.link}
                className={`listItemButton ${
                  drawerOpen ? 'drawerOpen' : ''
                } ${currentPage === item.link ? 'active' : ''}`}
              >
                <ListItemIcon
                  className='listItemIcon icon' style={{
                    color: currentPage === item.link ? '#5E92D1' : theme.palette.logo.text,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />

        <List id='center_section'>
          {accountRelatedItems.map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              className='listItem'
            >
              <ListItemButton
                component={Link}
                to={item.link}
                className={`listItemButton ${
                  drawerOpen ? 'drawerOpen' : ''
                } ${currentPage === item.link ? 'active' : ''}`}
              >
                <ListItemIcon
                  className='listItemIcon icon'
                  style={{
                    color: currentPage === item.link ? '#5E92D1' : theme.palette.logo.text,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />



        <List id='bottom_section'>
          {additionalItems.map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              className='listItem'
            >
              <ListItemButton
                component={Link}
                to={item.link}
                className={`listItemButton ${
                  drawerOpen ? 'drawerOpen' : ''
                } ${currentPage === item.link ? 'active' : ''}`}
              >
                <ListItemIcon
                  className='listItemIcon icon'
                  style={{
                    color: currentPage === item.link ? '#5E92D1' : theme.palette.logo.text,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};
export default LeftSectionWithMenu;
