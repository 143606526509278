

import React, { useEffect, useState } from 'react';
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu"
import Header from "../common/Header/Header";

import { Container, Grid,Card, CardContent, Typography, Button, Avatar, TextField, Select, FormControl, InputLabel, MenuItem, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTheme } from '@mui/material';
import { Upload, Edit, Add } from '@mui/icons-material';
import {
  GET_USER_INFO_BY_ID,
  UPDATE_USER_INFO_BY_ID,
  getFullApiUrl,
} from "../../utils/apiPath";

const My_Account = ({ toggleTheme }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [editable, setEditable] = useState(false); // State to manage edit mode
  const [imageUrl, setImageUrl] = useState(null);
  const [userId, setUserId] = useState()
  const getUserId = (() =>{
    const userId = localStorage.getItem("user_id")
    return userId;
  })
  
  const fetchData = async () => {
  try {
    const user_id = await getUserId(); // Wait for getUserId() to resolve
    await fetchUserList(user_id); // Wait for fetchUserList() to complete
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

  const [editUser, setEditUser] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    user_name: "",
    email_id: "",
    img: ""
   
  });
  useEffect(() => {
    fetchData();
  }, [0]);

  const fetchUserList = async (user_id) => {
  try {
    const response = await fetch(
      `${getFullApiUrl(GET_USER_INFO_BY_ID)}?user_id=${user_id}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const userData = await response.json();
    setData(userData);
    setEditUser(userData); // Update editUser state with fetched data

    
    setImageUrl(userData.img || "https://bootdey.com/img/Content/avatar/avatar7.png");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

  
  const handleEditInput = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };


  const handleEditUserData = async () => {
    try {
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        throw new Error("User ID is required");
      }
      const updatedUserData = { ...editUser, user_id: userId };
      if (imageUrl) {
        updatedUserData.img = imageUrl;
      } // Include user_id in the request data
      const response = await fetch(
        getFullApiUrl(UPDATE_USER_INFO_BY_ID), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedUserData),
      });
  
      if (!response.ok) {
        throw new Error("Failed to edit subscription");
      }
  
      console.log("Subscription edited successfully");
  
      fetchData(); // Fetch updated data
      setEditable(false); // Disable edit mode after saving
    } catch (error) {
      console.error("Error editing subscription:", error.message);
    }
  };
  
  

  


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='page_Section' >
       <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
    <div className='rightSection_withMenu' >
    <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
         <div className="bodySection">
           <div className="center-align">
           <div className="admin_page_heading">My Profile</div>
          </div>
      <form >    
      <Grid container className='my_profile_container' spacing={2}>
        <Grid item md={5}>
          <Card className='card' >
            <CardContent>
              <div className="profile_img">
              {editable && (
                        <>
                          <input
                            type="file"
                            onChange={handleImageUpload}
                            accept="image/*"
                            style={{ display: "none" }}
                            id="upload-image"
                          />
                          <label htmlFor="upload-image">
                            <img
                              src={
                                imageUrl 
                              }
                              alt="User"
                              className="img"
                            />
                          </label>
                        </>
                      )}
                      {!editable && (
                        <img
                          src={
                            data.img ||
                            "https://bootdey.com/img/Content/avatar/avatar7.png"
                          }
                          alt="Admin"
                          className="img"
                        />
                      )}
                <div className="mt-3">
                  <Typography variant="h5">{data.first_name} {data.last_name}</Typography>
                  <Typography variant="subtitle1" color="textSecondary">{data.email_id}</Typography>
                  
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={7}>
          <Card className='card'>
            <CardContent style={{textAlign:"justify"}}>
              <div className="row">
                <div className="col-sm-3">
                  <div className='my_account_details_heading'>First Name</div>
                </div>
                <div className="col-sm-3">
                <TextField
                      fullWidth
                      variant="standard"
                      value={editable ? editUser.first_name : data.first_name}
                      onChange={handleEditInput}
                      InputProps={{
                        readOnly: !editable,
                      }}
                      name="first_name"
                    />
                  </div>
              </div>
             
              <div className="row">
                <div className="col-sm-3">
                   <div className='my_account_details_heading'> Last Name</div>
                </div>
                <div className="col-sm-9  text-secondary">
                <TextField
                      fullWidth
                      variant="standard"
                     
                      value={editable ? editUser.last_name : data.last_name}
                      onChange={handleEditInput}
                      InputProps={{
                        readOnly: !editable,
                      }}
                      name="last_name"
                    />
                </div>
              </div>
             
              <div className="row">
                <div className="col-sm-3">
                <div className='my_account_details_heading'>Email Id</div>
                </div>
                <div className="col-sm-9 text-secondary">
                <TextField
                      fullWidth
                      variant="standard"
                      value={editUser ? editUser.email_id : data.email_id}
                      onChange={handleEditInput}
                      InputProps={{
                        readOnly:true
                      }}
                      name="email_id"
                    />
                </div>
              </div>
             
              <div className="row">
                <div className="col-sm-3">
                <div className='my_account_details_heading'>User Name</div>
                </div>
                <div className="col-sm-9 text-secondary">
                <TextField
                      fullWidth
                      variant="standard"
                      value={editable ? editUser.user_name : data.user_name}
                      onChange={handleEditInput}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="user_name"
                    />
                </div>
              </div>
             
              {/* <div className="row">
                <div className="col-sm-3">
                <div className='my_account_details_heading'>Password</div>
                </div>
                <div className="col-sm-9 text-secondary">
                 ************
                </div>
              </div> */}
             
              <div className="row">
                <div className="col-sm-12">
                {editable ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={handleEditUserData}
                        startIcon={<Add />}
                        sx={{ mr: 2 }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setEditable(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => setEditable(true)}
                      startIcon={<Edit />}
                      sx={{ mr: 2 }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      </form>
      </div>
      </div>
    </div>
  );
};

export default My_Account;



