import React, { useState, useEffect } from "react";
import Header from "../common/Header/Header";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import useAuth from "../../hooks/authentication/useAuth";
import "./BillingPage.css";
import {
  GET_CREDIT_RECORDS_OF_USER,
  GET_CREDIT_RECORDS_OF_TODAY,
  getFullApiUrl,
  GET_CREDIT_RECORDS_OF_THIS_WEEK,
  GET_CREDIT_RECORDS_OF_THIS_MONTH,
  GET_CREDIT_POINTS_OF_USER,
  GET_CREDIT_RECORD,
} from "../../utils/apiPath";
import {
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CustomizedTables from "../../components/CustomizedTables/CustomizedTables";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import { FaPlus } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

const localizer = momentLocalizer(moment);

const BillingPage = ({ toggleTheme }) => {
  const [show_payment_modal, set_show_payment_modal] = useState(false);
  const handle_payment_modal_close = () => {
    set_show_payment_modal(false);
  };
  const navigate = useNavigate();
  const { loggedIn } = useAuth();
  const [credit_points_detail, set_credit_points_detail] = useState([]);
  const [credit_points_left, set_credit_points_left] = useState();
  const [credit_use_today, set_credit_use_today] = useState();
  const [credit_use_this_week, set_credit_use_this_week] = useState();
  const [credit_use_this_month, set_credit_use_this_month] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [creditRecords, setCreditRecords] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
 

  const fetchData = async () => {
    fetchCreditPointDetailsList();
    fetchCreditRecord();
    // fetchCreditPointDetailsThisWeek();
    // fetchCreditPointDetailsThisMonth();
    // fetchCreditPointLeft();
  };

  const fetchCreditRecord = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_CREDIT_RECORD), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: localStorage.getItem("user_id") }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCreditRecords(data);
    } catch (error) {
      console.error("Error fetching credit records:", error);
    }
  };
  const fetchCreditPointDetailsList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_CREDIT_RECORDS_OF_USER
        )}?user_id=${localStorage.getItem("user_id")}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const creditPointsListData = await response.json();

      set_credit_points_detail(creditPointsListData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleAddCreditPoints = () => {
    set_show_payment_modal(true);
  };

  const fields = [
    {
      type: "id",
      field: "campaign_name",
      headerName: "",
      minWidth: 1,
      width: 1,
      maxWidth: 200,
    },
    {
      type: "field",
      field: "workspace_name",
      headerName: "Workspace",
      search_type: "text_search",
      minWidth: 150,
      width: 180,
      maxWidth: 200,
    },
    {
      type: "field",
      field: "used_credit_points",
      headerName: "Used Points",
      search_type: "number_search",
      minWidth: 150,
      width: 170,
      maxWidth: 200,
    },
    {
      type: "field",
      field: "date",
      headerName: "Date",
      search_type: "text_search",
      minWidth: 150,
      width: 180,
      maxWidth: 200,
    },
  ];

  const aggregatedData = {};
  const currentDate = new Date();
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);


  

  const chartDataForLineChart = Object.keys(aggregatedData).map((date) => ({
    date: date,
    used_credit_points: aggregatedData[date],
  }));

  const chartDataForBarChart = Object.keys(aggregatedData).map((date) => ({
    date: date,
    used_credit_points: aggregatedData[date],
  }));

  const [creditPoints, setCreditPoints] = useState(50);
  const [price, setPrice] = useState("");

  useEffect(() => {
    // Calculate price when creditPoints state changes
    const newPrice = calculatePrice(creditPoints);
    setPrice(newPrice);
  }, [creditPoints]);

  const handleCreditPointsChange = (event) => {
    let newCreditPoints = parseInt(event.target.value);

    // Ensure the entered value is not less than 50 or negative
    if (newCreditPoints < 50 || isNaN(newCreditPoints)) {
      newCreditPoints = 50; // Set default value to 50 if less than 50 or not a number
    }

    setCreditPoints(newCreditPoints);
  };

  const calculatePrice = (creditPoints) => {
    // Your calculation logic here
    // For example, if you want the price to be double the credit points
    return creditPoints * 2;
  };

  const handle_payment = () => {
    navigate("/");
  };

  return (
    <div className="page_Section">
      {loggedIn && (
        <div className="leftSection_withMenu">
          <LeftSectionWithMenu />
        </div>
      )}
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} loggedIn={loggedIn} />
        </div>
        <div className="bodySection">
          <div className="dashboard-header">
            <div className="dashboard-header_content">
              <Typography variant="h4" id="billing-header">
                Dashboard
              </Typography>
              <div
                className="add-credit-points"
                onClick={handleAddCreditPoints}
              >
                <FaPlus />
                <span>Add Credit Points</span>
              </div>
            </div>
            <div className="credit_price_details_container">
              <div className="credit-details-container">
                <div className="credit-record">
                  <div className="record-heading">Total Credit Points Left</div>
                  <div className="record-value"> {creditRecords.total_credits_of_user}</div>
                </div>
                <div className="credit-record">
                  <div className="record-heading">
                    Total Credit Points Used Today
                  </div>
                  <div className="record-value"> {creditRecords.used_credit_points_today}</div>
                </div>
                <div className="credit-record">
                  <div className="record-heading">
                    Total Credit Points Used This Week
                  </div>
                  <div className="record-value">{creditRecords.used_credit_points_this_week}</div>
                </div>
                <div className="credit-record">
                  <div className="record-heading">
                    Total Credit Points Used This Month
                  </div>
                  <div className="record-value"> {creditRecords.used_credit_points_this_month}</div>
                </div>
              </div>
              <div className="price_record">
              <div className="price-record">
                <div className="record-heading">Threshold Value</div>
                <div className="record-value"> {creditRecords.threshold_value}</div>
              </div>
              <div className="price-record">
                <div className="record-heading">
                  Placeholder Demo Value to showcase
                </div>
                <div className="record-value"> {creditRecords.threshold_value}</div>
              </div>
            </div>
            </div>
           
          </div>
          <div className="credit-details-table-section">
            <div className="table-container">
              <TableWithPagination
                data={credit_points_detail}
                fields={fields}
                bulk_actions={[
                  {
                    action: "add",
                    renderCell: (params) => {
                      return <></>;
                    },
                  },
                ]}
              />
            </div>
            <div className="chart-container">
              <div className="calendar-container">
                {/* <Calendar
                  localizer={localizer}
                  events={credit_points_detail.map((event) => ({
                    title: event.used_credit_points,
                    start: new Date(event.date),
                    end: new Date(event.date),
                  }))}
                  startAccessor="start"
                  endAccessor="end"
                  defaultDate={selectedDate}
                  views={["month"]}
                  onSelectEvent={(event) => console.log(event)}
                  style={{ height: 300, width: 325, marginLeft: 15 }}
                /> */}

                <div className="chart-container-details">
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={chartDataForBarChart}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="used_credit_points" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={chartDataForLineChart}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="used_credit_points"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <Modal
            open={show_payment_modal}
            onClose={handle_payment_modal_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_text">Add credit point(s)</div>

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Credit Points"
                    type="number"
                    variant="standard"
                    value={creditPoints}
                    onChange={handleCreditPointsChange}
                    className="modal_form_input"
                  />
                </Box>

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Price"
                    type="text"
                    variant="standard"
                    value={"$" + price}
                    InputProps={{
                      readOnly: true,
                    }}
                    inputProps={{ min: 50 }}
                    className="modal_form_input"
                  />
                </Box>

                <div className="form-group">
                  <Button onClick={handle_payment} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handle_payment_modal_close}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
