import AdminSideBar from "../common/AdminSideBar/AdminSideBar";
import Header from "../../common/Header/Header";

import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    useTheme,
    Tooltip,
    IconButton,
    Autocomplete,
    Breadcrumbs,
    Link as MuiLink
} from "@mui/material";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';

import { useState } from "react";
import Modal from "@mui/material/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";

import { DELETE_ALL_USERS_DATA,DELETE_ALL_TEAMS_DATA, getFullApiUrl, DELETE_ALL_TEAM_MEMBERS, DELETE_ALL_WORKSPACE_DATA ,DELETE_ALL_DATA} from "../../../utils/apiPath";

export default function All_Delete_data({ toggleTheme }) {
    const user_id = localStorage.getItem("user_id")

    const [showDeleteUsersModal, setshowDeleteUsersModal] = useState(false);
    const [showDeleteTeamsModal, setshowDeleteTeamsModal] = useState(false);
    const [showDeleteTeamMembersModal, setshowDeleteTeamMembersModal] = useState(false);
    const [showDeleteWorkspaceModal, setshowDeleteWorkspaceModal] = useState(false);
    const [showDeleteAlldataModal, setshowDeleteAlldataModal] = useState(false);
    

// Delete Users
    const handleIndividualUsersDeleteClick = () => {
        // Select only the clicked subscription
        setshowDeleteUsersModal(true);
    };

    const handleDeleteUsersModalClose = () => {
        setshowDeleteUsersModal(false);
    };

    const handleDeleteUsersConfirm = async () => {
        try {

            handleDeleteusers()
            setshowDeleteUsersModal(false);
            //   fetchsubscriptionList(); // Refresh subscription list after deletion
        } catch (error) {
            console.error("Error deleting subscriptions:", error.message);
        }
    };

    const handleDeleteusers = async () => {
        try {
            const response = await fetch(`${getFullApiUrl(DELETE_ALL_USERS_DATA)}?user_id=${user_id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },

            });


            if (!response.ok) {
                const data = await response.json();
                alert(data.error)

                throw new Error("Failed to delete User");
            }

            console.log("subscription deleted user:");
            // Refresh subscription list after deletion
            //   fetchsubscriptionList();
        } catch (error) {

            console.error("Error deleting subscription:", error.message);
        }
    };

// Delete Teams
    const handleIndividualTeamsDeleteClick = () => {
        // Select only the clicked subscription
        setshowDeleteTeamsModal(true);
    };
    const handleDeleteTeamsModalClose = () => {
        setshowDeleteTeamsModal(false);
    };
    const handleDeleteTeamsConfirm = async () => {
        try {

            handleDeleteTeams()
            setshowDeleteTeamsModal(false);
            //   fetchsubscriptionList(); // Refresh subscription list after deletion
        } catch (error) {
            console.error("Error deleting subscriptions:", error.message);
        }
    };
    const handleDeleteTeams = async () => {
        try {
            const response = await fetch(getFullApiUrl(DELETE_ALL_TEAMS_DATA), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                const data = await response.json();
                alert(data.error)

                throw new Error("Failed to delete Team");
            }
            console.log("Team deleted :");
         
        } catch (error) {

            console.error("Error deleting Team:", error.message);
        }
    };

// Delete Team Members

    const handleIndividualTeamMembersDeleteClick = () => {
        // Select only the clicked subscription
        setshowDeleteTeamMembersModal(true);
    };
    const handleDeleteTeamMembersModalClose = () => {
        setshowDeleteTeamMembersModal(false);
    };
    const handleDeleteTeamMembersConfirm = async () => {
        try {

            handleDeleteTeamMembers()
            setshowDeleteTeamMembersModal(false);
            //   fetchsubscriptionList(); // Refresh subscription list after deletion
        } catch (error) {
            console.error("Error deleting team members:", error.message);
        }
    };
    const handleDeleteTeamMembers = async () => {
        try {
            const response = await fetch(getFullApiUrl(DELETE_ALL_TEAM_MEMBERS), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                const data = await response.json();
                alert(data.error)

                throw new Error("Failed to delete Team Members");
            }
            console.log("Team Members deleted :");
         
        } catch (error) {

            console.error("Error deleting Team Members:", error.message);
        }
    };

// Delete Workspace

const handleIndividualWorkspaceDeleteClick = () => {
    // Select only the clicked subscription
    setshowDeleteWorkspaceModal(true);
};
const handleDeleteWorkspaceModalClose = () => {
    setshowDeleteWorkspaceModal(false);
};
const handleDeleteWorkspaceConfirm = async () => {
    try {

        handleDeleteWorkspace()
        setshowDeleteWorkspaceModal(false);
        //   fetchsubscriptionList(); // Refresh subscription list after deletion
    } catch (error) {
        console.error("Error deleting subscriptions:", error.message);
    }
};
const handleDeleteWorkspace = async () => {
    try {
        const response = await fetch(getFullApiUrl(DELETE_ALL_WORKSPACE_DATA), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const data = await response.json();
            alert(data.error)

            throw new Error("Failed to delete Team");
        }
        console.log("Team deleted :");
     
    } catch (error) {

        console.error("Error deleting Team:", error.message);
    }
};
// delete all data

const handleIndividualAlldataDeleteClick = () => {
    // Select only the clicked subscription
    setshowDeleteAlldataModal(true);
};
const handleDeleteAlldataModalClose = () => {
    setshowDeleteAlldataModal(false);
};
const handleDeleteAlldataConfirm = async () => {
    try {

        handleDeleteAlldata()
        setshowDeleteAlldataModal(false);
        //   fetchsubscriptionList(); // Refresh subscription list after deletion
    } catch (error) {
        console.error("Error deleting subscriptions:", error.message);
    }
};
const handleDeleteAlldata = async () => {
    try {
        const response = await fetch(`${getFullApiUrl(DELETE_ALL_DATA)}?user_id=${user_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const data = await response.json();
            alert(data.error)

            throw new Error("Failed to delete Team");
        }
        console.log("Team deleted :");
     
    } catch (error) {

        console.error("Error deleting Team:", error.message);
    }
};
    return (
        <>
            <div className="page_Section">
                <div className="leftSection_withMenu">
                    <AdminSideBar />
                </div>
                <div className="rightSection_withMenu">
                    <div className="headerSection_withMenu">
                        <Header onToggleTheme={toggleTheme} />
                    </div>
                    <div className="bodySection">
                        <div className="admin_page_heading">Delete All Data</div>
                        <div>
                       

<div className="delete_all_data_container">
    <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={12}>
            <div className="delete_all_data_icons">
                <div>Delete All Users Data</div>
                <Tooltip title="Delete All Users Data">
                    <IconButton onClick={() => handleIndividualUsersDeleteClick()}>
                        <PermContactCalendarIcon className="table_action_icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
            <div className="delete_all_data_icons">
                <div>Delete All Teams Data</div>
                <Tooltip title="Delete All Teams Data">
                    <IconButton onClick={() => handleIndividualTeamsDeleteClick()}>
                        <DeleteOutlineIcon className="table_action_icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
            <div className="delete_all_data_icons">
                <div>Delete All Teams Members Data</div>
                <Tooltip title="Delete All Teams Members Data">
                    <IconButton onClick={() => handleIndividualTeamMembersDeleteClick()}>
                        <Diversity1Icon className="table_action_icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
            <div className="delete_all_data_icons">
                <div>Delete All Workspace Data</div>
                <Tooltip title="Delete All Workspace Data">
                    <IconButton onClick={() => handleIndividualWorkspaceDeleteClick()}>
                        <WorkIcon className="table_action_icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
            <div className="delete_all_data_icons">
                <div>Delete All Data</div>
                <Tooltip title="Delete All Data">
                    <IconButton onClick={() => handleIndividualAlldataDeleteClick()}>
                        <DeleteIcon className="table_action_icon" />
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>
    </Grid>
</div>


 {/* Delete Users */}

                            <Modal
                                open={showDeleteUsersModal}
                                onClose={handleDeleteUsersModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box id="model_box">
                                    <div className="delete-user-form">
                                        <div className="modal_text">Delete User(s)</div>

                                        <div className="modal_text">
                                            Are you sure you want to delete All Users ?
                                        </div>
                                        <div className="form-group">
                                            <Button
                                                onClick={handleDeleteUsersConfirm}
                                                color="error"
                                            >
                                                <MdDelete className="formIcons" />
                                            </Button>
                                            <Button onClick={handleDeleteUsersModalClose}>
                                                <IoMdCloseCircle className="formIcons" />
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
 {/*  Delete Teams */}

                            <Modal
                                open={showDeleteTeamsModal}
                                onClose={handleDeleteTeamsModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box id="model_box">
                                    <div className="delete-user-form">
                                        <div className="modal_text">Delete User(s)</div>

                                        <div className="modal_text">
                                            Are you sure you want to delete All Teams ?
                                        </div>
                                        <div className="form-group">
                                            <Button
                                                onClick={handleDeleteTeamsConfirm}
                                                color="error"
                                            >
                                                <MdDelete className="formIcons" />
                                            </Button>
                                            <Button onClick={handleDeleteTeamsModalClose}>
                                                <IoMdCloseCircle className="formIcons" />
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
{/* Delete Team Members */}

                            <Modal
                                open={showDeleteTeamMembersModal}
                                onClose={handleDeleteTeamMembersModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box id="model_box">
                                    <div className="delete-user-form">
                                        <div className="modal_text">Delete User(s)</div>

                                        <div className="modal_text">
                                            Are you sure you want to delete All Team Members ?
                                        </div>
                                        <div className="form-group">
                                            <Button
                                                onClick={handleDeleteTeamMembersConfirm}
                                                color="error"
                                            >
                                                <MdDelete className="formIcons" />
                                            </Button>
                                            <Button onClick={handleDeleteTeamMembersModalClose}>
                                                <IoMdCloseCircle className="formIcons" />
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>

 {/* Delete Workspace */}

                            <Modal
                                open={showDeleteWorkspaceModal}
                                onClose={handleDeleteWorkspaceModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box id="model_box">
                                    <div className="delete-user-form">
                                        <div className="modal_text">Delete User(s)</div>

                                        <div className="modal_text">
                                            Are you sure you want to delete All Workspace ?
                                        </div>
                                        <div className="form-group">
                                            <Button
                                                onClick={handleDeleteWorkspaceConfirm}
                                                color="error"
                                            >
                                                <MdDelete className="formIcons" />
                                            </Button>
                                            <Button onClick={handleDeleteWorkspaceModalClose}>
                                                <IoMdCloseCircle className="formIcons" />
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
{/* Delete all data */}

                            <Modal
                                open={showDeleteAlldataModal}
                                onClose={handleDeleteAlldataModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box id="model_box">
                                    <div className="delete-user-form">
                                        <div className="modal_text">Delete User(s)</div>

                                        <div className="modal_text">
                                            Are you sure you want to delete All Data ?
                                        </div>
                                        <div className="form-group">
                                            <Button
                                                onClick={handleDeleteAlldataConfirm}
                                                color="error"
                                            >
                                                <MdDelete className="formIcons" />
                                            </Button>
                                            <Button onClick={handleDeleteAlldataModalClose}>
                                                <IoMdCloseCircle className="formIcons" />
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}