import * as React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ThemeSwitcher from "../../../components/ThemeSwitcher/ThemeSwitcher";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { MdSubscriptions } from "react-icons/md";
import "./Header.css";
import useAuth from "../../../hooks/authentication/useAuth";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import {
  GET_USER_INFO_BY_ID,
  GET_USER_NAME_BY_ID,
  getFullApiUrl,
} from "../../../utils/apiPath";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


const Header = ({ onToggleTheme }) => {
  const { loggedIn, logout } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true); // State to track scroll position
  const [user_image, set_user_image] = useState();

  useEffect(() => {
    if (isLoggingOut) {
      window.location.reload();
    }
  }, [isLoggingOut]);

  const handleLogout = () => {
    setIsLoggingOut(true);
    logout();
  };
  const navigate = useNavigate();

  const subscriptionHandle = () => {
    navigate("/subscription");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };
  const openSettings = () => {
    navigate("/settings");
  };
  const openBillingPage = () => {
    navigate("/billing");
  };
  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMyAccount = () => {
    setAnchorEl(null);
    navigate("/my_account");
  };

  const theme = useTheme();
  const userId = localStorage.getItem("user_id");
  const [user_info, set_user_info] = useState([]);
  const fetchuserInfo = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_USER_INFO_BY_ID)}?user_id=${userId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const userData = await response.json();
      set_user_info(userData);
      set_user_image(userData.img);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchuserInfo();
  }, [1000]);

  return (
    <React.Fragment>
      <div className="headerSection">
        <div className="rightSection">
          {loggedIn ? (
            <>
              <div className="customBoxStyles">
                <IconButton>
                  <ThemeSwitcher onToggleTheme={onToggleTheme} />
                </IconButton>

                <Tooltip title="Account settings" style={{ padding: "16px" }}>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    {user_image ? (
                      <img
                        src={user_image}
                        alt="User profile"
                        style={{
                          borderRadius: "50%",
                          width: "var(--icon-font-size)",
                          height: "var(--icon-font-size)",
                        }}
                      />
                    ) : (
                      <AccountCircleIcon
                        style={{ fontSize: "var(--icon-font-size)" }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible", // Set overflow to visible
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 28,
                      height: 28,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      overflow: "hidden",
                      top: 0,
                      right: 10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                
                <MenuItem onClick={handleMyAccount}>
                {user_image ? (
                      <img
                        src={user_image}
                        alt="User profile"
                        style={{
                          borderRadius: "50%",
                          width: "var(--icon-font-size)",
                          height: "var(--icon-font-size)",
                         
                        }}
                      />
                      ) : (
                  <Avatar>
                      {user_info.first_name
                        ? user_info.first_name.charAt(0).toUpperCase()
                        : ""}
                    </Avatar> )}  <div className="myprofile" > {user_info.first_name} {user_info.last_name}</div>
                 
                </MenuItem>
                <Divider />
                <MenuItem onClick={openBillingPage} >
                  <ListItemIcon>
                    <AttachMoneyIcon fontSize="small" />
                  </ListItemIcon>
                  Billing and Cost Management
                </MenuItem>
                <MenuItem onClick={openSettings}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <div className="loginRegisterButtons">
                <ThemeSwitcher onToggleTheme={onToggleTheme} />
                <Button
                  id="loginButton"
                  className="navigationButton"
                  color="inherit"
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
                <Button
                  id="registerButton"
                  color="inherit"
                  onClick={handleRegisterClick}
                >
                  Register
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
