import React, { useState, useEffect } from "react";
import PrimarySection from "../LandingPage/PrimarySection/PrimarySection";
import useAuth from "../../hooks/authentication/useAuth";

import AboutUs from "../LandingPage/AboutUsSection/AboutUs";
import FAQsSection from "../LandingPage/FAQsSection/FAQsSection";
import Footer from "../common/Footer/Footer";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import ContactUs from "../LandingPage/ContactUsSection/ContactUs";
import { Alert, Button, Skeleton } from "@mui/material";
import { MdOutlineCampaign } from "react-icons/md";
import { CgArrowRight } from "react-icons/cg";
import "./HomePage.css";
import {
  GET_PASSCODE,
  RECENTLY_USED_CAMPAIGNS,
  GET_CREDIT_RECORD,
  getFullApiUrl,
} from "../../utils/apiPath";
import { useNavigate } from "react-router-dom";
import Robo from "../common/Robo/Robo";
import "./HomePage.css";
import bigToolVideo from "../../assets/big_tool_video.mp4";
import classifying_video from "../../assets/classifying_video.mp4";
import identify_paragraph from "../../assets/identify_paragraph.mp4";

const HomePage = ({ toggleTheme }) => {
  const { loggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [creditRecords, setCreditRecords] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsLoaded, setCampaignsLoaded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showArrow, setShowArrow] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [lastVisitedCampaign, setLastVisitedCampaign] = useState(null);

  const [fetchError, setFetchError] = useState(false); // New state for fetch error

  const fetchCampaignList = async () => {
    try {
      const response = await fetch(getFullApiUrl(RECENTLY_USED_CAMPAIGNS), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: localStorage.getItem("user_id") }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCampaignList(data);
      if (data.length > 0) {
        setSelectedCampaign(data[0]);
      }
      setCampaignsLoaded(true);
      setFetchError(false); // Reset fetch error state if successful
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchError(true); // Set fetch error state if an error occurs
    }
  };

  useEffect(() => {
    fetchCampaignList();
  }, []);

  const handleCampaignClick = (campaignId) => {
    navigate(`/solutions/${campaignId}`);
  };

  const handleShowArrow = (campaign) => {
    setSelectedCampaign(campaign);
    setLastVisitedCampaign(campaign.campaign_id);
    setShowArrow(campaign.campaign_id);
  };

  const handleHideArrow = () => {
    setShowArrow(lastVisitedCampaign);
  };

  const renderCampaigns = () => {
    const campaignsToShow = showAll ? campaignList : campaignList.slice(0, 6);
  
    return (
      <div className="campaign_table">
        <div className="campaign_table_header">
          <div className="campaign_table_header_item">
            {campaignsLoaded ? (
              "Recently Visited Campaign 📢"
            ) : (
              <Skeleton variant="text" width="80%" />
            )}
          </div>
        </div>
        <div className="campaign_table_body">
          {campaignsLoaded
            ? campaignsToShow.map((campaign) => (
                <div
                  key={campaign.campaign_id}
                  className={`campaign_table_row ${
                    campaign.campaign_id === showArrow ? "highlight" : ""
                  }`}
                  onMouseEnter={() => handleShowArrow(campaign)}
                  onMouseLeave={handleHideArrow}
                  onClick={() => handleCampaignClick(campaign.campaign_id)}
                >
                  <div className="campaign_table_cell">
                    <a
                      href={`/solutions/${campaign.campaign_id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCampaignClick(campaign.campaign_id);
                      }}
                      className="campaign_link"
                    >
                      <div className="campaign_table_cell_icon">
                        <MdOutlineCampaign />
                      </div>
                      <div className="campaign_table_cell_text">
                        <div>{campaign.campaign_name}</div>
                        {campaign.campaign_id === showArrow && (
                          <div className="campaign_table_cell_icon">
                            <CgArrowRight />
                          </div>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
              ))
            : Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="campaign_table_row">
                  <div className="campaign_table_cell">
                    <Skeleton
                      variant="rectangular"
                      width={24}
                      height={24}
                      className="campaign_table_cell_icon"
                    />
                    <div className="campaign_table_cell_text">
                      <Skeleton variant="text" width="80%" />
                    </div>
                  </div>
                </div>
              ))}
        </div>
       {campaignsLoaded ? (
         <div className="more_less_button_container">
         {!showAll && campaignList.length > 5 && (
           <Button onClick={() => setShowAll(true)}>
             {campaignsLoaded ? "More" : <Skeleton variant="text" width="50%" />}
           </Button>
         )}
         {showAll && (
           <Button onClick={() => setShowAll(false)}>
             {campaignsLoaded ? "Less" : <Skeleton variant="text" width="50%" />}
           </Button>
         )}
       </div>
       ):(
        <div className="more_less_button_container">
           <Skeleton variant="text" width="80%" />
        </div>
       )}
      </div>
    );
  };
  
  

  const renderCreditRecords = () => {
 
    return (
      <div className="credit_records_container">
        {/* Owner Details Section */}
        <div className="credit_records_container_body">
          <div className="credit_records_header">
            <div className="credit_records_header_item">
              <div className="credit_records_header_item_text">
                {campaignsLoaded ? (
                  `${selectedCampaign.campaign_name}'s Details`
                ) : (
                  <Skeleton variant="text" width="60%" />
                )}
              </div>
              <div className="credit_records_header_item_icon">👑</div>
            </div>
          </div>
          <div className="credit_records_body">
            {campaignsLoaded ? (
              <div className="credit_record_box">
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">👑</div>
                    <div className="credit_record_heading_text">
                      Owner Name:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.owner_name}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">🪙</div>
                    <div className="credit_record_heading_text">
                      Owner Credits Left:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.total_credits_of_owner}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">👤</div>
                    <div className="credit_record_heading_text">
                      Created By:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.created_by}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">👥</div>
                    <div className="credit_record_heading_text">
                      In Charge:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.in_charge_of}
                  </div>
                </div>
              </div>
            ) : (
              <div className="credit_record_box">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="credit_record_field">
                    <Skeleton variant="circular" width={24} height={24} />
                    <Skeleton variant="text" width="60%" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
  
        {/* Credit Usage Section */}
        <div className="credit_records_container_body">
          
          <div className="credit_records_header">
            <div className="credit_records_header_item">
              <div className="credit_records_header_item_text">
                {campaignsLoaded ? (
                  `Your Credit Usage Details`
                ) : (
                  <Skeleton variant="text" width="60%" />
                )}
              </div>
              <div className="credit_records_header_item_icon">💰</div>
            </div>
          </div>
          <div className="credit_records_body">
            {campaignsLoaded ? (
              <div className="credit_record_box">
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">📅</div>
                    <div className="credit_record_heading_text">
                      Credits Used Today:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.used_credit_points_today}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">⏳</div>
                    <div className="credit_record_heading_text">
                      Credits Used This Week:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.used_credit_points_this_week}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">📆</div>
                    <div className="credit_record_heading_text">
                      Credits Used This Month:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.used_credit_points_this_month}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">⚖️</div>
                    <div className="credit_record_heading_text">
                      Threshold Value:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {selectedCampaign.threshold_value}
                  </div>
                </div>
              </div>
            ) : (
              <div className="credit_record_box">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="credit_record_field">
                    <Skeleton variant="circular" width={24} height={24} />
                    <Skeleton variant="text" width="60%" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  

  const renderwelcomeCard = () => {
    return (
      <div className="welcome_section">
        <div className="welcome_header">Welcome to Linkomatic 🚀</div>
        <div className="welcome_content">
          <div className="welcome_item">
            <div className="welcome_icon">🔍</div>
            <div className="welcome_text">
              <div className="welcome_title">Bulk Scrape Google</div>
              <div className="welcome_description">
                Scrape Google for thousands of keywords at a time.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">🤖</div>
            <div className="welcome_text">
              <div className="welcome_title">Find Relevant Sites with AI</div>
              <div className="welcome_description">
                We use AI to rank your prospecting list from most to least
                relevant.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">📈</div>
            <div className="welcome_text">
              <div className="welcome_title">Blow Past Link Insert Targets</div>
              <div className="welcome_description">
                Analyze thousands of sites to find link insert suggestions.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">👥</div>
            <div className="welcome_text">
              <div className="welcome_title">Empower Your Team</div>
              <div className="welcome_description">
                Invite your team, manage their credits, and watch them work
                faster.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">🔗</div>
            <div className="welcome_text">
              <div className="welcome_title">By Link Builders</div>
              <div className="welcome_description">
                For link builders, by link builders. Built to serve the needs of
                agencies and link builders.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCampaignLinks = () => {
    // List of campaign pages to display as links
    const campaignPages = [
      { title: "Add Target URLs", path: "client_urls", icon: "🔗" },
      {
        title: "Scrape Prospect URLs From Google",
        path: "check_active_bloggers",
        icon: "👥",
      },
      {
        title: "Black List",
        path: "black_list",
        icon: "🚫",
      },
      {
        title: "Prepare Prospects for Analysis",
        path: "blog_urls",
        icon: "📝",
      },
     
      {
        title: "Match Prospects with Target URLs",
        path: "bulk_process",
        icon: "🔄",
      },
      {
        title: "Classify Prospect Page Types",
        path: "classify_result_page",
        icon: "📊",
      },
      { title: "Page Quality Check", path: "page_quality_check", icon: "🌟" },
      {
        title: "Inspect Matches Individually",
        path: "identify_paragraphs",
        icon: "🔍",
      },
      {
        title: "Link Insertion Suggestion",
        path: "link_insertion_suggestion",
        icon: "🔗",
      },
      
    ];

    return (
      <div className="campaign_links_container">
        
        <div className="campaign_links_header">
          <div className="campaign_links_header_item">
           <div className="campaign_links_header_item_text">
           {campaignsLoaded ? (
              `${selectedCampaign.campaign_name} Links`
            ) : (
              <Skeleton variant="text" width="80%" />
            )}
           </div>
           <div className="campaign_links_header_item_icon">
            {campaignsLoaded ? (
                `🌟`
              ) : (
                <Skeleton variant="text" width="20%" />
              )}
           </div>
          </div>
        </div>
        <div className="campaign_links_body">
          {campaignsLoaded
            ? campaignPages.map((page, index) => (
                <div key={index} className="campaign_links_item">
                  <a
                    href={`/my_campaign/${
                      selectedCampaign ? selectedCampaign.campaign_id : ""
                    }/${page.path}`}
                    className="campaigns_link"
                  >
                    <div className="campaign_link_text">
                      <div className="campaign_link_text_icon">{page.icon}</div>
                      <div className="campaign_link_texts">{page.title}</div>
                    </div>
                  </a>
                </div>
              ))
            : Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="campaign_links_item">
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </div>
              ))}
        </div>
      </div>
    );
  };

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videoUrls = [bigToolVideo, classifying_video, identify_paragraph];

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex - 1 + videoUrls.length) % videoUrls.length
    );
  };

  const renderVideoPlayCard = () => {
    return (
      <div className="video_play_section">
        <div className="video_play_header">Featured Video 🎥</div>
        <div className="video_play_content">
          <video
            className="video_player"
            controls // Optional: Add controls for user interaction
            autoPlay // Autoplay the video
            loop={false} // No need for loop as we'll control it through carousel
            key={currentVideoIndex} // Ensure key changes to force re-render on source change
          >
            <source src={videoUrls[currentVideoIndex]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="video_controls">
            <Button onClick={handlePrevVideo}>Previous</Button>
            <Button onClick={handleNextVideo}>Next</Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page_Section">
      {loggedIn && (
        <div className="leftSection_withMenu">
          <LeftSectionWithMenu />
        </div>
      )}
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} loggedIn={loggedIn} />
        </div>
        <div className="bodySection">
          {fetchError ? <div className="home_page_primary_container">
            {/* <Alert severity="error">Failed to fetch campaigns due to some technical error</Alert> */}
          </div> :
           <div className="home_page_primary_container">
           <div className="recently_used_campaign_container">
             {renderCampaigns()}
           </div>
           {renderCampaignLinks()}
           {renderCreditRecords()}
         </div>
          }
         
          <div className="home_page_secondary_container">
            {renderwelcomeCard()}
            {renderVideoPlayCard()}
          </div>

          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
