import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/authentication/useAuth";
import "./SettingsPage.css";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Container,
  Typography,
  Button,
  Divider,
  Modal,
  Box,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Tooltip,
  Switch,
  Snackbar,
} from "@mui/material";
import {
  MdOutlinePassword,
  MdOutlineContentCopy,
  MdLockPerson,
  MdOutlineEdit,
} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import {
  GET_PASSCODE,
  GET_THRESHOLD_VALUE,
  GET_USER_INFO_BY_ID,
  UPDATE_PASSCODE_BY_ID,
  UPDATE_PASSWORD_BY_ID,
  UPDATE_THRESHOLD_VALUE,
  getFullApiUrl,
} from "../../utils/apiPath";
import { IoShareSocialSharp } from "react-icons/io5";
import ThemeSwitcher from "../../components/ThemeSwitcher/ThemeSwitcher";

const SettingsPage = ({ toggleTheme }) => {
  const { loggedIn } = useAuth();
  const [showEditPasscodeModal, setShowEditPasscodeModal] = useState(false);
  const [showSharePasscodeModal, setShowSharePasscodeModal] = useState(false);
  const [selectUserPasscode, setSelectUserPasscode] = useState({
    passcode: "",
  });

  const [user_passcode, set_user_passcode] = useState();
  const [threshold_value, set_threshold_value] = useState();

  const handleEditPasscode = async () => {
    if (localStorage.getItem("passcode")) {
      setNotificationMessage("You don't have access ");
      setOpenSnackbar(true);
      return;
    }
    await fetchPasscode();
    setSelectUserPasscode({
      passcode: "",
    });
    await setShowEditPasscodeModal(true);
  };

  const handleEditPasscodeClose = () => {
    setShowEditPasscodeModal(false);
  };

  const handleSharePasscode = () => {
    if (localStorage.getItem("passcode")) {
      setNotificationMessage("You don't have access ");
      setOpenSnackbar(true);
      return;
    }
    fetchPasscode();
    setShowSharePasscodeModal(true);
  };

  const handleSharePasscodeClose = () => {
    setShowSharePasscodeModal(false);
  };

  const handleInputChangePasscode = (e) => {
    const { name, value } = e.target;
    setSelectUserPasscode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGeneratePasscode = () => {
    const newPasscode = generatePasscode();
    setSelectUserPasscode((prevState) => ({
      ...prevState,
      passcode: newPasscode,
    }));
  };

  const generatePasscode = () => {
    const alphanumericChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const passcodeLength = 6;
    let passcode = "";

    for (let i = 0; i < passcodeLength; i++) {
      const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
      passcode += alphanumericChars.charAt(randomIndex);
    }

    return passcode;
  };

  const handlePasscodeChange = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(UPDATE_PASSCODE_BY_ID)}?user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            passcode: selectUserPasscode.passcode,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update passcode");
      }

      console.log("Passcode updated successfully");
      fetchPasscode();
      setShowEditPasscodeModal(false);
    } catch (error) {
      console.error("Error updating passcode:", error.message);
    }
  };

  const fetchPasscode = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_PASSCODE)}?user_id=${localStorage.getItem(
          "user_id"
        )}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      set_user_passcode(data.passcode);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // handle new_password change
  const [errorMessage, setErrorMessage] = useState("");

  const [select_user_password, set_select_user_password] = useState({
    user_id: "",
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [showEditPasswordModal, setShowEditPasswordModal] =
    React.useState(false);
  const handleEditPasswordOpen = () => setShowEditPasswordModal(true);
  const handleEditPasswordClose = () => {
    setShowEditPasswordModal(false);
    setErrorMessage("");
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChangePassword = (e) => {
    const { name, value } = e.target;
    set_select_user_password({ ...select_user_password, [name]: value });
    setErrorMessage("");
  };

  const handleEditPassword = (user_id) => {
    if (localStorage.getItem("passcode")) {
      setNotificationMessage("You don't have access to Edit Password");
      setOpenSnackbar(true);
      return;
    }
    set_select_user_password({
      user_id: user_id,
      old_password: "",
      new_password: "",
      confirm_new_password: "", // Reset to empty string
    });

    setShowEditPasswordModal(true);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = async () => {
    if (
      select_user_password.new_password !==
      select_user_password.confirm_new_password
    ) {
      console.error("Password and Confirm Password do not match");
      setErrorMessage("New Password and Confirm Password do not match");
      return;
    }

    // Add new_password strength validation logic here if needed

    try {
      const response = await fetch(getFullApiUrl(UPDATE_PASSWORD_BY_ID), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: select_user_password.user_id,
          old_password: select_user_password.old_password,
          new_password: select_user_password.new_password,
          confirm_new_password: select_user_password.confirm_new_password,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update new password");
      }
      const responseData = await response.json();
      const message = responseData.message;

      console.log("Password updated successfully");

      if (message === "Password updated successfully") {
        // Display success message from the backend
        setShowEditPasswordModal(false);
      } else if (message === "Old password does not match") {
        throw new Error("Old password does not match");
      } else if (message === "New password and confirm password do not match") {
        throw new Error("New password and confirm password do not match");
      } else if (
        message === "New password does not meet strength requirements"
      ) {
        throw new Error("New password does not meet strength requirements");
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error updating new_password:", error.message);
      setErrorMessage(error.message);
    }
  };

  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const handleNotificationToggle = () => {
    setNotificationEnabled(!notificationEnabled);
  };

  // snackbar notification

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const [showEditThresholdModal, setShowEditThresholdModal] = useState(false);
  const [thresholdValue, setThresholdValue] = useState("");
  const [userThresholdValue, setUserThresholdValue] = useState("");

  const handleEditThresholdOpen = async () => {
    const data = await fetchThresholdValueOfUser();
    await setThresholdValue(data)
    await setUserThresholdValue(data); // Set initial value to current threshold
    await setShowEditThresholdModal(true);
  };

  const handleEditThresholdClose = () => {
    setShowEditThresholdModal(false);
  };

  const handleInputChangeThresholdValue = (e) => {
    setUserThresholdValue(e.target.value);
  };

  const handleThresholdValueChange = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(UPDATE_THRESHOLD_VALUE)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          threshold_value: userThresholdValue,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update threshold value");
      }

      console.log("Threshold value updated successfully");
      fetchThresholdValueOfUser();
      setShowEditThresholdModal(false);
    } catch (error) {
      console.error("Error updating threshold value:", error.message);
    }
  };

  const fetchThresholdValueOfUser = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_THRESHOLD_VALUE)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
        }),
      });
      const data = await response.json();
      setThresholdValue(data);
      return data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // useEffect =(() =>{
  //   fetchPasscode()
  //   fetchThresholdValueOfUser()
  // })

  return (
    <div className="page_Section">
      {loggedIn && (
        <div className="leftSection_withMenu">
          <LeftSectionWithMenu />
        </div>
      )}
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} loggedIn={loggedIn} />
        </div>
        <div className="bodySection">
          <div>
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>

            <section className="settings_section">
              <div className="settings_section_title">Security</div>
              <div className="settings_section_content_body">
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Share Passcode
                    </div>
                    <div className="settings_section_content_sub_title">
                      Share your passcode with trusted individuals to grant them
                      access to your account
                    </div>
                  </div>
                  <Tooltip title="Delete">
                    <IconButton onClick={handleSharePasscode}>
                      <IoShareSocialSharp className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Generate Passcode
                    </div>
                    <div className="settings_section_content_sub_title">
                      Change your passcode to enhance the security of your
                      account
                    </div>
                  </div>
                  <Tooltip title="Delete">
                    <IconButton onClick={handleEditPasscode}>
                      <MdOutlinePassword className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Update Password
                    </div>
                    <div className="settings_section_content_sub_title">
                      Change your account new_password to strengthen security .
                    </div>
                  </div>
                  <Tooltip title="Change Password">
                    <IconButton
                      onClick={() =>
                        handleEditPassword(localStorage.getItem("user_id"))
                      }
                    >
                      <MdLockPerson className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Set Threshold Value
                    </div>
                    <div className="settings_section_content_sub_title">
                      Set a threshold value for your credits to manage and
                      monitor your account limits effectively.
                    </div>
                  </div>
                  <Tooltip title="Edit">
                    <IconButton onClick={handleEditThresholdOpen}>
                      <MdOutlineEdit className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="settings_section_title">System </div>
              <div className="settings_section_content_body">
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Change theme
                    </div>
                    <div className="settings_section_content_sub_title">
                      Switch between light and dark themes with a click of a
                      button for a personalized browsing experience.
                    </div>
                  </div>
                  <Tooltip title="Delete">
                    <IconButton>
                      <ThemeSwitcher onToggleTheme={toggleTheme} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Notifications
                    </div>
                    <div className="settings_section_content_sub_title">
                      Enable or disable notifications to stay updated with
                      important information and updates.
                    </div>
                  </div>
                  <Tooltip title="Delete">
                    <IconButton>
                      <Switch
                        checked={notificationEnabled}
                        onChange={handleNotificationToggle}
                        // color="primary"
                        inputProps={{ "aria-label": "notification toggle" }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              {/* <div className="settings_section_title">Account</div>
              <div className="settings_section_content_body">
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Update Name
                    </div>
                    <div className="settings_section_content_sub_title">
                      Share your passcode with trusted individuals to grant them
                      access to your account
                    </div>
                  </div>
                  <Tooltip title="Delete">
                    <IconButton onClick={handleSharePasscode}>
                      <IoShareSocialSharp className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Update Username
                    </div>
                    <div className="settings_section_content_sub_title">
                      Change your passcode to enhance the security of your
                      account
                    </div>
                  </div>
                  <Tooltip title="Delete">
                    <IconButton onClick={handleEditPasscode}>
                      <MdOutlinePassword className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="settings_section_content">
                  <div className="settings_section_content_heading">
                    <div className="settings_section_content_title">
                      Delete Account
                    </div>
                    <div className="settings_section_content_sub_title">
                      Change your account new_password to strengthen security .
                    </div>
                  </div>
                  <Tooltip title="Change Password">
                    <IconButton
                      onClick={() =>
                        handleEditPassword(localStorage.getItem("user_id"))
                      }
                    >
                      <MdLockPerson className="settings_action_icon" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div> */}
            </section>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={notificationMessage}
          />
          <Modal
            open={showSharePasscodeModal}
            onClose={handleSharePasscodeClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <Typography id="settings_modal_heading">
                Share Passcode
              </Typography>
              <div className="modal_description">
                <div className="modal_description_section">
                  <strong>Instructions:</strong>
                  <div>
                    Share your passcode with trusted individuals to grant them
                    access to your account.
                  </div>
                </div>
                <div className="modal_description_section">
                  <strong>Warning:</strong>
                  <div>
                    Exercise caution when sharing your passcode. Only share it
                    with individuals you trust.
                  </div>
                </div>
              </div>
              <TextField
                id="setting_input_textfield"
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  textAlign: "center",
                }}
                type="text"
                label="Passcode"
                value={user_passcode}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(user_passcode);
                          handleSharePasscodeClose(); // Close Modal after copying
                        }}
                      >
                        <MdOutlineContentCopy />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Modal>
          <Modal
            open={showEditPasscodeModal}
            onClose={handleEditPasscodeClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <Typography id="settings_modal_heading">
                Update Passcode
              </Typography>
              <div className="modal_description">
                <div className="modal_description_section">
                  <strong>Instructions:</strong>
                  <div>
                    Enter a new passcode to enhance the security of your
                    account.
                  </div>
                </div>
                <div className="modal_description_section">
                  <strong>Benefits of Sharing Passcode:</strong>
                  <div>
                    Sharing your passcode allows trusted individuals to access
                    your account and perform necessary actions on your behalf.
                  </div>
                </div>
                <div className="modal_description_section">
                  <strong>Warning:</strong>
                  <div>
                    Sharing your passcode compromises the security of your
                    account. If shared, others may gain unauthorized access to
                    your account or impersonate you.
                  </div>
                </div>
                <div className="modal_description_section">
                  <strong>Changing Passcode:</strong>
                  <div>
                    Changing your passcode will immediately log out any users
                    currently using your account via the old passcode.
                  </div>
                </div>
              </div>
              <TextField
                style={{ width: "100%", margin: "8px 0" }}
                type="text"
                label="New Passcode"
                name="passcode"
                className="modal_form_input"
                value={selectUserPasscode.passcode}
                onChange={handleInputChangePasscode}
                variant="standard"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleGeneratePasscode}>
                        <MdOutlinePassword />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <button
                variant="contained"
                color="primary"
                className="add-user-btn"
                onClick={handlePasscodeChange}
              >
                Update Passcode
              </button>
            </Box>
          </Modal>
          <Modal
            open={showEditPasswordModal}
            onClose={handleEditPasswordClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header">Update Password</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="old_password"
                    label="Current Password"
                    name="old_password"
                    value={select_user_password.old_password}
                    onChange={handleInputChangePassword}
                    className="modal_form_input"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  component="form"
                  id="modal_box_form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="new_password"
                    label="New Password"
                    name="new_password"
                    value={select_user_password.new_password}
                    onChange={handleInputChangePassword}
                    className="modal_form_input"
                    variant="standard"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle new_password visibility"
                            onClick={handleTogglePasswordVisibility}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="confirm_new_password"
                    label="Confirm Password"
                    name="confirm_new_password"
                    value={select_user_password.confirm_new_password}
                    onChange={handleInputChangePassword}
                    className="modal_form_input"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle new_password visibility"
                            onClick={handleTogglePasswordVisibility}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {errorMessage && (
                  <div
                    style={{
                      color: "red",
                      paddingLeft: "58px",
                      textAlign: "left",
                    }}
                  >
                    {errorMessage}
                  </div>
                )}
                <button onClick={handlePasswordChange} className="add-user-btn">
                  Update Password
                </button>
              </div>
            </Box>
          </Modal>
          <Modal
            open={showEditThresholdModal}
            onClose={handleEditThresholdClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              id="model_box"
              // sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}
            >
              <Typography id="settings_modal_heading" variant="h6">
                Update Threshold Value
              </Typography>
              <div className="modal_description " style={{
                padding:"0 24px"
              }} >
                <div className="modal_description_section">
                  <strong>Instructions:</strong>
                  <div>
                    Enter a new threshold value to update the limit for the
                    user's account.
                  </div>
                </div>
                <div className="modal_description_section">
                  <strong>What is a Threshold Value?</strong>
                  <div>
                    The threshold value is related to your credits. If the
                    credit deduction crosses the threshold limit, a warning
                    message will be displayed before executing the function.
                    This helps in managing and monitoring account limits
                    effectively.
                  </div>
                </div>
                <div className="modal_description_section">
                  <strong>Warning:</strong>
                  <div>
                    Changing the threshold value will immediately affect the
                    account limits and may impact the user's ability to perform
                    certain actions if the value is set too low. Ensure that the
                    new threshold value is appropriate for the account's needs
                    and usage patterns.
                  </div>
                </div>
              </div>
              <div className="modal_description " style={{
                padding:"0 24px"
              }}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    
                    width: "100%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                type="number"
                label="New Threshold Value"
                name="threshold_value"
                className="modal_form_input"
                value={userThresholdValue}
                onChange={handleInputChangeThresholdValue}
                variant="standard"
                required
                
              />
              </Box>
              </div>
             
              
              
  
                <button
                  className="add-user-btn"
                  onClick={handleThresholdValueChange}
                >
                  Update Threshold Value
                </button>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
