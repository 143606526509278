// PaymentPage.js
import React from 'react';
import { Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Header from '../common/Header/Header';

const PaymentPage = ({ toggleTheme }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const price = searchParams.get('price');

  return (
    <>
      <Header onToggleTheme={toggleTheme} />
      <Box>
        <Typography variant="h4">Payment Page</Typography>
        <Typography variant="h6">Selected Subscription: {type}</Typography>
        <Typography variant="h6">Price: {price}</Typography>
      </Box>
    </>
  );
};

export default PaymentPage;
