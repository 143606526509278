import React, { useState, useEffect } from "react";

import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";

import {
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  Snackbar,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import "./SerperSearchComponent.css";
import {
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_POINTS_DETAILS,
  CHECK_CREDIT_CONDITION,
  SAVE_BLOG_URLS_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  getFullApiUrl,
} from "../../../utils/apiPath";

const SerperSearchComponent = ({ props }) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [serper_search_domain, set_serper_search_domain] = useState("search");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [keywords, set_keywords] = useState("");
  const [active_bloggers_urls, set_active_bloggers_urls] = useState("");

  const [search_queries, set_search_queries] = useState("");
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [search_results, set_search_results] = useState([]);
  const [blog_urls_list, set_blog_urls_list] = useState([]);

  const [blog_urls_data, set_blog_urls_data] = useState([]);

  const [saving, set_saving] = useState(false);

  const [
    show_should_we_run_search_queries_serper_modal,
    set_show_should_we_run_search_queries_serper_modal,
  ] = useState(false);

  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(
        getFullApiUrl(CHECK_CREDIT_CONDITION),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: localStorage.getItem("user_id"),
            campaign_id: campaignId,
            points_to_be_deducted: points_to_be_deducted
          }),
        }
      );
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction)
      setTotalCreditPoints(data.owner_credits_left_before_deduction)
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(data.credits_limit_left_this_week_after_deduction);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );
      fetchpointsDetails();
      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_run_search_queries_serper_close = () => {
    set_show_should_we_run_search_queries_serper_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const goto = (page) => {
    if (page == "check_active_bloggers") {
      //window.location.href = '/campaigns';
      //navigate(`/check_active_bloggers`);
      navigate(`/campaign/${campaignId}/check_active_bloggers`);
    }
  };

  const create_search_queries = () => {
    const activeBloggerUrls = active_bloggers_urls
      .split("\n")
      .filter((url) => url.trim() !== "");
    const clientKeywords = keywords
      .split("\n")
      .filter((keyword) => keyword.trim() !== "");
    if (activeBloggerUrls.length === 0 || clientKeywords.length === 0) {
      set_search_queries(
        "Please enter active blogger URLs and client keywords."
      );
      return;
    }
    let generatedQueries = "";
    activeBloggerUrls.forEach((url) => {
      clientKeywords.forEach((keyword) => {
        generatedQueries += `site:${url} ${keyword}\n`;
      });
    });
    if (generatedQueries.length > 0) {
      generatedQueries = generatedQueries.slice(0, -1);
    }
    set_search_queries(generatedQueries);
  };

  const [search_running, set_search_running] = useState(false);
  const [progress, set_Progress] = useState(0);
  const [no_of_pages, set_no_of_pages] = useState(1);
  const [no_of_results, set_no_of_results] = useState(10);
  const [no_of_queries_to_search, set_no_of_queries_to_search] = useState();
  const run_search_queries_serper = async () => {
    set_show_should_we_run_search_queries_serper_modal(false);
    set_search_results("");
    set_blog_urls_list([]);
    set_search_running(true);
    set_Progress(0);
    let search_query_list = search_queries.split("\n");
    const batchSize = 90; // Adjust batch size as needed
    // Filter out completely empty lines
    let filtered_search_query_list = search_query_list.filter(
      (query) => query.trim() !== ""
    );

    const totalQueries = filtered_search_query_list.length;

    for (
      let startIndex = 0;
      startIndex < totalQueries;
      startIndex += batchSize
    ) {
      const endIndex = Math.min(startIndex + batchSize, totalQueries);
      const batchQueries = search_query_list.slice(startIndex, endIndex);

      await searchBatch(batchQueries);
      //console.log("endIndex - "+endIndex);
      //console.log("totalQueries - "+totalQueries);
      //console.log("percentage - "+(100*endIndex/totalQueries));
      set_Progress((100 * endIndex) / totalQueries);
    }

    set_search_running(false);
  };

  const searchBatch = async (batchQueries, maxRetries = 3) => {
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        const requestBody = batchQueries.map((query) => ({
          q: query,
          autocorrect: false,
          page: no_of_pages,
          num: no_of_results,
        }));

        var myHeaders = new Headers();
        myHeaders.append(
          "X-API-KEY",
          "31fdf7ff042b58b13eb23a446d3df4be924ce6aa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(requestBody);
        //,  "autocorrect": false
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          "https://google.serper.dev/" + serper_search_domain,
          requestOptions
        );
        //const response = await fetch("http://localhost:8002/get_serper_data", requestOptions);

        const results = await response.json();

        for (const result of results) {
          //var urlArray = []
          if (serper_search_domain == "news") {
            //urlArray = result.news.map(item => item.link);
            var result_key = "news";
          } else {
            //urlArray = result.organic.map(item => item.link);
            var result_key = "organic";
          }
          const urlArray = result[result_key].map((item) => item.link);
          //console.log(urlArray);

          //set_search_results(prevResults => prevResults.concat(urlArray.join('\n') + '\n'));
          //set_search_results(prevResults => [...new Set([...prevResults.split('\n'), ...urlArray])].join('\n'));
          await set_search_results((prevResults) =>
            [...new Set([...prevResults.split("\n"), ...urlArray])]
              .join("\n")
              .replace(/^\n+|\n+$/g, "")
          );

          set_blog_urls_list((prevUrls) => [
            ...new Set(prevUrls.concat(urlArray)),
          ]);
          //set_blog_urls_list(prevUrls => prevUrls.concat(urlArray));
        }

        // If the request is successful, break out of the retry loop
        break;
      } catch (error) {
        console.log("Error:", error);
        retryCount++;
        console.log(`Retrying... (Attempt ${retryCount}/${maxRetries})`);
      }
    }
  };

  const save_blog_urls = () => {
    //const existingUrlsData = rows.map((row) => row);
    const updatedUrls = search_results
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    //updatedUrls = [...new Set(updatedUrls)]
    var row_objects_array = [];
    var row_object = {};
    for (let i = 0; i < updatedUrls.length; i++) {
      row_object = {
        id: i + 1,
        url_id: i + 1,
        url: updatedUrls[i],
        scraped: "not_scraped",
        scrapedData: "",
      };
      row_objects_array.push(row_object);
    }
    console.log(row_objects_array);
    set_blog_urls_data(row_objects_array);
    saveRows(row_objects_array);
    saveProjectData(row_objects_array);
  };

  const saveRows = (data) => {
    set_saving(true);
    fetch(getFullApiUrl(SAVE_BLOG_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, blog_urls_data: data },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  const saveProjectData = (data) => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          check_active_bloggers_using_serper: 1,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  const validate_run_search_queries_serper = async (button) => {
    const deduction_point = await getCreditPointToDeductByButton(button);
    // const deduction_point = await getCreditPointToDeductByButton(button);

    let search_query_list = search_queries.split("\n");

    // Filter out completely empty lines
    let filtered_search_query_list = search_query_list.filter(
      (query) => query.trim() !== ""
    );

    const totalQueries = filtered_search_query_list.length;

    const totalRows = totalQueries;

    // const credits_to_be_deducted = totalRows * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat((totalRows * parseFloat(deduction_point)).toFixed(3));
    // If all conditions passed, open the modal

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    let shouldOpenModal = false;

    if (!data.does_owner_have_enough_credits && !data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    if (shouldOpenModal) {
      set_show_should_we_run_search_queries_serper_modal(true);
    } else if (threshold_value >= credits_to_be_deducted) {
      await run_search_queries_serper();
      await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_run_search_queries_serper_modal(true);
    }
  };


  const run_search_queries_serper_update = async () => {
    await run_search_queries_serper();
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };

  useEffect(() => { }, []);

  return (
    <>
      <div className="active_bloggers_Container">
        <div className="active_bloggers_title_container">
          <div className="active_bloggers_title_text">
            Scrape Prospect URLs From Google
          </div>
          <div className="active_bloggers_subheading_text">
            <div>🔍 Enter your client keywords to generate search queries.</div>
            <div>🌐 Use the 'Create Search Queries' button to prepare queries for scraping.</div>
            <div>🛠️ Adjust the number of pages and results, select the domain type, and click 'Run Search' to begin scraping.</div>
            <div>💾 Save the scraped URLs or review the results directly on this page.</div>
          </div>
        </div>
        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={keywords}
            onChange={(event) => set_keywords(event.target.value)}
            placeholder="Enter your client keywords"
            minRows={5}
            maxRows={8}
            style={{
              width: "100%",
              overflow: "auto",
              borderRadius: "5px",
            }}
            className="text_area_of_link_insertion"
          />
        </div>
        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={active_bloggers_urls}
            onChange={(event) => set_active_bloggers_urls(event.target.value)}
            placeholder="Enter active bloggers urls that we have"
            minRows={5}
            maxRows={10}
            style={{
              width: "100%",
              overflow: "auto",
              borderRadius: "5px",
            }}
            className="text_area_of_link_insertion"
          />
        </div>
        <div>
          <Button
            sx={{
              marginRight: "10px",
            }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              create_search_queries();
            }}
          >
            Create Search Queries
          </Button>
        </div>

        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={search_queries}
            onChange={(event) => set_search_queries(event.target.value)}
            placeholder="Here you would get generated search queries"
            minRows={5}
            maxRows={15}
            style={{
              width: "100%",
              overflow: "auto",
              borderRadius: "5px",
            }}
            className="text_area_of_link_insertion"
          />
        </div>
        <div className="check_active_blogger_result_number_containr">
          <div>
            {search_running ? (
              <div style={{ width: "96%", margin: "0px 2%", position: "relative" }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography
                  style={{
                    position: "absolute",
                    color: "#00f",
                    top: -22,
                    left: Math.floor(progress).toFixed(0) + "%",
                    transform: "translateX(-25%)",
                  }}
                >
                  {`${Math.floor(progress).toFixed(0)}%`}
                </Typography>
              </div>
            ) : (
              <div class="search_option_section">
                No of pages :{" "}
                <TextField
                  id="outlined-number"
                  type="number"
                  variant="standard"
                  value={no_of_pages}
                  onChange={(event) => {
                    var inputValue = event.target.value;
                    console.log(inputValue);
                    if (inputValue >= 1 && inputValue <= 10) {
                      console.log("condition pass");
                      set_no_of_pages(parseInt(inputValue));
                    }
                  }}
                  sx={{
                    width: "35px",
                  }}
                />
                &nbsp; &nbsp; &nbsp; &nbsp; No of results :{" "}
                <TextField
                  id="outlined-number"
                  type="number"
                  variant="standard"
                  value={no_of_results}
                  onChange={(event) => {
                    var inputValue = event.target.value;
                    console.log(inputValue);
                    if (inputValue >= 1 && inputValue <= 100) {
                      console.log("condition pass");
                      set_no_of_results(parseInt(inputValue));
                    }
                  }}
                  sx={{
                    width: "45px",
                  }}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <FormControl
                  className="serper_search_domain"
                  variant="standard"
                  sx={{ m: "-17px 25px 0px 10px", minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Domain
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={serper_search_domain}
                    onChange={(event) =>
                      set_serper_search_domain(event.target.value)
                    }
                    label="Domain"
                  >
                    <MenuItem value="search">web</MenuItem>
                    <MenuItem value="news">news</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    validate_run_search_queries_serper("Run Search with Serper")
                  }
                >
                  Run Search
                </Button>
              </div>
            )}
          </div>

          <div>No of url found : - {blog_urls_list.length}</div>
        </div>
        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={search_results}
            onChange={(event) => set_search_results(event.target.value)}
            placeholder="Here you would get generated search results"
            minRows={5}
            maxRows={15}
            style={{
              width: "100%",
              overflow: "auto",
              borderRadius: "5px",
            }}
            className="text_area_of_link_insertion"
          />
        </div>
        <div>
          {saving == true ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => saveRows()}
              disabled
              endIcon={<CircularProgress size="20" />}
            >
              Saving . . . . . . . . . .
            </Button>
          ) : (
            <Button
              sx={{
                marginRight: "10px",
              }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                save_blog_urls();
              }}
            >
              Save
            </Button>

          )}
        </div>

        <div className="next_step_button_container">
        </div>
      </div>
      <Modal
        open={show_should_we_run_search_queries_serper_modal}
        onClose={handle_should_we_run_search_queries_serper_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handle_should_we_run_search_queries_serper_close}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits</div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
            </div>
          </div>
          {errorMessage !==
            "There are no blog URLs available for scraping at the moment." && (
              <div className="LinkExchange_modal_text_deduct">
                Performing this action will deduct{" "}
                <div className="LinkExchange_modal_deduct_number">
                  {" "}
                  {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                </div>{" "}
                from your balance.
              </div>
            )}
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
              <div
                style={{
                  color: "red",
                  textAlign: "center",

                  fontWeight: "bolder",
                  fontFamily: "monospace",
                  fontSize: "larger",
                }}
              >
                <div>{errorMassageForTotalCredits}</div>
                <div>{errorMassageForCreditWeekLimits}</div>
                <div>{errorMessage}</div>
              </div>
            )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
              <>
                <div className="LinkExchange_modal_header">After Deduction</div>
                <div className="LinkExchange_modal_text">
                  Balance Credits :
                  <div className="LinkExchange_modal_number">
                    {totalCreditAfterDeduction}
                  </div>
                </div>
                <div className="LinkExchange_modal_text">
                  Weekly Credits Limit Left :{" "}
                  <div className="LinkExchange_modal_number">
                    {creditWeekLimitAfterDeduction}
                  </div>
                </div>

                <div className="LinkExchange_form_group">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={run_search_queries_serper_update}
                    color="primary"
                  >
                    Confirm Action
                  </Button>
                </div>
              </>
            )}
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
    </>
  );
};
export default SerperSearchComponent;
