import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import "./AdminPage.css";
import AdminSideBar from "./common/AdminSideBar/AdminSideBar";
import TopButton from "../../components/TopButton/TopButton";
import Header from "../common/Header/Header";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import AdminTable from "./common/AdminTable/AdminTable";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import { GET_ALL_USERS, getFullApiUrl } from "../../utils/apiPath";
import CustomizedTables from "../../components/CustomizedTables/CustomizedTables";

const AdminPage = ({ toggleTheme }) => {
  const theme = useTheme();
  const [user_data, set_user_data] = useState([]);
  const chartData = [
    { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
  ];

  const localizer = momentLocalizer(moment);

  const salesData = [
    { month: "Jan", sales: 2200 },
    { month: "Feb", sales: 3000 },
    { month: "Mar", sales: 2800 },
    { month: "Apr", sales: 3500 },
    { month: "May", sales: 4000 },
    { month: "Jun", sales: 3800 },
    { month: "Jul", sales: 4100 },
  ];
  const tasks = [
    {
      id: 1,
      title: "Complete project proposal",
      description: "Finish the proposal document for the new project.",
      completed: false,
    },
    {
      id: 2,
      title: "Review marketing strategy",
      description:
        "Evaluate the current marketing strategy and suggest improvements.",
      completed: false,
    },
    {
      id: 3,
      title: "Send report to stakeholders",
      description: "Prepare and send the quarterly report to stakeholders.",
      completed: false,
    },
  ];
  const recentActivities = [
    { description: "User John Doe added a new post", time: "2 hours ago" },
    { description: "User Alice Smith updated profile", time: "3 hours ago" },
    { description: "User Jane Doe deleted a comment", time: "4 hours ago" },
    // Add more recent activities as needed
  ];
  const fetchData = async () => {
    await fetchUserList();
  };

  useEffect(() => {
    fetchData();
  }, [1000]);
  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const usersListData = await response.json();

      set_user_data(usersListData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const payingUsers = 200;
  const nonPayingUsers = 100;

  const paymentData = [
    { channel: "Channel A", amount: 5000 },
    { channel: "Channel B", amount: 8000 },
    { channel: "Channel C", amount: 6000 },
    { channel: "Channel D", amount: 3000 },
    { channel: "Channel E", amount: 7000 },
  ];

  const fieldsToShow = ["user_name", "role_name", "credit_points"];

  const returningCustomerRateData = [
    { month: "Jan", rate: 20 },
    { month: "Feb", rate: 25 },
    { month: "Mar", rate: 30 },
    { month: "Apr", rate: 28 },
    { month: "May", rate: 32 },
    { month: "Jun", rate: 29 },
    { month: "Jul", rate: 31 },
  ];

  const projectionActualData = [
    { month: "Jan", projection: 1000, actual: 800 },
    { month: "Feb", projection: 1200, actual: 1000 },
    { month: "Mar", projection: 1400, actual: 1100 },
    { month: "Apr", projection: 300, actual: 400 },
    { month: "May", projection: 1500, actual: 1300 },
    { month: "Jun", projection: 1600, actual: 1400 },
    { month: "Jul", projection: 200, actual: 1500 },
  ];
  const latestReviews = [
    {
      reviewer: "Michael Johnson",
      date: "April 3, 2024",
      content: "Impressed with the fast delivery and excellent packaging!",
    },
    {
      reviewer: "Emily Brown",
      date: "April 2, 2024",
      content: "The product exceeded my expectations. Highly recommended.",
    },
    {
      reviewer: "David Wilson",
      date: "April 1, 2024",
      content:
        "Had an issue with the product, but customer support resolved it quickly.",
    },
    {
      reviewer: "Sarah Martinez",
      date: "March 31, 2024",
      content: "Very happy with my purchase. Will buy again in the future.",
    },
    // {
    //   reviewer: "Christopher Taylor",
    //   date: "March 30, 2024",
    //   content: "Product arrived damaged. Disappointed with the quality.",
    // },
    // {
    //   reviewer: "Jessica Garcia",
    //   date: "March 29, 2024",
    //   content:
    //     "Easy ordering process and timely delivery. Will shop here again.",
    // },
  ];

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="admin_page_heading_">Admin Dashboard</div>

          <div className="dashboard">
            <div className="separator">
              <div className="analytics-section section">
                <h2>Analytics</h2>
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="user-management-section section">
                <h2>User Management</h2>
                <CustomizedTables
                  data={user_data}
                  fieldsToShow={fieldsToShow}
                />
              </div>
              <div className="calendar-section section">
                {/* <h2>Calendar</h2>   */}
                <Calendar
                  localizer={localizer}
                  events={[
                    {
                      title: "Meeting",
                      start: new Date(),
                      end: new Date(),
                    },
                  ]}
                  style={{ height: 500, width: 400 }}
                />
              </div>
            </div>
            <div className="separator">
              <div className="activity-section section">
                <h2 className="section-title">Recent Activity</h2>
                <div className="activity-table">
                  <div className="activity-row header">
                    <div className="activity-column">Activity</div>
                    <div className="activity-column">Time</div>
                  </div>
                  {recentActivities.map((activity, index) => (
                    <div key={index} className="activity-row">
                      <div className="activity-column">
                        {activity.description}
                      </div>
                      <div className="activity-column">{activity.time}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sales-section section">
                <h2>Sales Overview</h2>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={salesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="sales" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="tasks-section section">
                <h2>Tasks</h2>
                <div className="task-cards">
                  {tasks.map((task) => (
                    <div key={task.id} className="task-card">
                      <div className="task-details">
                        <div>
                          <div>{task.title}</div>
                          <div>{task.description}</div>
                        </div>
                        <div
                          className={`status-indicator ${
                            task.completed ? "completed" : "pending"
                          }`}
                        >
                          {task.completed ? "Completed" : "Pending"}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="separator">
              <div className="notifications-section section">
                <h2>Notifications</h2>
                <ul>
                  <li>You have a new message from User X</li>
                  <li>Your account password expires soon</li>
                </ul>
              </div>
              <div className="settings-section section">
                <h2>Settings</h2>
                {/* Add settings content here */}
              </div>
              <div className="feedback-section section">
                <h2>Feedback</h2>
                <p>Provide feedback and suggestions for improvement.</p>
              </div>
            </div>
            <div className="separator">
              <div className="help-section section">
                <h2>Help & Support</h2>
                <p>Get assistance with any issues or questions.</p>
              </div>
              <div className="new-users-section section">
                <h2>New Users</h2>
                {/* Add content for New Users section here */}
              </div>
              <div className="paying-vs-non-paying-section section">
                <h2>Paying vs Non-Paying</h2>
                {/* Add content for Paying vs Non-Paying section here */}
                <ResponsiveContainer width="100%" height={350}>
                  <PieChart>
                    <Pie
                      data={[
                        { name: "Paying", value: payingUsers },
                        { name: "Non-Paying", value: nonPayingUsers },
                      ]}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      label
                    >
                      {[{ fill: "#8884d8" }, { fill: "#82ca9d" }].map(
                        (entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        )
                      )}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="separator">
              <div className="latest-reviews-section section">
                <h2>Latest Reviews</h2>
                <div className="latest-reviews-container">
                  {latestReviews.map((review, index) => (
                    <div key={index} className="review-card">
                      <div className="review-info">
                        <div className="reviewer-name">{review.reviewer}</div>
                        <div className="review-date">{review.date}</div>
                      </div>
                      <div className="review-content">{review.content}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="payment-received-section section">
                <h2>Payment Received Across All Channels</h2>
                {/* Add content for Payment Received section here */}
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart
                    data={paymentData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="channel" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="amount" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="returning-customer-rate-section section">
                <h2>Returning Customer Rate</h2>
                {/* Add content for Returning Customer Rate section here */}
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={returningCustomerRateData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="rate"
                      stroke="#8884d8"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="projection-vs-actual-section section">
              <h2>Projection vs Actual</h2>
              <div className="projection-vs-actual-chart">
                <ResponsiveContainer width="100%" height={450}>
                  <LineChart data={projectionActualData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="projection"
                      stroke="#82ca9d"
                      yAxisId="left"
                      dot={false} // Remove dots for projection line
                    />
                    <Line
                      type="monotone"
                      dataKey="actual"
                      stroke="#8884d8"
                      yAxisId="right"
                      dot={{ stroke: "#8884d8", strokeWidth: 3 }} // Customize dots for actual line
                      animationDuration={2000} // Add animation for actual line
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
