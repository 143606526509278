import React, { useEffect, useState } from "react";
import { Typography, Breadcrumbs, Link, Tab, Box } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import { useParams, Link as RouterLink } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FindContactsFromLinkComponent from "./FindContactsFromLinkComponent/FindContactsFromLinkComponent";
import FindContactsFromExcelComponent from "./FindContactsFromExcelComponent/FindContactsFromExcelComponent";
import {
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  getFullApiUrl,
} from "../../utils/apiPath";

const Find_Contacts = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          {/* <FloatingMenuBar campaignId={campaignId} /> */}
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams/${localStorage.getItem("user_id")}`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/user_campaign"
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
             Find Contacts
            </div>
          </Breadcrumbs>

          {/* <FindContactsComponent /> */}
          <TabContext value={value}>
            <Box
              sx={{ width: "100%", justifyContent: "center", display: "flex" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {/* <Tab label="Previously Classifised data" value="1" /> */}
                <Tab label="Find Contacts From Links" value="1" />
                <Tab label="Find Contacts From Excel" value="2" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <FindContactsFromLinkComponent />
            </TabPanel>

            <TabPanel value="2">
              <FindContactsFromExcelComponent />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default Find_Contacts;
