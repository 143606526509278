// AboutUs.js
import React from 'react';
import './AboutUs.css'; // Import the CSS file
import { useTheme } from '@mui/material';
import { MdEditDocument } from "react-icons/md";
import { MdLanguage } from "react-icons/md";
import { RiSearchFill } from "react-icons/ri";
import { RiTeamFill } from "react-icons/ri";
import { BiSolidDollarCircle } from "react-icons/bi";
import { PiLinkFill } from "react-icons/pi";

const AboutUs = () => {
  const sections = [
    {
      icon:<MdEditDocument  />,
      heading: 'Bulk Scrape Google',
      paragraph:
        "Scrape Google for Thousands of Keywords at a time.",
    },
    {
      icon:<MdLanguage  />,
      heading: 'Find Relevant Sites with Al',
      paragraph:
        'We use Al to rank your prospecting list from most to least relevant.',
    },
    {
      icon:<RiSearchFill />,

      heading: 'Blow Past Link Insert Targets',
      paragraph:
        "Analyze 1000's of sites to find link insert suggestions.",
    },
    {
      icon:<RiTeamFill  />,
      heading: 'Empower Your Team',
      paragraph:
        'Invite your team, manage their credits, watch them work faster.',
    },
    {
      icon:<BiSolidDollarCircle   />,
      heading: 'No Monthly Fees',
      paragraph:
        'Buy credits and spend them on Google scrapes, page crawling, and Al analysis.',
    },
    {
      icon:<PiLinkFill    />,
      heading: 'By Link Builders',
      paragraph:
        'For link builders, by link builders. Built to serve the needs to agencies and link.',
    },
  ];
  const theme = useTheme()
  return (
    <div id='aboutUs' style={{
      background: `${theme.palette.background.default}`,
      color:`${theme.palette.text.primary}`,
    }}>
      <h1 id='aboutheading'></h1>
      <div id='boxContainer'>
        {sections.map((section, index) => (
          <div key={index} className='box'>
            <div className='box-icon'>{section.icon}</div>
            
            <div id='box-heading'>{section.heading}</div>
            <div className='box-paragraph'>{section.paragraph}</div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default AboutUs;
