import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import "./BlackListPage.css";
import { GrSort } from "react-icons/gr";
import TextField from "@mui/material/TextField";
import { BsSortDown } from "react-icons/bs";
import { IoMdCut } from "react-icons/io";
import { FaRegCreditCard } from "react-icons/fa6";
import { GiMedal } from "react-icons/gi";
import { CiCreditCard2 } from "react-icons/ci";
import { TbFilterStar } from "react-icons/tb";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { MdSave } from "react-icons/md";
import {
  Box,
  CssBaseline,
  Button,
  IconButton,
  Modal
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getFullApiUrl, SAVE_BLACK_LIST_DATA_LIST_OF_CAMPAIGN, SAVE_BLACK_LIST_DATA_LIST_OF_WORKSPACE } from "../../utils/apiPath";
import { CgArrowRight } from "react-icons/cg";
import { FaLessThan , FaGreaterThan  } from "react-icons/fa6";


const drawerWidthOpen = 310;
const drawerWidthClosed = 59;
const BlackListComponent = ({ workspace_id, campaign_id }) => {
  const navigate = useNavigate();

  const handleGoToNextPage = () => {
    navigate(`/my_campaign/${campaign_id}/bulk_process`);
  };

  const remove_urls_options = [
    { key: 'removeDuplicates', label: 'Remove Duplicates' },
    { key: 'removeRootBasedDomain', label: 'Remove Root Based Domain' },
    { key: 'removeHostIsIP', label: 'Remove Host is an IP' },
  ];

  const trim_url_options = [
    { key: 'trimToRoot', label: 'Trim To Root' },
    { key: 'trimToLastFolder', label: 'Trim To Last Folder' },
    { key: 'trimToUrlsDomainLevel', label: 'Trim To URLs Domain Level' },
    { key: 'cleanUrlTag', label: 'Clean URL Tag' },
  ];
  const filter_tlds_options = {
    country: ['.in', '.au', '.ca', '.uk', '.us', '.de', '.fr', '.jp', '.cn', '.br'],
    sponsored: ['.gov', '.edu', '.mil', '.int', '.jobs', '.museum', '.coop'],
    common: ['.com', '.net', '.org', '.info', '.biz', '.us', '.eu', '.tv'],
    uncommon: ['.mobi', '.name', '.pro', '.aero', '.tel', '.coop', '.asia'],
    generic: ['.online', '.xyz', '.site', '.store', '.tech', '.fun', '.space', '.life', '.club'],
  };



  // Function to initialize selectedOptions based on remove_urls_options array
  const initializeSelectedOptions = () => {
    return remove_urls_options.reduce((acc, option) => {
      acc[option.key] = false;
      return acc;
    }, {});
  };

  const initializeSelectedTrimOptions = () => {
    return trim_url_options.reduce((acc, option) => {
      acc[option.key] = false;
      return acc;
    }, {});
  };

  const initializeSelectedTLDsOptions = () => {
    const initialSelected = {};
    Object.keys(filter_tlds_options).forEach(category => {
      filter_tlds_options[category].forEach(tld => {
        initialSelected[tld] = false;
      });
    });
    return initialSelected;
  };



  const [selectedOptions, setSelectedOptions] = useState(initializeSelectedOptions());
  const [selectedTrimOptions, setSelectedTrimOptions] = useState(initializeSelectedTrimOptions());
  const [selectedTLDsOptions, setSelectedTLDsOptions] = useState(initializeSelectedTLDsOptions());
  const [activeTab, setActiveTab] = useState('country');
  const [selectAll, setSelectAll] = useState(false);
  const scrollRef = useRef(null);
  const drawerContentRef = useRef(null);



  const initializeAllSelectedOptionsForSave = () => [
    {
      "remove_urls_options": Object.keys(selectedOptions).filter(key => selectedOptions[key]),
    },
    {
      "trim_url": Object.keys(selectedTrimOptions).filter(key => selectedTrimOptions[key]),
    },
    {
      "filter_tlds": Object.keys(selectedTLDsOptions).filter(key => selectedTLDsOptions[key]),
    },
  ];


  const [allSelectedOptionsForSave, setAllSelectedOptionsForSave] = useState(initializeAllSelectedOptionsForSave());

  const handleCheckboxChange = (optionKey) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionKey]: !prevOptions[optionKey],
    }));
  };


  const handleTrimCheckboxChange = (optionKey) => {
    setSelectedTrimOptions((prevOptions) => ({
      ...prevOptions,
      [optionKey]: !prevOptions[optionKey],
    }));
  };


  const handleTLDCheckboxChange = (tld) => {
    setSelectedTLDsOptions((prevOptions) => ({
      ...prevOptions,
      [tld]: !prevOptions[tld],
    }));
  };

  const handleProceed = () => {
    const selectedData = Object.entries(selectedOptions)
      .filter(([key, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    console.log(
      'Proceed button clicked with the following remove_urls_options:',
      selectedData
    );
  };

  const handleTrimProceed = () => {
    const selectedTrimData = Object.entries(selectedTrimOptions)
      .filter(([key, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    console.log(
      'Proceed button clicked with the following trim_url options:',
      selectedTrimData
    );
  };

  const handleFilterTLDsProceed = () => {
    const selectedTLDsData = Object.entries(selectedTLDsOptions)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    console.log(
      'Proceed button clicked with the following filter_tlds options:',
      selectedTLDsData
    );
  };

  const handleResetRemoveOrFilterUrlsData = () => {
    setSelectedOptions(initializeSelectedOptions());
  };

  const handleResetTrimUrlData = () => {
    setSelectedTrimOptions(initializeSelectedTrimOptions());
  };

  const handleResetFilterTLDsData = () => {
    setSelectedTLDsOptions(initializeSelectedTLDsOptions());
    setSelectAll(false);
  };


  const handleRestAllData = () => {
    handleResetRemoveOrFilterUrlsData()
    handleResetTrimUrlData()
    handleResetFilterTLDsData()

  }
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    const updatedOptions = { ...selectedTLDsOptions };

    Object.keys(filter_tlds_options).forEach(category => {
      filter_tlds_options[category].forEach(tld => {
        updatedOptions[tld] = newSelectAll;
      });
    });

    setSelectedTLDsOptions(updatedOptions);
    setSelectAll(newSelectAll);
  };

  const renderRemoveOrFilterUrls = () => {
    return (
      <div className="remove_or_filter_url_container">
        <div className="black_list_header_container">
          <div className="black_list_header_container_text">
            Remove/Filter URLs
          </div>
        </div>
        {remove_urls_options.map(({ key, label }) => (
          <div key={key} className="remove_or_filter_url_content">
            <input
              type="checkbox"
              checked={selectedOptions[key]}
              onChange={() => handleCheckboxChange(key)}
            />
            {label}
          </div>
        ))}
        {/* <div className="remove_or_filter_url_button_container" >
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleProceed}
            id="black_list_button_content"
          >
            Proceed
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleResetRemoveOrFilterUrlsData}
            id="black_list_button_content"
          >
            Clear Filter
          </Button>
        </div> */}
      </div>
    );
  };


  const renderTrimUrl = () => {
    return (
      <div className="remove_or_filter_url_container">
        <div className="black_list_header_container">
          <div className="black_list_header_container_text">
            Trim URLs
          </div>
        </div>
        {trim_url_options.map(({ key, label }) => (
          <div key={key} className="remove_or_filter_url_content">
            <input
              type="checkbox"
              checked={selectedTrimOptions[key]}
              onChange={() => handleTrimCheckboxChange(key)}
            />
            {label}
          </div>
        ))}

        {/* <div className="remove_or_filter_url_button_container">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleTrimProceed}
            id="black_list_button_content"
          >
            Proceed
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleResetTrimUrlData}
            id="black_list_button_content"
          >
            Clear Filters
          </Button>
        </div> */}
      </div>
    );
  };




  const renderFilterTLDs = () => {
    return (
      <div className="filter_tlds_header_sidebar_container">
        <div className="black_list_header_container">
          <div className="black_list_header_container_text">
            Filter TLDs
          </div>
        </div>
        <div className="filter_tlds_container">


          {/* <div className="filter_tlds_sidebar">
          <div className="filter_tlds_tabs_vertical">
            {Object.keys(filter_tlds_options).map((category) => (
              <div
                key={category}
                className={`filter_tlds_tab ${activeTab === category ? 'filter_tlds_tab_active' : ''}`}
                onMouseEnter={() => setActiveTab(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </div>
            ))}
          </div>
        </div> */}
          <div className="filter_tlds_content">
            <div className="select_all_container">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
              Select All
            </div>
            <div className="filter_tlds_tab_container" ref={scrollRef}>
              {Object.keys(filter_tlds_options).map((category) => (
                <div key={category} className="filter_tlds_tab_content_container">
                  <div className="filter_tlds_tab_heading">{category}</div>
                  <div className="filter_tlds_tab_content">
                    {filter_tlds_options[category].map((tld) => (
                      <div key={tld} className="tld_content">
                        <input
                          type="checkbox"
                          checked={selectedTLDsOptions[tld] || false}
                          onChange={() => handleTLDCheckboxChange(tld)}
                        />
                        <div className="tld_content_input_text">{tld}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="filter_tlds_button_container">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleFilterTLDsProceed}
              id="black_list_button_content"
            >
              Proceed
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleResetFilterTLDsData}
              id="black_list_button_content"
            >
              Reset
            </Button>
          </div> */}
          </div>
        </div>
      </div>

    );
  };


  const renderFilterTLDs1 = () => {
    return (
      <div className="filter_tlds_container">
        <div className="filter_tlds_sidebar">
          <div className="filter_tlds_tabs_vertical">
            {Object.keys(filter_tlds_options).map(category => (
              <div
                key={category}
                className={`filter_tlds_tab ${activeTab === category ? 'filter_tlds_tab_active' : ''}`}
                onMouseEnter={() => setActiveTab(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </div>
            ))}
          </div>
        </div>
        <div className="filter_tlds_content">
          <div className="select_all_container">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            Select All
          </div>
          <div className="filter_tlds_tab_heading">
            {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
          </div>
          <div className="filter_tlds_tab_content">

            {filter_tlds_options[activeTab].map(tld => (
              <div key={tld} className="tld_content">
                <input
                  type="checkbox"
                  checked={selectedTLDsOptions[tld]}
                  onChange={() => handleTLDCheckboxChange(tld)}
                />
                <div className="tld_content_input_text">{tld}</div>
              </div>
            ))}
          </div>
          <div className="filter_tlds_button_container">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleFilterTLDsProceed}
              id="black_list_button_content"
            >
              Proceed
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleResetFilterTLDsData}
              id="black_list_button_content"
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
    );
  };



  const renderFilterAhrefsTopMilion = () => (
    <div>Filter Ahrefs Top Milion Content</div>
  );

  const renderFilterByDomain = () =>
    <div className="filter_by_domain_container">
      <div className="black_list_header_container">
        <div className="black_list_header_container_text">
          Filter by Domain
        </div>
      </div>
      <div>Filter by Domain</div>
    </div>

  const renderFilterByDomainString = () => (
    <div className="filter_by_domain_container">
      <div className="black_list_header_container">
        <div className="black_list_header_container_text">
          Filter by Domain String Content
        </div>
      </div>
      <div>Filter by Domain String Content</div>
    </div>
  );

  const renderProritizeURLs = () =>
    <div className="filter_by_domain_container">
      <div className="black_list_header_container">
        <div className="black_list_header_container_text">
          Prioritize URLs Content
        </div>
      </div>
      <div>Prioritize URLs Content</div>
    </div>

  const renderURLsDomainTo = () =>
    <div className="filter_by_domain_container">
      <div className="black_list_header_container">
        <div className="black_list_header_container_text">
          URLs Domain To Content
        </div>
      </div>
      <div>URLs Domain To Content</div>
    </div>


  const percentages = {
    1: 0,
    2: 10,
    3: 30,
    5: 50,
    6: 60,
    7: 80,
    8: 100,
  };

  const menuItems = [
    {
      id: 1,
      title: "Remove/Filter URLs",
      content: renderRemoveOrFilterUrls(),
      icon: <BsSortDown />,
      description: "Remove or filter specific URLs based on criteria.",
    },
    {
      id: 2,
      title: "Trim URLs",
      content: renderTrimUrl(),
      icon: <IoMdCut />,
      description: "Trim URLs to remove unnecessary parts.",
    },
    {
      id: 3,
      title: "Filter TLDs",
      content: renderFilterTLDs(),
      icon: <FaRegCreditCard />,
      description: "Filter URLs based on their top-level domain (TLD).",
    },
    {
      id: 5,
      title: "Filter by Domain",
      content: renderFilterByDomain(),
      icon: <CreditScoreIcon />,
      description: "Filter URLs based on specific domain names.",
    },
    {
      id: 6,
      title: "Filter by Domain String",
      content: renderFilterByDomainString(),
      icon: <TbFilterStar  />,
      description: "Filter URLs by specific strings within domain names.",
    },
    {
      id: 7,
      title: "Prioritize URLs",
      content: renderProritizeURLs(),
      icon: <FaSortAmountUpAlt />,
      description: "Prioritize URLs based on their importance or relevance.",
    },
    {
      id: 8,
      title: "URLs Domain To",
      content: renderURLsDomainTo(),
      icon: <GrNotes />,
      description: "Convert URLs to specific domain formats or structures.",
    },
  ];


  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].id);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleSortClick = () => {
    setMenuVisible(!menuVisible);
  };
  const renderMenuItem = (item) => (
    <ListItem key={item.id} disablePadding id="black_list_drawer_menuitem">
      <ListItemButton
        onMouseEnter={() => handleMouseEnter(item.id)}
        onClick={() => setSelectedMenuItem(item.id)}
        id={selectedMenuItem === item.id ? "selected" : ""}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        {menuVisible && (
          <ListItemText
            primary={
              <div className="black_list_drawer_text">
                <div>{item.title}</div>
                <div>{selectedMenuItem === item.id && <CgArrowRight />}</div>
              </div>
            }
          />
        )}
      </ListItemButton>
    </ListItem>
  );

  const renderSelectedContent1 = () => {
    const selectedItem = menuItems.find((item) => item.id === selectedMenuItem);
    return selectedItem ? selectedItem.content : <></>;
  };
  const handleMouseEnter = (menuItemId) => {
    setSelectedMenuItem(menuItemId);
    scrollToMenuItem(menuItemId);
  };

  const renderSelectedContent = () => {
    return (
      <div ref={drawerContentRef} className="drawer_content_inner">
        {menuItems.map((item) => (
          <div
            key={item.id}
            className={`drawer_menuitem_container ${selectedMenuItem === item.id ? 'selected' : ''}`}
            id={`menu-item-${item.id}`}
            tabIndex={-1} // Make the element focusable
          >
            {item.content}
          </div>
        ))}
      </div>
    );
  };


  const renderDrawerHeader = () => (
    <div className="black_list_drawer_header_container">
      <div className="black_list_drawer_header">
        {menuVisible ? "Actions" : ""}
      </div>
      <div onClick={handleSortClick}>
        {menuVisible ? <div className="black_list_drawer_menu_open_icon"><FaLessThan /></div> :<div className="black_list_drawer_menu_close_icon"> <FaGreaterThan /></div>}
      </div>
    </div>
  );


  const renderHeader = () => {
    const selectedItem = menuItems.find((item) => item.id === selectedMenuItem);
    return (
      <div className="blacklit_header_with_button_container">
        <div className="black_list_header_container">
          <div className="black_list_header_container_text">
            {selectedItem ? selectedItem.title : ""}
          </div>
          <div className="black_list_header_container_sub_text">
            {selectedItem ? selectedItem.description : ""}
          </div>
        </div>
        <div className="black_list_header_button_container">
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleRestAllData}
            id="black_list_header_button_content"
          >
            Clear All Filters
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleOpenSaveForBlackListModal}
            id="black_list_header_button_content"
          >
            {/* <MdSave /> */}
            Save
          </Button>
        </div>
      </div>

    );
  };


  const [openSaveForBlackListModal, setOpenSaveForBlackListModal] = useState(false);

  const handleOpenSaveForBlackListModal = () => setOpenSaveForBlackListModal(true);
  const handleCloseSaveForBlackListModal = () => setOpenSaveForBlackListModal(false);

  const save_black_list_urls_in_campaign = async () => {


    try {
      const response = await fetch(getFullApiUrl(SAVE_BLACK_LIST_DATA_LIST_OF_CAMPAIGN), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          request_data: { campaign_id: campaign_id, black_list_data: allSelectedOptionsForSave },
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Save successful:", result);
      } else {
        console.error("Save failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };
  const save_black_list_urls_in_workspace = async () => {


    try {
      const response = await fetch(getFullApiUrl(SAVE_BLACK_LIST_DATA_LIST_OF_WORKSPACE), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          request_data: { workspace_id: workspace_id, black_list_data: allSelectedOptionsForSave },
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Save successful:", result);
      } else {
        console.error("Save failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  useEffect(() => {
    setAllSelectedOptionsForSave([
      {
        "remove_urls_options": Object.keys(selectedOptions).filter(key => selectedOptions[key]),
      },
      {
        "trim_url": Object.keys(selectedTrimOptions).filter(key => selectedTrimOptions[key]),
      },
      {
        "filter_tlds": Object.keys(selectedTLDsOptions).filter(key => selectedTLDsOptions[key]),
      },
    ]);
  }, [selectedOptions, selectedTrimOptions, selectedTLDsOptions]);

  const scrollToMenuItem = (menuItemId) => {
    const selectedItemElement = document.getElementById(`menu-item-${menuItemId}`);
    if (selectedItemElement) {
      selectedItemElement.focus({ preventScroll: true });
      selectedItemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    scrollToMenuItem(selectedMenuItem);
  }, [selectedMenuItem]);

  return (
    <div className="black_list_drawer_header_with_container">
      {renderHeader()}
      <div className="drawer_with_content_container">
        <CssBaseline />
        <Drawer
          sx={{
            width: menuVisible ? drawerWidthOpen : drawerWidthClosed,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: menuVisible ? drawerWidthOpen : drawerWidthClosed,
              boxSizing: "border-box",
              height: "425px",
              position: "static",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              overflow:"hidden"
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {renderDrawerHeader()}
          {/* <Divider /> */}
          <List id="black_list_drawer_menu">{menuItems.map(renderMenuItem)}</List>
        </Drawer>
        <div className="drawer_content_container">
          {renderSelectedContent()}
        </div>
      </div>
      {/* <div className="next_step_button_container">
            <Button
              variant="contained"
              size="small"
              onClick={handleGoToNextPage}
              color="primary"
            >
              Next Step 👉
            </Button>
      </div> */}

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={openSaveForBlackListModal}
        onClose={handleCloseSaveForBlackListModal}
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: "15px",
              top: "13px",
            }}
          >
            <IconButton onClick={handleCloseSaveForBlackListModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_text_heading">
            Save Blacklist URLs
          </div>
          <div className="black_list_save_modal_button_container">
            <Button
              className="black_list_save_modal_button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                save_black_list_urls_in_workspace();
                handleCloseSaveForBlackListModal();
              }}
            >
              Save in Workspace Level
            </Button>
            <Button
              className="black_list_save_modal_button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                save_black_list_urls_in_campaign();
                handleCloseSaveForBlackListModal();
              }}
            >
              Save in Campaign Level
            </Button>
            <Button
              className="black_list_save_modal_button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                save_black_list_urls_in_campaign();
                save_black_list_urls_in_workspace();
                handleCloseSaveForBlackListModal();
              }}
            >
              Save in Both
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BlackListComponent;
