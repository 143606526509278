import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Breadcrumbs,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  getFullApiUrl,
  ADD_NEW_TICKET,
  GET_ALL_TICKETS_OF_USER,
  GET_TICKET_BY_ID,
} from "../../utils/apiPath";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import "./TicketPage.css";
import { CiImageOn } from "react-icons/ci";
import { IoMdCloseCircle } from "react-icons/io";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableWithPagination from "../../utils/TableWithPagination";

const TicketPage = ({ toggleTheme }) => {
  const ticketTypes = [
    "Technical",
    "Credit Points Related",
    "Campaign Related",
    "Other",
  ]; // List of ticket types
  const [newTicket, setNewTicket] = useState({
    user_id: localStorage.getItem("user_id") || "", // Ensure default value
    ticket_type: "",
    ticket_question: "",
    ticket_screenshot: null, // Initialize to null for file
  });
  const [ticketData, setTicketData] = useState([]);
  const [ticket_details, set_ticket_details] = useState();
  const [show_ticket_screenshot_modal, set_show_ticket_screenshot_modal] =
    useState(false);

  const handle_ticket_screenshot_modal_close = () => {
    set_show_ticket_screenshot_modal(false);
    set_ticket_details(null);
  };

  const fetchTicketDetailsList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_ALL_TICKETS_OF_USER
        )}?user_id=${localStorage.getItem("user_id")}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setTicketData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTicketDetails = async (ticket_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_TICKET_BY_ID)}?ticket_id=${ticket_id}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      set_ticket_details(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    await fetchTicketDetailsList();
  };
  useEffect(() => {
    fetchData();
  }, [1000]);

  const handle_show_screenshot = (params) => {
    fetchTicketDetails(params.ticket_id);

    set_show_ticket_screenshot_modal(true);
  };
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const [anchorEl, setAnchorEl] = useState(null); // State for Menu anchor element
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setNewTicket({ ...newTicket, ticket_screenshot: reader.result });
      setImagePreview(reader.result); // Set image preview
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddTicket = async () => {
    try {
      setLoading(true);

      const requestData = {
        user_id: newTicket.user_id,
        ticket_type: newTicket.ticket_type,
        ticket_question: newTicket.ticket_question,
        ticket_screenshot: newTicket.ticket_screenshot,
      };

      const response = await fetch(getFullApiUrl(ADD_NEW_TICKET), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new ticket");
      }

      setNewTicket({
        user_id: localStorage.getItem("user_id") || "",
        ticket_type: "",
        ticket_question: "",
        ticket_screenshot: null,
      });

      setLoading(false);
      fetchData();
      console.log("New ticket added successfully");

      // Clear image preview after successful upload
      setImagePreview(null);
    } catch (error) {
      console.error("Error adding new ticket:", error.message);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTicket((prevTicket) => ({
      ...prevTicket,
      [name]: value,
    }));
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">Ticket</div>
          </Breadcrumbs>

          <div className="ticket_container_box">
            <div className="ticket_container_title">Submit a Ticket</div>

            <div className="input-container">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    width: "80%",
                    marginLeft: "10%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="ticket_type"
                  select
                  label="Select Question Category"
                  name="ticket_type"
                  helperText="Please select your question type"
                  value={newTicket.ticket_type} // Make sure the value matches the selected role_name
                  onChange={handleInputChange}
                >
                  {ticketTypes.map(
                    (
                      type // Correct the variable name here
                    ) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Box>
            </div>
            <div className="input-container">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    width: "80%",
                    marginLeft: "10%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="ticket_question"
                  label="Question / Suggestions"
                  variant="standard"
                  name="ticket_question"
                  value={newTicket.ticket_question}
                  onChange={handleInputChange}
                  required
                  className="ticket_input_container"
                  multiline
                  rows={4}
                />
              </Box>
            </div>
            <div className="input-container">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    width: "80%",
                    marginLeft: "10%",
                    cursor: "pointer",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <Button
                  component="label"
                  htmlFor="ticket_screenshot"
                  // startIcon={}
                >
                  <ImageSearchIcon />
                  <input
                    type="file"
                    id="ticket_screenshot"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Button>
              </Box>
            </div>

            {/* Display image preview */}
            {imagePreview && (
              <div
                className="image-preview"
                style={{
                  border: "1px solid #ccc",
                  padding: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "#fff",
                  }}
                  onClick={() => setImagePreview(null)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}

            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  m: 1,
                  width: "80%",
                  marginLeft: "10%",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTicket}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Submit Ticket"}
              </Button>
            </Box>
          </div>
          <TableWithPagination
             file_name={`ticket_data`}

            data={ticketData}
            fields={[
              {
                type: "id",
                field: "ticket_id",
                headerName: "",
                minWidth: 1,
                width: 1,
                maxWidth: 380,
              },
              {
                type: "field",
                field: "ticket_type",
                headerName: "ticket_type",
                search_type: "text_search",
                minWidth: 150,
                width: 220,
                maxWidth: 240,
              },
              {
                type: "field",
                field: "ticket_question",
                search_type: "text_search",
                headerName: "ticket_question",
                minWidth: 290,
                width: 280,
                maxWidth: 350,
              },

              {
                type: "action",
                field: "actions",
                headerName: "Actions",
                minWidth: 120,
                width: 120,
                maxWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="action_icons_">
                      {/* <Tooltip title="Edit">
                    <IconButton onClick={() => handleEditsubscription(params)}>
                      <LiaEdit className="table_action_icon" />
                    </IconButton>
                  </Tooltip> */}

                      <Tooltip title="Show Screenshot">
                        <IconButton
                          onClick={() => handle_show_screenshot(params)}
                        >
                          <CiImageOn className="table_action_icon" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  );
                },
              },
            ]}
            bulk_actions={[
              {
                action: "add",
                renderCell: (params) => {
                  return (
                    // <Tooltip title="Add New Subscription">
                    //   <div
                    //     onClick={handleAddOpen}
                    //     className="bulk_classify_button download_icons"
                    //   >
                    //     <RiAddLine />
                    //   </div>
                    // </Tooltip>
                    <></>
                  );
                },
              },
              {
                action: "delete_multiple",
                renderCell: (params) => {
                  return (
                    // <Tooltip title="Delete Selected Subscription">
                    //   <div
                    //     onClick={() => handleBulkSubscriptionDeleteClick(params)}
                    //     className="bulk_delete_button download_icons"
                    //   >
                    //     <MdOutlineDeleteSweep fontSize={30}/>
                    //   </div>
                    // </Tooltip>
                    <></>
                  );
                },
              },
            ]}
          />
          <Modal
            open={show_ticket_screenshot_modal}
            onClose={handle_ticket_screenshot_modal_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 2,
                    width: "80%",
                    marginLeft: "10%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                {ticket_details && (
                  <img
                    src={ticket_details}
                    alt="Ticket Screenshot"
                    height={300}
                    width={250}
                  />
                )}
              </Box>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 2,
                    width: "80%",
                    marginLeft: "10%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <Button onClick={handle_ticket_screenshot_modal_close}>
                  <IoMdCloseCircle className="formIcons" />
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TicketPage;
