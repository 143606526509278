import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Papa from "papaparse";
//import {Router, browserHistory} from 'react-router';
import { useNavigate } from "react-router-dom";
import {
  Input,
  Tabs,
  Tab,
  Box,
  Grid,
  Button,
  Snackbar,
  IconButton,
  Modal,
  Typography,
  Tooltip,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Skeleton,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {} from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
//import Tooltip from '@mui/material/Tooltip';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import page_classification_icon from "../../../assets/icons/page_classification_icon2.png";
import save_button_icon from "../../../assets/icons/save_button_icon.png";

import { styled } from "@mui/material/styles";
import TableWithPagination from "./../../../utils/TableWithPagination";
import "./ClassifyResultPagesComponent.css";
import {
  CHECK_CREDIT_CONDITION,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_IDENTIFIED_PARAGRAPHS_DATA_FILTERED,
  GET_POINTS_DETAILS,
  LOAD_PREVIOUSLY_CLASSIFIED_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA,
  getFullApiUrl,
} from "../../../utils/apiPath";
import { FaProjectDiagram, FaSave } from "react-icons/fa";

const ClassifyResultPagesComponent = ({ props }) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [
    show_should_we_classify_urls_modal,
    set_show_should_we_classify_urls_modal,
  ] = useState(false);
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [isClassifying, setIsClassifying] = useState(false);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");
  const [classificationProgress, setClassificationProgress] = useState({
    processed: 0,
    total: 0,
  });
  const [saving, set_saving] = useState(false);
  const [progressMessage, setProgressMessage] = useState("Classifying...");
  const progressPercentage =
    classificationProgress.total === 0
      ? 0
      : (100 * classificationProgress.processed) / classificationProgress.total;

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      console.log("---------------------------------------");
      console.log("points_to_be_deducted");
      console.log(points);
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
      // setNotificationMessage(`${points} Points deducted successfully`);
      // setOpenSnackbar(true);
      fetchpointsDetails();
      // Close the Modal after deduction
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_classify_urls_close = () => {
    set_show_should_we_classify_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setErrorMessage("");
  };

  const [identified_paragraphs_data, set_identified_paragraphs_data] = useState(
    []
  );

  //const [initial_data_loaded_client, set_initial_data_loaded_client] = useState(0);
  //const [initial_data_loaded_blog, set_initial_data_loaded_blog] = useState(0);
  const [
    initial_data_loaded_identified_paragraphs,
    set_initial_data_loaded_identified_paragraphs,
  ] = useState(0);

  //const [filter_count_of_keyword_match, set_filter_count_of_keyword_match] = useState(0);
  const [filter_similarity_score, set_filter_similarity_score] = useState({
    from: 33,
    to: 70,
  });

  //const [open, setOpen] = React.useState(false);

  const [filtered_scored_data, set_filtered_scored_data] = useState([]);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [filtered_blog_urls, set_filtered_blog_urls] = useState([]);
  //const [search_data, set_search_data] = useState([]);

  const [file_upload_field_key, set_file_upload_field_key] = useState(
    Math.random()
  );
  const [upload_file_fields, set_upload_file_fields] = useState([]);

  const get_identified_paragraphs_data_filtered = () => {
    set_initial_data_loaded_identified_paragraphs(1);
    fetch(getFullApiUrl(GET_IDENTIFIED_PARAGRAPHS_DATA_FILTERED), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          from: filter_similarity_score.from,
          to: filter_similarity_score.to,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((inputData) => {
        console.log(inputData.data);
        var transformedData = [];
        var search_result_id = 0;
        inputData.data.forEach((entry) => {
          const clientUrl = entry.client_url;
          const blogUrls = entry.blog_urls;

          blogUrls.forEach((blog) => {
            search_result_id = search_result_id + 1;
            const transformedEntry = {
              search_result_id: search_result_id,
              client_url: clientUrl,
              blog_url: blog.blog_url,
              blog_title: blog.title,
              keywords: blog.keywords,
              //matched_paragraph: blog.matched_paragraph,
              selected: blog.selected,
              similarity_score: blog.similarity_score,
            };
            transformedData.push(transformedEntry);
          });
        });
        console.log(transformedData);
        set_filtered_scored_data(transformedData);
        set_initial_data_loaded_identified_paragraphs(0);
        setErrorFetchMassage(null);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_filtered_scored_data([]);
        set_initial_data_loaded_identified_paragraphs(0);
        setErrorFetchMassage("There is no data to classify");
      });
  };

  const [columns_to_select, set_columns_to_select] = useState([
    { key: "client_url", text: "Client URL", csv_field: "" },
    { key: "blog_url", text: "Blog URL", csv_field: "" },
    { key: "blog_title", text: "Blog Title", csv_field: "" },
    { key: "similarity_score", text: "Similarity Score", csv_field: "" },
    { key: "classification", text: "Page Type", csv_field: "" },
  ]);
  const [uploaded_file_data, set_uploaded_file_data] = useState([]);
  const [isFileSelectVisible, setIsFileSelectVisible] = useState(false);
  const handleColumnSelection = (columnName, uploaded_file_field) => {
    console.log("*************************");
    console.log(columnName);
    console.log(uploaded_file_field);
    console.log(columns_to_select);
    if (columnName == "") {
      set_columns_to_select((prevColumnsToSelect) =>
        prevColumnsToSelect.map((column) =>
          column.csv_field === uploaded_file_field
            ? { ...column, csv_field: "" }
            : column
        )
      );
    } else {
      /*
      set_columns_to_select((prevColumnsToSelect) =>
        prevColumnsToSelect.map((column) =>
          column.key === columnName ? { ...column, csv_field: uploaded_file_field } : column
        )
      );
      */
      set_columns_to_select((prevColumnsToSelect) =>
        prevColumnsToSelect.map((column) => {
          return column.key === columnName
            ? { ...column, csv_field: uploaded_file_field }
            : column.csv_field === uploaded_file_field
            ? { ...column, csv_field: "" }
            : column;
        })
      );
    }
  };

  const upload_user_uploaded_file = (event) => {
    console.log(
      "******************************************************************"
    );
    console.log("upload_user_uploaded_file");
    const user_uploaded_file = event.target.files[0];
    if (user_uploaded_file) {
      Papa.parse(user_uploaded_file, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          const columnNames = Object.keys(results.data[0] || {});
          setIsFileSelectVisible(true);
          console.log("Column Names:", columnNames);
          set_upload_file_fields(columnNames);
          set_uploaded_file_data(results);
        },
      });
    }
    set_file_upload_field_key(Math.random());
  };

  const load_user_uploaded_file_data = (event) => {
    console.log(columns_to_select);
    var search_result_id = 0;
    const updatedData = uploaded_file_data.data.map((obj) => {
      search_result_id = search_result_id + 1;

      const mappedData = {
        search_result_id: search_result_id,
        client_url: columns_to_select
          .filter(
            (column) => column.key === "client_url" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        blog_url: columns_to_select
          .filter(
            (column) => column.key === "blog_url" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        blog_title: columns_to_select
          .filter(
            (column) => column.key === "blog_title" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        similarity_score: columns_to_select
          .filter(
            (column) =>
              column.key === "similarity_score" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        classification: columns_to_select
          .filter(
            (column) =>
              column.key === "classification" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
      };

      return mappedData;
    });

    console.log(updatedData);
    set_filtered_scored_data(updatedData);
  };

  const handleSaveMultiple = async (record_ids) => {
    set_saving(true)
    var filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    console.log(filteredDataWithRecordIds);
    var selectedFields = filteredDataWithRecordIds.map((item) => ({
      client_url: item.client_url,
      blog_url: item.blog_url,
      blog_title: item.blog_title,
      page_type: item.classification,
      similarity_score: item.similarity_score,
    }));
    //console.log(selectedFields);
    fetch(getFullApiUrl(USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, data: selectedFields },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
        saveProjectData();
        set_saving(false)
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        set_saving(false)
      });
  };

  const load_previously_classified_data = async () => {
    fetch(getFullApiUrl(LOAD_PREVIOUSLY_CLASSIFIED_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var search_result_id = 0;
        const updatedData = data.data.map((obj) => {
          search_result_id = search_result_id + 1;
          return {
            ...obj,
            search_result_id: search_result_id,
            classification: obj.page_type,
            /*client_url: obj["Client URL"],
            blog_url: obj["Blog URL"],
            similarity_score: obj["Similarity Score"]*/
          };
        });
        set_filtered_scored_data(updatedData);
        setErrorFetchMassage(null);
        //console.log('File uploaded successfully:', data);
      })
      .catch((error) => {
        setErrorFetchMassage("There is no data to classify");
        console.error("Error Downloading file:", error);
      });
  };

  const get_blog_urls_from_uploaded_data = () => {};

  const get_prompt = (blog_url) => {
    var classification_prompt =
      "\nThe URL of the webpage is " +
      blog_url["blog_url"] +
      "\n" +
      "The webpage title is " +
      blog_url["blog_title"] +
      "\n\n" +
      "Based on the above webpage URL and webpage title, now classify the webpage in one of the following classes:\n" +
      //"Based on the above webpage URL, now classify the webpage in one of the following classes:\n" +
      "- About Us Page\n" +
      "- Blog Page\n" +
      "- Contact Us Page\n" +
      "- FAQ Page\n" +
      "- Home Page\n" +
      "- Landing Page\n" +
      "- News Page\n" +
      "- Privacy Policy Page\n" +
      "- Service Page\n" +
      "- Terms of Service Page\n" +
      "- Testimonials/Reviews Page\n" +
      "- Product Page\n" +
      "- Ecommerce Product Page\n" +
      "- Sales Page\n" +
      "- Forum Page\n" +
      "- Job Listing Page\n" +
      "- Category Page\n" +
      "- Tag Page\n" +
      "- Ecommerce Category Page\n" +
      "- Blog Page of Product Listicles\n" +
      "- Directory Listing Page\n" +
      "- Pricing Page\n" +
      "- Resource Blog Page\n" +
      "- Profile Page\n" +
      "- Product Listicles Blog Page\n" +
      "- Help Desk Pages\n\n" +
      "Remember, I want only one class without any explanation.";
    return classification_prompt;
  };
  const apiEndpoints = [
    "https://m5p5ee4g5l.execute-api.us-east-2.amazonaws.com",
  ];

  const handleClassifyMultiple1 = async (record_ids) => {
    set_show_should_we_classify_urls_modal(false);
    // Filter out already classified URLs
    let unclassifiedData = filtered_scored_data.filter(
      (item) => !item.classification // Assuming "classification" property indicates if URL is already classified
    );

    let filteredDataWithRecordIds = unclassifiedData.filter((item) =>
      record_ids.includes(item.search_result_id)
    );

    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          blog_title: item.blog_title,
        }))
      ),
    ];
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    console.log(uniqueBlogURLs);

    const totalRows = uniqueBlogURLs.length;
    const batchSize = 10; // Number of API calls per batch
    const totalBatches = Math.ceil(totalRows / batchSize);

    let updatedData = [...filtered_scored_data];

    const classifyURL = async (blogURL) => {
      const index = uniqueBlogURLs.indexOf(blogURL);
      const record_id = record_ids[index];
      const classification_prompt = get_prompt(blogURL);
      const apiIndex = index % apiEndpoints.length;

      // If credit points deducted successfully, proceed with URL classification
      const response = await fetch(apiEndpoints[apiIndex], {
        method: "POST",
        body: JSON.stringify({
          request_data: { prompt: classification_prompt },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(
          `Classification result for url ${blogURL["blog_url"]}:`,
          data.response
        );

        // Update data based on blog_url
        updatedData = updatedData.map((item) =>
          item.blog_url === blogURL["blog_url"]
            ? { ...item, classification: data.response }
            : item
        );

        set_filtered_scored_data(updatedData);
        setErrorFetchMassage(null);
      } else {
        setErrorFetchMassage("There is no data to classify");
        console.error(
          `Error classifying url ${blogURL["blog_url"]}:`,
          response.statusText
        );
      }
    };

    // Classify only unclassified URLs
    const classifyBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUniqueBlogURLs = uniqueBlogURLs.slice(startIndex, endIndex);

      const classifyBatchURLs = batchUniqueBlogURLs.map(classifyURL);
      await Promise.all(classifyBatchURLs);

      if (startIndex + batchSize < totalRows) {
        classifyBatch(batchIndex + 1); // Proceed to the next batch
      }
    };

    classifyBatch(0); // Start with the first batch
  };
  const handleClassifyMultiple = async (record_ids) => {
    set_show_should_we_classify_urls_modal(false);
    setIsClassifying(true);
    setProgressMessage("Classifying...");

    let filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );

    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          blog_title: item.blog_title,
        }))
      ),
    ];

    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    const totalRows = uniqueBlogURLs.length;
    const batchSize = 10;
    const totalBatches = Math.ceil(totalRows / batchSize);

    let updatedData = [...filtered_scored_data];

    setClassificationProgress({ processed: 0, total: totalRows });

    const classifyBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUniqueBlogURLs = uniqueBlogURLs.slice(startIndex, endIndex);

      const classifyURL = async (blogURL) => {
        const index = uniqueBlogURLs.indexOf(blogURL);
        const record_id = record_ids[index];
        const classification_prompt = get_prompt(blogURL);
        const apiIndex = index % apiEndpoints.length;

        try {
          const response = await fetch(apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify({
              request_data: { prompt: classification_prompt },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            updatedData = updatedData.map((item) =>
              item.blog_url === blogURL.blog_url
                ? { ...item, classification: data.response }
                : item
            );
            set_filtered_scored_data(updatedData);
            setErrorFetchMassage(null);
          } else {
            setErrorFetchMassage("There is no data to classify");
            console.error(
              `Error classifying url ${blogURL.blog_url}:`,
              response.statusText
            );
          }
        } catch (error) {
          console.error(`Error classifying url ${blogURL.blog_url}:`, error);
        } finally {
          setClassificationProgress((prevProgress) => {
            const newProgress = {
              processed: prevProgress.processed + 1,
              total: prevProgress.total,
            };
            if (newProgress.processed === newProgress.total) {
              setProgressMessage("Classified");
              // setIsClassifying(false);
            }
            return newProgress;
          });
        }
      };

      const classifyBatchURLs = batchUniqueBlogURLs.map(classifyURL);
      await Promise.all(classifyBatchURLs);

      if (startIndex + batchSize < totalRows) {
        classifyBatch(batchIndex + 1);
      }
    };

    classifyBatch(0);
  };

  const [value, setValue] = React.useState("2");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setIsFileSelectVisible(false);
  };
  const [selected_blog_url_for_classify, set_selected_blog_url_for_classify] = useState([]);
  const [isBlogUrlUpdated, setIsBlogUrlUpdated] = useState(false);

  const update_selected_blog_url_for_classify = (params) => {
    try {
      set_selected_blog_url_for_classify(params)
      setIsBlogUrlUpdated(true); // Update the status
    } catch (error) {
      console.error("Error setting selected blog URL for classify:", error);
      setIsBlogUrlUpdated(false); // Update the status to false in case of error
    }
  };
  const validate_classify_urls = async (action, params) => {
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    // Extracting search_result_id list from params
    const selectedIds = params;

    update_selected_blog_url_for_classify(params);

    if (isBlogUrlUpdated) {
      const deduction_point = await getCreditPointToDeductByButton(action);

      const totalRows = selectedIds.length;
      const credits_to_be_deducted = parseFloat(
        (totalRows * parseFloat(deduction_point)).toFixed(3)
      );
      console.log("credits_to_be_deducted");
      console.log(credits_to_be_deducted);

      setDeductionPoints(credits_to_be_deducted);
      console.log("deductionPoints");
      console.log(deductionPoints);
      const data = await fetchpointsDetails(credits_to_be_deducted);
      const threshold_value = data.threshold_value;

      // Check conditions separately
      let shouldOpenModal = false;

      if (
        !data.does_owner_have_enough_credits &&
        !data.does_user_have_enough_credits_week_limit
      ) {
        setErrorMassageForTotalCredits("Insufficient Credit Points");
        setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
        shouldOpenModal = true;
      } else if (!data.does_owner_have_enough_credits) {
        setErrorMassageForTotalCredits("Insufficient Credit Points");
        shouldOpenModal = true;
      } else if (!data.does_user_have_enough_credits_week_limit) {
        setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
        shouldOpenModal = true;
      } else if (credits_to_be_deducted === 0) {
        setErrorMessage(
          "There are no blog URLs available for scraping at the moment."
        );
        shouldOpenModal = true;
      }

      // Check if modal should be opened or execute processScrapeAndUpdate
      if (shouldOpenModal) {
        set_show_should_we_classify_urls_modal(true);
      } else if (threshold_value > credits_to_be_deducted) {
        await handleClassifyMultiple(selected_blog_url_for_classify);

        await updateCreditPoints(credits_to_be_deducted);
      } else {
        set_show_should_we_classify_urls_modal(true);
      }
    }
  };

  const processClassifyProcessAndUpdate = async () => {
    await handleClassifyMultiple(selected_blog_url_for_classify);
    // if (!ClassifyProcessInProgress) {
    //   await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    // }
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    // setIsClassifying(false);
  };
  const saveProjectData = () => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, classify: 1 },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  return (
    <>
      <div className="classify_page_container">
        <div className="data_option_section_container">
          <TabContext value={value}>
            <Box sx={{ width: "51rem", bgcolor: "background.paper" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  component="div"
                  label={
                    <div className="classify_tab_label_text">
                      Previously Classified data
                    </div>
                  }
                  value="1"
                />
                <Tab
                  component="div"
                  label={
                    <div className="classify_tab_label_text">
                      Classify on existing data
                    </div>
                  }
                  value="2"
                />
                <Tab
                  component="div"
                  label={
                    <div className="classify_tab_label_text">
                      Classify on Uploaded data
                    </div>
                  }
                  value="3"
                />
              </TabList>
            </Box>

            <TabPanel value="1">
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button
                  component="label"
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  onClick={load_previously_classified_data}
                >
                  Load
                </Button>
              </div>
            </TabPanel>

            <TabPanel value="2">
              I want to classify blog urls which has similarity score between{" "}
              <TextField
                id="outlined-number"
                type="number"
                variant="standard"
                value={filter_similarity_score.from}
                onChange={(event) => {
                  var inputValue = event.target.value;
                  console.log(inputValue);
                  console.log(filter_similarity_score.to);
                  if (
                    inputValue >= 0 &&
                    inputValue < 100 &&
                    inputValue <= parseInt(filter_similarity_score.to)
                  ) {
                    console.log("condition pass");
                    set_filter_similarity_score({
                      from: inputValue,
                      to: filter_similarity_score.to,
                    });
                  }
                }}
                sx={{
                  width: "35px",
                }}
              />{" "}
              and{" "}
              <TextField
                id="outlined-number"
                type="number"
                variant="standard"
                value={filter_similarity_score.to}
                onChange={(event) => {
                  var inputValue = event.target.value;
                  console.log(filter_similarity_score.from);
                  console.log(inputValue);
                  if (
                    inputValue > 0 &&
                    inputValue <= 100 &&
                    inputValue >= parseInt(filter_similarity_score.from)
                  ) {
                    console.log("condition pass");
                    set_filter_similarity_score({
                      from: filter_similarity_score.from,
                      to: event.target.value,
                    });
                  }
                  //set_filter_similarity_score({from:filter_similarity_score.from, to:event.target.value});
                }}
                sx={{
                  width: "42px",
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                sx={{
                  marginRight: "10px",
                }}
                variant="text"
                color="secondary"
                size="small"
                onClick={() => {
                  get_identified_paragraphs_data_filtered();
                }}
              >
                load the data
              </Button>
            </TabPanel>

            <TabPanel value="3">
              <div className="fileUploadContainer">
                <Button
                  className="fileUploadButton"
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <Input
                    type="file"
                    id="file-input"
                    onChange={upload_user_uploaded_file}
                    key={file_upload_field_key}
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                  />
                </Button>
              </div>
            </TabPanel>
          </TabContext>
        </div>
        {isFileSelectVisible ? (
          <>
            <div className="uploadedFileContainer">
              <div>
                {upload_file_fields.map((field, index) => (
                  <div key={index} className="uploadedFileItem">
                    <span>
                      {
                        columns_to_select.find(
                          (column) => column.csv_field === field
                        )?.text
                      }
                    </span>
                    <FormControl>
                      <InputLabel>Select Field</InputLabel>
                      <Select
                        value={
                          columns_to_select.find(
                            (column) => column.csv_field === field
                          )?.key || ""
                        }
                        onChange={(e) =>
                          handleColumnSelection(e.target.value, field)
                        }
                      >
                        <MenuItem value="">
                          <em>Select Field</em>
                        </MenuItem>
                        {columns_to_select.map((option) => (
                          <MenuItem
                            key={option.key}
                            value={option.key}
                            disabled={columns_to_select.some(
                              (column) =>
                                column.key === option.key &&
                                column.csv_field !== ""
                            )}
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Choose a field to map</FormHelperText>
                    </FormControl>
                  </div>
                ))}
              </div>
            </div>

            <Button
              className="columnSelectionContainer"
              variant="contained"
              size="small"
              onClick={() => {
                load_user_uploaded_file_data();
              }}
              style={{
                marginTop: "2%",
              }}
              disabled={
                columns_to_select.some(
                  (column) =>
                    column.key === "blog_url" && column.csv_field !== ""
                )
                  ? false
                  : true
              }
            >
              load the data
            </Button>
          </>
        ) : (
          <></>
        )}
        <div className="data_table_section_container">
          {initial_data_loaded_identified_paragraphs === 1 ? (
            <div style={{ position: "relative", width: "100%", height: "70%" }}>
              {/* <Skeleton
                variant="rectangular"
                width="100%"
                height="50vh"
                // sx={{
                //   position: "absolute",
                //   backgroundColor: "#fff",
                //   color: "#000",
                //   zIndex: (theme) => theme.zIndex.drawer + 1,
                // }}
                open={initial_data_loaded_identified_paragraphs}
              /> */}
              <CircularProgress />
            </div>
          ) : (
            <>
              {isClassifying && (
                <div className="classification_progress_bar">
                  <LinearProgress
                    sx={{
                      height: "5px",
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#26d426",
                      },
                      "& .MuiLinearProgress-bar2Buffer": {
                        backgroundColor: "#f15757",
                      },
                      "& .css-8ub8io-MuiLinearProgress-dashed": {
                        backgroundImage:
                          "radial-gradient(rgb(255 255 255) 0%, rgb(0 127 255) 16%, #ffffff 42%);",
                      },
                    }}
                    variant="determinate"
                    value={progressPercentage}
                  />

                  <Typography
                    style={{
                      position: "absolute",
                      color: "#00f",
                      top: -22,
                      left: `${progressPercentage / 2}%`,
                      transform: "translateX(-25%)",
                    }}
                  >
                    {progressPercentage.toFixed(0)}%
                  </Typography>
                  <div className="classification_progress_bar_title">
                    {progressMessage}
                  </div>
                </div>
              )}
              {errorFetchMassage ? (
                <div className="alert_massage_container">
                  <Alert severity="error">{errorFetchMassage}</Alert>
                </div>
              ) : (
                <TableWithPagination
                file_name={"classify_prospect_page_types_data"}
                  data={filtered_scored_data}
                  fields={[
                    {
                      type: "id",
                      field: "search_result_id",
                      headerName: "",
                      minWidth: 1,
                      width: 1,
                      maxWidth: 90,
                    },
                    {
                      type: "field",
                      field: "client_url",
                      headerName: "Client URL",
                      minWidth: 200,
                      width: 350,
                      maxWidth: 1500,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "blog_url",
                      headerName: "Blog URL",
                      minWidth: 200,
                      width: 350,
                      maxWidth: 1000,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "blog_title",
                      headerName: "Blog Title",
                      minWidth: 200,
                      width: 350,
                      maxWidth: 1500,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "classification",
                      headerName: "Page Type",
                      minWidth: 100,
                      width: 300,
                      maxWidth: 1000,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "similarity_score",
                      headerName: "Similarity Score",
                      minWidth: 100,
                      width: 200,
                      maxWidth: 500,
                      search_type: "number_search",
                    },
                  ]}
                  bulk_actions={[
                    {
                      action: "save",
                      renderCell: (params) => {
                        return (
                         <>
                          {saving ? (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={true}
                              startIcon={<CircularProgress size={20} />}
                            >
                              Saving...
                            </Button>
                          ) : (
                            <Tooltip title="Save">
                            <IconButton
                              onClick={() => {
                                handleSaveMultiple(params);
                              }}
                            >
                              <FaSave className="table_action_icon_link_exchange_row" />
                            </IconButton>
                          </Tooltip>
                        )}
                         </>
                        );
                      },
                    },
                    {
                      action: "classify",
                      renderCell: (params) => {
                        return (
                          <Tooltip title="classify">
                            <IconButton
                              onClick={() => {
                                validate_classify_urls("Classify URL", params);
                                // handleClassifyMultiple(params);
                              }}
                            >
                              <FaProjectDiagram className="table_action_icon_link_exchange_row" />
                            </IconButton>
                          </Tooltip>
                        );
                      },
                    },
                  ]}
                />
              )}
            </>
          )}
        </div>
        <Modal
          open={show_should_we_classify_urls_modal}
          onClose={handle_should_we_classify_urls_close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box id="LinkExchange_model_box">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handle_should_we_classify_urls_close}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="LinkExchange_modal_header">Current Credits</div>
            <div className="LinkExchange_modal_text_heading">
              Before proceeding with this action, please review the following:
            </div>
            <div className="LinkExchange_modal_text">
              Available Credits:{" "}
              <div className="LinkExchange_modal_number">
                <div
                  style={{
                    color: errorMassageForTotalCredits ? "red" : "inherit",
                  }}
                >
                  {totalCreditPoints}
                </div>
              </div>
            </div>
            <div className="LinkExchange_modal_text">
              Weekly Credits Limit Left:{" "}
              <div className="LinkExchange_modal_number">
                <div
                  style={{
                    color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                  }}
                >
                  {creditPointsLimit}
                </div>
              </div>
            </div>
            {errorMessage !==
              "There are no blog URLs available for scraping at the moment." && (
              <div className="LinkExchange_modal_text_deduct">
                Performing this action will deduct{" "}
                <div className="LinkExchange_modal_deduct_number">
                  {" "}
                  {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                </div>{" "}
                from your balance.
              </div>
            )}
            {(errorMessage ||
              errorMassageForTotalCredits ||
              errorMassageForCreditWeekLimits) && (
              <div
                style={{
                  color: "red",
                  textAlign: "center",

                  fontWeight: "bolder",
                  fontFamily: "monospace",
                  fontSize: "larger",
                }}
              >
                <div>{errorMassageForTotalCredits}</div>
                <div>{errorMassageForCreditWeekLimits}</div>
                <div>{errorMessage}</div>
              </div>
            )}

            {!(
              errorMessage ||
              errorMassageForTotalCredits ||
              errorMassageForCreditWeekLimits
            ) && (
              <>
                <div className="LinkExchange_modal_header">After Deduction</div>
                <div className="LinkExchange_modal_text">
                  Balance Credits :
                  <div className="LinkExchange_modal_number">
                    {totalCreditAfterDeduction}
                  </div>
                </div>
                <div className="LinkExchange_modal_text">
                  Weekly Credits Limit Left :{" "}
                  <div className="LinkExchange_modal_number">
                    {creditWeekLimitAfterDeduction}
                  </div>
                </div>

                <div className="LinkExchange_form_group">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={processClassifyProcessAndUpdate}
                    color="primary"
                  >
                    Confirm Action
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={notificationMessage}
        />
      </div>
    </>
  );
};

export default ClassifyResultPagesComponent;
