import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/authentication/useAuth";
import { LOGIN_WITH_PASSCODE_API, LOGIN_API, getFullApiUrl } from "../../utils/apiPath";
import "./Login_passcode.css";
import LoginRegisterHeader from "../common/Header/LoginRegisterHeader/LoginRegisterHeader";
import {
  TextField,
  Box,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login_passcode = ({ toggleTheme, registrationSuccess }) => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState("");

  const handleLoginWithPassCode = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(getFullApiUrl(LOGIN_WITH_PASSCODE_API), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_name: email,
          passcode: passcode,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message === "Login successful") {
          console.log(data);
          login();
          // Extract user name and role name from response data
          const { user_id , role_name } = data;
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("role_name", role_name);
          localStorage.setItem("passcode", passcode);


          navigate("/");
        } else {
          setError(data.message || "Login failed");
          setLoading(false);
        }
      } else {
        setError("Login failed");
      }
    } catch (error) {
      setError("An unexpected error occurred");
      setLoading(false);
      setError(error.message);
    }
  };

  const [showPasscode, setShowPasscode] = useState(false);

  const handleTogglePasscodeVisibility = () => {
    setShowPasscode(!showPasscode);
  };

  return (
    <div className="page_Section">
      <div className="rightSection_withOutMenu">
        <div className="headerSection_withOutMenu">
          <LoginRegisterHeader onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection login_page">
          <div>
            <div className="login_container">
              <form className="form-container">
                {registrationSuccess && (
                  <p className="success-message">
                    Registration Successful! You can now log in.
                  </p>
                )}
                <div className="center-align">
                  <div className="container_header">Sign In with Passcode</div>
                  <div className="container_text">
                    Please fill this form to sign in to your account
                  </div>
                </div>
                <div className="input-container">
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "95%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      label="Email ID or User Name"
                      variant="standard"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input"
                      required
                    />
                  </Box>
                </div>
                <div className="input-container">
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "95%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      label="Passcode"
                      variant="standard"
                      type={showPasscode ? "text" : "passcode"}
                      value={passcode}
                      onChange={(e) => setPasscode(e.target.value)}
                      className="input"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle passcode visibility"
                              onClick={handleTogglePasscodeVisibility}
                            >
                              {showPasscode ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </div>
                {error && <p className="error-message">{error}</p>}
                <div className="button-container">
                  <button
                    type="button"
                    onClick={handleLoginWithPassCode}
                    disabled={loading}
                    className="button"
                  >
                    {loading ? "Logging In..." : "Sign In"}
                  </button>
                </div>
                <div className="container_text link-container">
                  Don't have an account?{" "}
                  <Link to="/register" >
                    Sign Up here
                  </Link>
                </div>
                <div className="link-container">
              Already have an account? <Link to="/login" >Login here</Link>
            </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_passcode;
