// ContactUs.js
import React from 'react';
import { Container, Typography, Box, Button, TextField, Grid, Checkbox } from '@mui/material';
import './ContactUs.css';
import Footer from '../../common/Footer/Footer';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material';
import useAuth from '../../../hooks/authentication/useAuth';


const ContactUs = ({ toggleTheme }) => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const { loggedIn } = useAuth();
  const theme = useTheme();
  return (
    <>
      <div id='contactUs'>
        <Typography id='contactHeading' variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <div className='contact_container'>
          {/* Left side - Company information */}
         
          
          <div className='contact_container_form'>

            <Box
              component='form'
              id='formContainer'
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label='Name'
                    name='name'
                    fullWidth
                    required
                    variant='standard'
              className='contactTextField'

                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label='Email'
                    name='email'
                    fullWidth
                    required
                    variant='standard'
              className='contactTextField'

                  />
                </Grid>
              </Grid>

              <TextField
                label=' Message...'
                name='message'
                fullWidth
                variant="standard"
                style={{
                  marginTop:"10px"
                }}
                required
                rows={2}
                multiline
              className='contactTextField'
              />
              <div className='checkbox_section'>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}

                /> <div>Subscribe to the Best Link Building Newsletter Ever</div>
              </div>
              {/* <Button type='submit' variant='contained' color='primary' id='submitButton'>
                submit
              </Button> */}
              <Button type="submit" style={{background:"#6DB2E9"}} id='submitButton' variant="contained">Submit</Button>
            </Box>
          </div>

          <div id='companyInfo'>
            <Box>
          
              <div className='contactInfo'>
                <Typography variant="body1" className='contactDetail'>
                  <EmailIcon className='contactIcon' />  linkomatic.ai@gmail.com
                </Typography>
              </div>
              <div className='contactInfo'>
                <Typography variant="body1" className='contactDetail'>
                  <PhoneIcon className='contactIcon' /> +91 923-946-1774 
                </Typography>
              </div>
              <div className='contactInfo'>
                <Typography variant="body1" className='contactDetail'>
                  <LocationOnIcon className='contactIcon' />26, Binodini Ave, beside babu da garage, Hatiara Extention, Hatiara, Dhankol, Kolkata, West Bengal 700157
                </Typography>
              </div>
              <div className='contactInfo'>
                <Typography variant="body1" className='contactDetail'>
                  <LanguageIcon className='contactIcon' ></LanguageIcon>  <a href="https://bhattacharjeegroup.com/software_development">Developed by Team Roboanalytics</a>
                </Typography>
              </div>
              <div className='contactInfo'>
                <Typography variant="body1" className='contactDetail'>
                  <LinkedInIcon className='contactIcon' /><a href="https://www.linkedin.com/company/example">linkedin.com/company/example</a>
                </Typography>
              </div>
            </Box>
          </div>
          </div>
          {/* Right side - Contact form */}

        
        {/* Map */}

        {/* <div id="mapContainer">
          <iframe
            title="Company Location"
            width="100%"
            height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14732.389228988559!2d88.4429931!3d22.612841200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f041e77c35b%3A0xcfa945e63f08fc75!2sRobo%20Analytics!5e0!3m2!1sen!2sin!4v1705593932787!5m2!1sen!2sin"
          />
        </div> */}
      </div>
      {/* <Footer /> */}

    </>
  );
};

export default ContactUs;
