import AdminSideBar from "../common/AdminSideBar/AdminSideBar";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";

// import AlertMessage from './AlertMessage';
import {
  GET_ALL_USERS,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_PASSWORD,
  UPDATE_USER,
  GET_ALL_ROLES,
  UPDATE_USER_ROLE,
  getFullApiUrl,
  UPDATE_USER_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTION,
  UPDATE_USER_CREDIT_POINTS,
  UPDATE_USER_NAME,
  UPDATE_PASSCODE,
  UPDATE_EMAIL_OF_USER,
} from "../../../utils/apiPath";
import { MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditNoteIcon from "@mui/icons-material/";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { LiaExpeditedssl } from "react-icons/lia";
import "./Users.css";
import { FaUserEdit } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import { LiaEdit } from "react-icons/lia";
import { RiFileEditFill } from "react-icons/ri";
import { BsFillCreditCardFill } from "react-icons/bs";
import { LiaUserEditSolid } from "react-icons/lia";
import { MdAlternateEmail } from "react-icons/md";
import Header from "../../common/Header/Header";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlinePassword } from "react-icons/md";

const Users = ({ toggleTheme }) => {
  const [user_data, set_user_data] = useState([]);
  const [selectedUserForDelete, setSelectedUserForDelete] = useState(null);
  const [selectedBulkUsersForDelete, setSelectedBulkUsersForDelete] = useState(
    []
  );
  const [selected_user, set_selected_user] = useState([]);

  const [showAddUserForm, setShowAddUserForm] = React.useState(false);
  const [showEditUserModal, setShowEditUserModal] = React.useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] =
    React.useState(false);
  const [showEditPasscodeModal, setShowEditPasscodeModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddUserForm(true);
  const handleAddClose = () => setShowAddUserForm(false);
  const handleEditOpen = () => setShowEditUserModal(true);
  const handleEditClose = () => setShowEditUserModal(false);
  const handleEditPasswordOpen = () => setShowEditPasswordModal(true);
  const handleEditPasswordClose = () => setShowEditPasswordModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);
  const [selectedUsersForDelete, setSelectedUsersForDelete] = useState([]); // State to store selected users for deletion
  // const [showDeleteBulkUserModal, setShowDeleteBluUserModal] = useState()
  const handleEditPasscodeOpen = () => setShowEditPasscodeModal(true);
  const handleEditPasscodeClose = () => setShowEditPasscodeModal(false);

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUsersForBulkDelete, setSelectedUsersForBulkDelete] = useState(
    []
  );
  // editRole name....
  const [showEditRoleModal, setShowEditRoleModal] = React.useState(false);
  const handleEditRoleOpen = () => setShowEditRoleModal(true);
  const handleEditRoleClose = () => setShowEditRoleModal(false);

  // subscription......

  const [showEditSubscriptionModal, setShowEditSubscriptionModal] =
    React.useState(false);
  const handleEditSubscriptionOpen = () => setShowEditSubscriptionModal(true);
  const handleEditSubscriptionClose = () => setShowEditSubscriptionModal(false);

  // Creditpoints....

  const [showEditCreditpointsModal, setShowEditCreditpointsModal] =
    React.useState(false);
  const handleEditCreditpointsOpen = () => setShowEditCreditpointsModal(true);
  const handleEditCreditpointsClose = () => setShowEditCreditpointsModal(false);
  const [passcode, setPasscode] = useState(""); // State to hold the passcode
  // State to store the selected user for deletion

  const [selectedUser, setSelectedUser] = useState({
    user_id: "",
    user_name: "",
    email_id: "",

    first_name: "",
    last_name: "",
  });

  const [newUserData, setNewUserData] = useState({
    user_name: "",
    email_id: "",
    password: "",
    role_name: "",
    subscription_id: "",
    credit_points: "",
    first_name: "",
    last_name: "",
  });
  const [select_user_password, set_select_user_password] = useState({
    email_id: "",
    password: "",
    confirm_password: "",
  });

  // edit role.......

  const [role, setRole] = useState([]);
  const [select_user_edit_role, set_select_user_edit_role] = useState({
    user_id: "",
    role_name: "",
  });
  const { user_id, role_name } = select_user_edit_role;
  const handleInputChangeRole = (e) => {
    set_select_user_edit_role({
      ...select_user_edit_role,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditRole = (role) => {
    set_select_user_edit_role({
      user_id: role.user_id,
      role_name: role.role_name,
    });
    setShowEditRoleModal(true);
  };

  const handleEditRoleChange = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_ROLE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(select_user_edit_role),
      });

      if (!response.ok) {
        throw new Error("Failed to update role");
      }

      console.log(select_user_edit_role, "role updated successfully");
      setShowEditRoleModal(false);

      // Refresh user list after editing
      fetchData();
    } catch (error) {
      console.error("Error updating role:", error.message);
    }
  };

  // edit subscription......

  const [subscription, setSubscription] = useState([]);
  const [select_user_edit_subscription, set_select_user_edit_subscription] =
    useState({
      user_id: "",
      subscription_id: "",
    });

  const handleInputChangesubscription = (e) => {
    const { name, value } = e.target;
    set_select_user_edit_subscription({
      ...select_user_edit_subscription,
      [name]: value,
    });
  };

  const handleEditSubscription = (subscription) => {
    set_select_user_edit_subscription({
      user_id: subscription.user_id,
      subscription_id: subscription.subscription_id,
    });
    setShowEditSubscriptionModal(true);
  };

  const handleEditSubscriptionChange = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_SUBSCRIPTION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription_id: select_user_edit_subscription.subscription_id,
          user_id: select_user_edit_subscription.user_id,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update subscription");
      }

      console.log(
        select_user_edit_subscription,
        "subscription updated successfully"
      );
      setShowEditSubscriptionModal(false);

      // Refresh user list after editing
      fetchData();
    } catch (error) {
      console.error("Error updating subscription:", error.message);
    }
  };

  // edit credit_points......

  // const [Creditpoints, setCreditpoints] = useState([]);
  const [select_user_edit_credit_points, set_select_user_edit_credit_points] =
    useState({
      user_id: "",
      credit_points: "",
    });

  const handleInputChangeCreditpoints = (e) => {
    const { name, value } = e.target;
    set_select_user_edit_credit_points({
      ...select_user_edit_credit_points,
      [name]: value,
    });
  };

  const handleEditCreditpoints = (Creditpoints) => {
    set_select_user_edit_credit_points({
      user_id: Creditpoints.user_id,
      credit_points: Creditpoints.credit_points,
    });
    setShowEditCreditpointsModal(true);
  };

  const handleEditCreditpointsChange = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_CREDIT_POINTS), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(select_user_edit_credit_points),
      });

      if (!response.ok) {
        throw new Error("Failed to update credit_points");
      }

      console.log(
        select_user_edit_credit_points,
        "credit_points updated successfully"
      );
      setShowEditCreditpointsModal(false);

      // Refresh user list after editing
      fetchData();
    } catch (error) {
      console.error("Error updating credit_points:", error.message);
    }
  };

  // hash password

  const [showPassword, setShowPassword] = useState(false);

  const handleInputChangePassword = (e) => {
    const { name, value } = e.target;
    set_select_user_password({ ...select_user_password, [name]: value });
  };

  const handleEditPassword = (user) => {
    set_select_user_password({
      email_id: user.email_id,
      password: "", // Reset to empty string
      confirm_password: "", // Reset to empty string
    });

    setShowEditPasswordModal(true);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = async () => {
    if (
      select_user_password.password !== select_user_password.confirm_password
    ) {
      console.error("Password and Confirm Password do not match");
      return;
    }

    // Add password strength validation logic here if needed

    try {
      const response = await fetch(getFullApiUrl(UPDATE_PASSWORD), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_id: select_user_password.email_id,
          password: select_user_password.password,
          confirm_password: select_user_password.confirm_password,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update password");
      }

      console.log("Password updated successfully");
      setShowEditPasswordModal(false);
      // Refresh user list after editing
      fetchData();
    } catch (error) {
      console.error("Error updating password:", error.message);
    }
  };

  // add user...

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
    setErrorMessage("");
  };

  const handleAddUserSubmit = async () => {
    try {
      const response = await fetch(getFullApiUrl(ADD_NEW_USER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUserData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new user");
      }

      const responseData = await response.json();
      const responseDataMessage = responseData.Message;
      // console.log(newUserData)
      console.log(responseData);
      if (responseData.message) {
        // Display success message from the backend
        handleAddClose(); // Close the modal after successful addition
        fetchData(); // Refresh user list
        setNewUserData({
          user_name: "",
          email_id: "",
          password: "",
          role_name: "",
          subscription_id: "",
          credit_points: "",
          first_name: "",
          last_name: "",
        });
      } else {
        throw new Error(responseDataMessage);
      }
    } catch (error) {
      console.error("Error adding new user:", error.message);
      alert(error.message);
    }
  };

  const fetchData = async () => {
    await fetchRoleList();
    const subscriptions = await fetchSubscriptionList();
    await fetchUserList(subscriptions);
  };

  useEffect(() => {
    fetchData();
  }, [1000]);

  // List....

  const fetchUserList = async (subscriptions) => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const usersListData = await response.json();

      // Iterate over each user and assign subscription_name
      const updatedUserData = await usersListData.map((userRow) => {
        const userSubscription = subscriptions.find(
          (sub) => sub.subscription_id === userRow.subscription_id
        );
        if (userSubscription) {
          userRow.subscription_name = userSubscription.subscription_name;
        }
        return userRow;
      });
      set_user_data(updatedUserData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRoleList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_ROLES));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const RoleListData = await response.json();
      setRole(RoleListData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubscriptionList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_SUBSCRIPTION));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const SubscriptionListData = await response.json();
      setSubscription(SubscriptionListData);
      return SubscriptionListData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Delete....

  const handleIndividualDeleteClick = (user) => {
    setSelectedUsersForDelete([user]); // Select only the clicked user
    setShowDeleteUserModal(true);
  };

  const handleBulkUserDeleteClick = async (params) => {
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allUsers = await response.json();

      const usersDetails = params.map((userId) => {
        const user = allUsers.find((u) => u.user_id === userId);
        return user ? `${user.user_name} (${user.email_id})` : "";
      });

      setSelectedUsersForBulkDelete(params);
      setSelectedUsersForDelete(usersDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedUsersForBulkDelete)
    try {
      if (
        !selectedUsersForBulkDelete ||
        !Array.isArray(selectedUsersForBulkDelete)
      ) {
        console.error("Invalid or empty params:", selectedUsersForBulkDelete);
        return;
      } else {
        await handleBulkDelete(selectedUsersForBulkDelete);
        // Close modal and refresh user list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting users in bulk:", error.message);
    }
  };
  const handleDeleteUserConfirm = async () => {
    try {
      await Promise.all(
        selectedUsersForDelete.map((user) => handleDeleteUser(user.user_id))
      );
      setSelectedUsersForDelete([]);
      setShowDeleteUserModal(false);
      fetchData(); // Refresh user list after deletion
    } catch (error) {
      console.error("Error deleting users:", error.message);
    }
  };
  const handleBulkDelete = async (selected_user) => {
    set_selected_user(selected_user);
    try {
      // Iterate over selected users and delete each one
      for (let userId of selected_user) {
        await handleDeleteUser(userId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the user list
      fetchData();
    } catch (error) {
      console.error("Error deleting users in bulk:", error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetch(getFullApiUrl(DELETE_USER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      console.log("User deleted successfully:", userId);
      // Refresh user list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error.message);
    }
  };
  // Edit user.....

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser({ ...selectedUser, [name]: value });
  };
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowEditUserModal(true);
  };

  const handleEditUserData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedUser),
      });

      if (!response.ok) {
        throw new Error("Failed to edit user");
      }

      console.log("User edited successfully");
      setShowEditUserModal(false);
      // Refresh user list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing user:", error.message);
    }
  };

  // const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const handleDeleteUserModalOpen = (user) => {
    setSelectedUserForDelete(user);
    setShowDeleteUserModal(true);
  };

  // Function to handle the closure of the delete modal
  const handleDeleteUserModalClose = () => {
    setShowDeleteUserModal(false);
  };

  // update....user_name....

  const [errorMessage, setErrorMessage] = useState("");
  const [showUpdateUserNameModal, setShowUpdateUserNameModal] =
    React.useState(false);
  const handleUpdateUserNameOpen = () => setShowUpdateUserNameModal(true);
  const handleUpdateUserNameClose = () => {
    setShowUpdateUserNameModal(false);
    setErrorMessage("");
  };

  const [select_userName, set_select_userName] = useState({
    user_id: "",
    user_name: "",
  });
  const handleUpdateUserName = (userName) => {
    set_select_userName(userName);
    setShowUpdateUserNameModal(true);
  };

  const handleUpdateInputUserName = (e) => {
    const { name, value } = e.target;
    set_select_userName({ ...select_userName, [name]: value });
    setErrorMessage("");
  };

  const handleUpdateUserNameData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_NAME), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(select_userName),
      });

      if (!response.ok) {
        throw new Error("Failed to update user name");
      }

      const responseData = await response.json();
      const message = responseData.message;

      if (message === "Users name updated successfully") {
        // Display success message from the backend
        handleUpdateUserNameClose(); // Close the modal after successful update
        fetchData(); // Refresh user list
      } else if (message === "user name already exists") {
        throw new Error("User name already exists");
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error updating user name:", error.message);
      setErrorMessage(error.message); // Set error message state
    }
  };

  // email
  const [select_user_email, set_select_user_email] = useState({
    email_id: "",
    user_id: "",
  });

  const [showEditEmailModal, setShowEditEmailModal] = React.useState(false);
  const handleEditEmailOpen = () => setShowEditEmailModal(true);
  const handleEditEmailClose = () => {
    setShowEditEmailModal(false);
    setErrorMessage("");
  };

  const handleInputChangeEmail = (e) => {
    const { name, value } = e.target;
    set_select_user_email({ ...select_user_email, [name]: value });
    setErrorMessage("");
  };

  const handleEditEmail = (user) => {
    set_select_user_email(user);

    setShowEditEmailModal(true);
  };

  const handleEmailChange = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_EMAIL_OF_USER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(select_user_email),
      });

      if (!response.ok) {
        throw new Error("Failed to update user name");
      }

      const responseData = await response.json();
      const message = responseData.message;

      if (message === "Email ID updated successfully") {
        // Display success message from the backend
        handleEditEmailClose(); // Close the modal after successful update
        fetchData(); // Refresh user list
      } else if (message === "Email ID already exists") {
        throw new Error("Email ID already exists");
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error updating user name:", error.message);
      setErrorMessage(error.message);
    }
  };

  // update passcode
  const [select_user_passcode, set_select_user_passcode] = useState({
    user_id: "",
    email_id: "",
    passcode: "",
  });
  const handleEditPasscode = (user) => {
    set_select_user_passcode({
      email_id: user.email_id,
      passcode: "", // Reset to empty string
    });

    setShowEditPasscodeModal(true);
  };
  const handleInputChangePasscode = (e) => {
    const { name, value } = e.target;
    set_select_user_passcode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGeneratePasscode = () => {
    // Generate a new passcode
    const newPasscode = generatePasscode();
    // Update the state with the new passcode while preserving other fields
    set_select_user_passcode((prevState) => ({
      ...prevState,
      passcode: newPasscode,
    }));
  };
  const generatePasscode = () => {
    const alphanumericChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const passcodeLength = 6; // You can adjust the length as needed
    let passcode = "";

    for (let i = 0; i < passcodeLength; i++) {
      const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
      passcode += alphanumericChars.charAt(randomIndex);
    }

    return passcode;
  };

  const handlePasscodeChange = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_PASSCODE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_id: select_user_passcode.email_id,
          passcode: select_user_passcode.passcode,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update password");
      }

      console.log("Password updated successfully");
      setShowEditPasscodeModal(false);
      // Refresh user list after editing
      fetchData();
    } catch (error) {
      console.error("Error updating password:", error.message);
    }
  };



  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="admin_page_heading">Users Management</div>
          <div className="table_section">
            <TableWithPagination
              data={user_data}
              fields={[
                
                {
                  type: "field",
                  field: "user_name",
                  headerName: "User Name",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "first_name",
                  headerName: "First Name",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "last_name",
                  search_type: "text_search",
                  headerName: "Last Name",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "email_id",
                  search_type: "text_search",
                  headerName: "Email ID",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "role_name",
                  headerName: "Role ",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "passcode",
                  search_type: "text_search",
                  headerName: "Passcode",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "subscription_name",
                  headerName: "Subscription ",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "field",
                  field: "credit_points",
                  headerName: "Credit ",
                  search_type: "number_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1800,
                },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  minWidth: 180,
                  width: 300,
                  maxWidth: 300,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Update Name">
                          <IconButton onClick={() => handleEditUser(params)}>
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Update User Name">
                          <IconButton
                            onClick={() => handleUpdateUserName(params)}
                          >
                            <LiaUserEditSolid className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Update Email">
                          <IconButton onClick={() => handleEditEmail(params)}>
                            <MdAlternateEmail className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Update Password">
                          <IconButton
                            onClick={() => handleEditPassword(params)}
                          >
                            <LiaExpeditedssl className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Update Passcode">
                          <IconButton
                            onClick={() => handleEditPasscode(params)}
                          >
                            <RiLockPasswordFill className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit Role Name">
                          <IconButton>
                            <FaUserEdit
                              onClick={() => handleEditRole(params)}
                              className="table_action_icon"
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Subscription Name">
                          <IconButton>
                            <RiFileEditFill
                              onClick={() => handleEditSubscription(params)}
                              className="table_action_icon"
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Creditpoints ">
                          <IconButton>
                            <BsFillCreditCardFill
                              onClick={() => handleEditCreditpoints(params)}
                              className="table_action_icon"
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
                {
                  type: "id",
                  field: "user_id",
                  headerName: "ID",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 1,
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add User">
                        <div
                          onClick={handleAddOpen}
                          className="bulk_classify_button download_icons"
                        >
                          <IoIosAddCircleOutline />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Users">
                        <div
                          onClick={() => handleBulkUserDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30}/>
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            {/* add user..... */}
            <Modal
              open={showAddUserForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New User</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="user_name"
                      label="User Name"
                      variant="standard"
                      name="user_name"
                      value={newUserData.user_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="first_name"
                      label="First Name"
                      variant="standard"
                      name="first_name"
                      value={newUserData.first_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="last_name"
                      label="Last Name"
                      variant="standard"
                      name="last_name"
                      value={newUserData.last_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="email_id"
                      label="Email ID"
                      variant="standard"
                      name="email_id"
                      value={newUserData.email_id}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="password"
                      label="Password"
                      variant="standard"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={newUserData.password}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    
                    <TextField
                      id="role_name"
                      select
                      label="Select Role"
                      name="role_name"
                      helperText="Please select your Role"
                      value={newUserData.role_name} // Make sure the value matches the selected role_name
                      onChange={handleNewInputChange}
                    >
                      {role.map((option) => (
                        <MenuItem
                          key={option.role_id}
                          value={option.role_name}
                          disabled={option.role_name === "super_admin"}
                        >
                          {option.role_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <TextField
                      id="subscription_id"
                      label="Subscription Name"
                      variant="standard"
                      type="text"
                      name="subscription_id"
                      value={newUserData.subscription_id}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    /> */}
                    <TextField
                      id="subscription_id"
                      select
                      label="Select Subscription"
                      name="subscription_id"
                      helperText="Please select your Subscription"
                      value={newUserData.subscription_id} // Make sure the value matches the selected role_name
                      onChange={handleNewInputChange}
                    >
                      {subscription.map((option) => (
                        <MenuItem
                          key={option.subscription_id}
                          value={option.subscription_id}
                        >
                          {option.subscription_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="credit_points"
                      label="Credit Points"
                      variant="standard"
                      type="number"
                      name="credit_points"
                      value={newUserData.credit_points}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <button
                    onClick={handleAddUserSubmit}
                    className="add-user-btn"
                  >
                    Add User
                  </button>
                </div>
              </Box>
            </Modal>
            {/* edit user.... */}
            <Modal
              open={showEditUserModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update User</div>
                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="text"
                      name="user_name"
                      //  placeholder="User Name"
                      id="user_name"
                      label="User Name"
                      value={selectedUser ? selectedUser.user_name : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                      variant="standard"
                    />
                  </Box> */}
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="text"
                      name="first_name"
                      id="first_name"
                      label="First Name"
                      value={selectedUser ? selectedUser.first_name : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                      variant="standard"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="text"
                      name="last_name"
                      //  placeholder="User Name"
                      id="last_name"
                      label="Last Name"
                      value={selectedUser ? selectedUser.last_name : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                      variant="standard"
                    />
                  </Box>
                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="email"
                      id="email_id"
                      label="Email ID"
                      name="email_id"
                      // placeholder="Email ID"
                      value={selectedUser ? selectedUser.email_id : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                      variant="standard"
                    />
                  </Box> */}
                  <button onClick={handleEditUserData} className="add-user-btn">
                    Update User
                  </button>
                </div>
              </Box>
            </Modal>
            {/* update password... */}
            <Modal
              open={showEditPasswordModal}
              onClose={handleEditPasswordClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Password</div>
                  <Box
                    component="form"
                    id="modal_box_form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type={showPassword ? "text" : "password"}
                      id="password"
                      label="New Password"
                      name="password"
                      value={select_user_password.password}
                      onChange={handleInputChangePassword}
                      className="modal_form_input"
                      variant="standard"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type={showPassword ? "text" : "password"}
                      id="confirm_password"
                      label="Confirm Password"
                      name="confirm_password"
                      value={select_user_password.confirm_password}
                      onChange={handleInputChangePassword}
                      className="modal_form_input"
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <button
                    onClick={handlePasswordChange}
                    className="add-user-btn"
                  >
                    Update Password
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update passcode */}
            <Modal
              open={showEditPasscodeModal}
              onClose={handleEditPasscodeClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Passcode</div>
                  <Box
                    component="form"
                    id="modal_box_form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="text"
                      id="passcode"
                      label="New Passcode"
                      name="passcode"
                      value={select_user_passcode.passcode} // Access passcode from state
                      onChange={handleInputChangePasscode} // Use handleInputChangePasscode function
                      className="modal_form_input"
                      variant="standard"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleGeneratePasscode}
                            >
                              <MdOutlinePassword />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <button
                    onClick={handlePasscodeChange}
                    className="add-user-btn"
                  >
                    Update Passcode
                  </button>
                </div>
              </Box>
            </Modal>

            {/* editRole */}

            <Modal
              open={showEditRoleModal}
              onClose={handleEditRoleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Role</div>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    textAlign={"center"}
                  >
                    <TextField
                      id="role_name"
                      select
                      label="Select Role"
                      name="role_name"
                      helperText="Please select your Role"
                      value={role_name} // Make sure the value matches the selected role_name
                      onChange={handleInputChangeRole}
                    >
                      {role.map((option) => (
                        <MenuItem
                          key={option.role_id}
                          value={option.role_name}
                          disabled={option.role_name === "super_admin"}
                        >
                          {option.role_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <button
                    onClick={handleEditRoleChange}
                    className="add-user-btn"
                  >
                    Update role
                  </button>
                </div>
              </Box>
            </Modal>

            {/* subscription...... */}

            <Modal
              open={showEditSubscriptionModal}
              onClose={handleEditSubscriptionClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Subscription</div>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    textAlign={"center"}
                  >
                    <TextField
                      id="subscription_id"
                      select
                      label="Select Subscription"
                      name="subscription_id"
                      helperText="Please select your Subscription"
                      value={select_user_edit_subscription.subscription_id} // Make sure the value matches the selected role_name
                      onChange={handleInputChangesubscription}
                    >
                      {subscription.map((option) => (
                        <MenuItem
                          key={option.subscription_id}
                          value={option.subscription_id}
                        >
                          {option.subscription_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <button
                    onClick={handleEditSubscriptionChange}
                    className="add-user-btn"
                  >
                    Update Subscription
                  </button>
                </div>
              </Box>
            </Modal>

            {/* credit_points..... */}

            <Modal
              open={showEditCreditpointsModal}
              onClose={handleEditCreditpointsClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Credit points</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                    
                    className="modal_form_input"
                      id="credit_points"
                      type="number"
                      variant="standard"
                      label="Select credit_points"
                      name="credit_points"
                      // helperText="Please select your credit_points"
                      value={select_user_edit_credit_points.credit_points} // Make sure the value matches the selected role_name
                      onChange={handleInputChangeCreditpoints}
                    >
                      {/* {subscription.map((option) => (
                        <MenuItem
                          key={option.subscriptions_id}
                          value={option.credit_points}
                        >
                          {option.credit_points}
                        </MenuItem>
                      ))} */}
                    </TextField>
                  </Box>

                  <button
                    onClick={handleEditCreditpointsChange}
                    className="add-user-btn"
                  >
                    Update Credit Points
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update user name */}

            <Modal
              open={showUpdateUserNameModal}
              onClose={handleUpdateUserNameClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update User Name</div>
                  <Box
                    component="form"
                    id="modal_box_form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="text"
                      id="user_name"
                      label="Update User Name"
                      name="user_name"
                      value={select_userName ? select_userName.user_name : ""}
                      onChange={handleUpdateInputUserName}
                      className="modal_form_input"
                      variant="standard"
                      required
                    />
                  </Box>
                  {errorMessage && (
                    <div
                      style={{
                        color: "red",
                        paddingLeft: "58px",
                        textAlign: "left",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <button
                    onClick={handleUpdateUserNameData}
                    className="add-user-btn"
                  >
                    Update User Name
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update email */}
            <Modal
              open={showEditEmailModal}
              onClose={handleEditEmailClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Email</div>
                  <Box
                    component="form"
                    id="modal_box_form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="email"
                      id="email_id"
                      label="Email ID"
                      name="email_id"
                      value={
                        select_user_email ? select_user_email.email_id : ""
                      }
                      onChange={handleInputChangeEmail}
                      className="modal_form_input"
                      variant="standard"
                      required
                    />
                  </Box>
                  {errorMessage && (
                    <div
                      style={{
                        color: "red",
                        paddingLeft: "58px",
                        textAlign: "left",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <button onClick={handleEmailChange} className="add-user-btn">
                    Update Email
                  </button>
                </div>
              </Box>
            </Modal>

            {/* delete..... */}

            <Modal
              open={showDeleteUserModal}
              onClose={handleDeleteUserModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete User(s)</div>
                  {selectedUsersForDelete.map((user) => (
                    <div key={user.user_id}>
                      <div className="modal_text">
                        User : {user.user_name} ({user.email_id})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected user(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleDeleteUserConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleDeleteUserModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete User(s)</div>
                  {selectedUsersForDelete.map((user) => (
                    <div key={user}>
                      <div className="modal_text">User Name: {user} </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected user(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
