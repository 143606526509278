import React, { useState, useEffect } from "react";
import {
  GET_CAMPAIGNS_DETAILS_OF_USER,
  GET_CREDIT_POINTS_OF_USER,
  GET_POINTS_DETAILS,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_USED_CREDIT_POINTS,
  getFullApiUrl,
} from "../../../utils/apiPath";
import { Button, Snackbar, Box } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import Modal from "@mui/material/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";

const PointDeductionComponent = ({
  user_id,
  campaign_id,
  points,
  label,
  onPointsDeducted,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  useEffect(() => {
    fetchpointsDetails();
  }, []); // Fetch credit point details on component mount

  const fetchpointsDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_POINTS_DETAILS)}?user_id=${localStorage.getItem(
          "user_id"
        )}&campaign_id=${campaign_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      

      setTotalCreditPoints(data.total_credit_points);
      setCreditPointsLimit(data.credit_points_week_limit);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeductPoints = async () => {
    try {
      if (totalCreditPoints < points) {
        setNotificationMessage("Insufficient Credit Points");
        setOpenSnackbar(true);
        return;
      }
      if (creditPointsLimit < points) {
        setNotificationMessage("Ask your Team Owner to refill coins");
        setOpenSnackbar(true);
        return;
      }
  
      const updatedPoints = creditPointsLimit - points;
  
      if (updatedPoints < 0) {
        setNotificationMessage(
          "Insufficient total credit points week limit. Ask your owner to refill this"
        );
        setOpenSnackbar(true);
      } else {
        // Proceed with deduction API call if onPointsDeducted function is implemented
        if (typeof onPointsDeducted === 'function') {
          // Call the callback function
          onPointsDeducted();
  
          // Deduct points
          const deductionResponse = await fetch(
            `${getFullApiUrl(
              UPDATE_CREDIT_POINT_OF_DEDUCTION
            )}?campaign_id=${campaign_id}&user_id=${user_id}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                points_deducted: points,
              }),
            }
          );
  
          if (!deductionResponse.ok) {
            throw new Error("Failed to deduct points");
          }
  
          setNotificationMessage("Points deducted successfully");
          handleBulkDeleteClose(); // Close the Modal after deduction
        } else {
          console.error("onPointsDeducted function is not implemented");
        }
      }
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };
  
  

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleBulkDeleteClose = () => {
    setShowBulkDeleteModal(false);
  };

  const handleShowBulkDeleteModal = async () => {

      setShowBulkDeleteModal(true);
   
  };

  return (
    <>
      {/* <Button
        variant="contained"
        startIcon={<RemoveIcon />}
        onClick={handleDeductPoints}
      >
        {label}
      </Button> */}
      <Button
        sx={{
          marginRight: "25px",
        }}
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleShowBulkDeleteModal}
      >
        {" "}
        {label}
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
      <Modal
        open={showBulkDeleteModal}
        onClose={handleBulkDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="model_box">
          <div className="modal_header">Process Details</div>
          <div className="modal_text">
            Before proceeding with this action, please review the following:
          </div>
          <div className="modal_text">
            Your Current Available Credit Points: {totalCreditPoints}
          </div>
          <div className="modal_text">
            Weekly Credit Points Limit Ledt: {creditPointsLimit}
          </div>
          <div className="modal_text">
            Performing this action will deduct {points} points from your
            balance.
          </div>

          <div className="form-group">
            <Button onClick={handleDeductPoints} color="error">
              Confirm Action
            </Button>
            <Button onClick={handleBulkDeleteClose}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PointDeductionComponent;
