import AdminSideBar from "../common/AdminSideBar/AdminSideBar";
import Header from "../../common/Header/Header";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import {
  GET_ALL_TEAM_MEMBERS,
  ADD_NEW_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  getFullApiUrl,
  GET_ALL_TEAM,
  GET_ALL_USERS,
  TEAM_NAME_FROM_ID,
  GET_ALL_TEAM_MEMBERS_OF_TEAMS,
  GET_TEAM_MEMBER_DETAILS,
  GET_TEAM_MEMBER_DESIGNATION,
  GET_WORKSPACES_OF_TEAM_MEMBER,
  GET_ALL_TEAM_MEMBERS_NOT_IN_TEAM,
} from "../../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { IoMdCloseCircle } from "react-icons/io";
import { LiaExpeditedssl } from "react-icons/lia";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

const MyTeamMembers = ({ toggleTheme }) => {
  const theme = useTheme();
  const { teamId } = useParams();
  const [team_members_data, set_team_members_data] = useState([]);
  const [selected_team_members, set_selected_team_members] = useState([]);
  const [selectedTeam_membersForDelete, setselectedTeam_membersForDelete] =
    useState([]); // State to store selected team_memberss for deletion
  const [showDeleteTeam_membersModal, setshowDeleteTeam_membersModal] =
    useState(false);
  const [
    selectedTeam_membersForBulkDelete,
    setselectedTeam_membersForBulkDelete,
  ] = useState([]);
  const [teamMemberDetails, setTeamMemberDetails] = useState({
    member_designation: "",
  });

  const fetchTeamMemberDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_TEAM_MEMBER_DESIGNATION
        )}?team_id=${teamId}&team_member_id=${localStorage.getItem("user_id")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const teamData = await response.json();
      console.log("API Response:", teamData); // Log API response
      setTeamMemberDetails(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTeamMemberDetails();
  }, []);

  const isOwnerOrManager =
    teamMemberDetails === "Owner" ||
    teamMemberDetails === "Manager";
  const isOwnerOrManagerOrLead =
    teamMemberDetails === "Owner" ||
    teamMemberDetails === "Manager" ||
    teamMemberDetails === "Lead";
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // State to store the selected team_members for deletion

  const [selectedTeam_members, setselectedTeam_members] = useState({
    team_id: teamId,
    team_member_id: "",
    member_designation: "",
    credit_points_week_limit: "",
    used_credit_points_this_week: "",
  });
  const [newTeam_membersData, setnewTeam_membersData] = useState({
    team_id: teamId,
    team_member_id: "",
    member_designation: "",
    credit_points_week_limit: "",
    used_credit_points_this_week: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedTeam_members({ ...selectedTeam_members, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setnewTeam_membersData({ ...newTeam_membersData, [name]: value });
  };
  //   add team_members...

  const handleAddTeam_membersSubmit = async () => {
    try {
      const response = await fetch(getFullApiUrl(ADD_NEW_TEAM_MEMBER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTeam_membersData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new team_members");
      }
      handleAddClose();

      setnewTeam_membersData({
        team_id: teamId,
        team_member_id: "",
        member_designation: "",
        credit_points_week_limit: "",
        used_credit_points_this: "",
      });
      fetchData();

      console.log("New Team_members added successfully");
    } catch (error) {
      console.error("Error adding new Team_members:", error.message);
    }
  };
  useEffect(() => {
    fetchTeamMemberDetails();
    fetchData();
  }, [1000]);

  // list.....

  const fetchData = async () => {
    const users = await fetchUserList();
    await fetchUserListToSearch()
    await fetch_my_team_members_list(users, teamId);
  };

  const fetch_my_team_members_list = async (users, teams_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_TEAM_MEMBERS_OF_TEAMS)}?team_id=${teams_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const workspaceListData = await response.json();
      const updatedWorkspaceListData = await workspaceListData.map(
        (workspaceRow) => {
          const userWorkspace = users.find(
            (u) => u.user_id === workspaceRow.team_member_id
          );
          if (userWorkspace) {
            workspaceRow.user_name = userWorkspace.user_name;
          }

          return workspaceRow;
        }
      );

      set_team_members_data(workspaceListData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [workspaces_for_team_member, set_workspaces_for_team_member] = useState([])
  // const fetch_workspace_of_team_member = async ( teams_id , team_member_id) => {
  //   try {
  //     const response = await fetch(
  //       `${getFullApiUrl(GET_WORKSPACES_OF_TEAM_MEMBER)}?team_id=${teams_id}&team_member_id=${team_member_id}`
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     set_workspaces_for_team_member(workspaceListData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };


  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamUserdata = await response.json();
      // set_user_data(teamUserdata);
      return teamUserdata;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUserListToSearch = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_ALL_TEAM_MEMBERS_NOT_IN_TEAM)}?team_id=${teamId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamUserdata = await response.json();
      set_user_data(teamUserdata);
      return teamUserdata;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  

  const handleIndividualDeleteClick = (
    team_members,
    isOwnerOrManagerOrLead
  ) => {
    // const memberDesignation = team_members.member_designation;

    if (
      team_members.member_designation === "Owner" ||
      team_members.member_designation === "Manager" ||
      team_members.member_designation === "Lead"
    ) {
      // Display a snackbar message informing the user that deletion is not allowed
      setNotificationMessage("Deletion not allowed for Owners or Managers or Leads");
      setOpenSnackbar(true);
    } else if (!isOwnerOrManagerOrLead) {
      // Display a snackbar message informing the user that they don't have access to delete
      setNotificationMessage("You don't have access to delete.");
      setOpenSnackbar(true);
    } else {
      // Proceed with deletion logic
      setselectedTeam_membersForDelete([team_members]); // Select only the clicked team_members
      setshowDeleteTeam_membersModal(true);
    }
  };

  const handleBulkTeam_membersDeleteClick = async (params) => {
    if (!isOwnerOrManager) {
      setNotificationMessage("You don't have access to delete multiple.");
      setOpenSnackbar(true);

      return;
    }
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAM_MEMBERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allteam_memberss = await response.json();

      // Extract team_members details including name and email
      const Team_membersDetails = params.map((team_membersId) => {
        const team_memberss = allteam_memberss.find(
          (u) => u.team_members_id === team_membersId
        );
        console.log("ss", team_memberss);
        return team_memberss
          ? `${team_memberss.team_member_id} (${team_memberss.team_members_description})`
          : "";
      });
      console.log("detail", Team_membersDetails);

      setselectedTeam_membersForBulkDelete(params);
      setselectedTeam_membersForDelete(Team_membersDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching team_members data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedTeam_membersForBulkDelete)
    try {
      if (
        !selectedTeam_membersForBulkDelete ||
        !Array.isArray(selectedTeam_membersForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedTeam_membersForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedTeam_membersForBulkDelete);
        // Close modal and refresh team_members list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting team_memberss in bulk:", error.message);
    }
  };

  const handleDeleteteam_membersConfirm = async () => {
    try {
      await Promise.all(
        selectedTeam_membersForDelete.map((team_members) =>
          handleDeleteteam_members(team_members.team_members_id)
        )
      );
      setselectedTeam_membersForDelete([]);
      setshowDeleteTeam_membersModal(false);
      fetchData(); // Refresh team_members list after deletion
    } catch (error) {
      console.error("Error deleting team_memberss:", error.message);
    }
  };

  const handleBulkDelete = async (selected_team_members) => {
    set_selected_team_members(selected_team_members);
    try {
      // Iterate over selected team_memberss and delete each one
      for (let team_membersId of selected_team_members) {
        await handleDeleteteam_members(team_membersId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the team_members list
      fetchData();
    } catch (error) {
      console.error("Error deleting team_memberss in bulk:", error.message);
    }
  };

  const handleDeleteteam_members = async (team_membersId) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          DELETE_TEAM_MEMBER
        )}?team_id=${teamId}&team_member_id=${team_membersId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        alert(data.error);

        throw new Error("Failed to delete team_members");
      }

      console.log("team_members deleted successfully:", team_membersId);
      // Refresh team_members list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting team_members:", error.message);
    }
  };
  // Edit..

  const handleEditteam_members = (team_members) => {
  
    setselectedTeam_members({
      ...selectedTeam_members,
      team_member_id: team_members.team_member_id,
      credit_points_week_limit: team_members.credit_points_week_limit,
      member_designation: team_members.member_designation,
    });

    setShowEditteam_membersModal(true);
  };

  const handleEditteam_membersData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_TEAM_MEMBER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTeam_members),
      });

      if (!response.ok) {
        throw new Error("Failed to edit team_members");
      }

      console.log("team_members edited successfully");
      setShowEditteam_membersModal(false);
      // Refresh team_members list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing team_members:", error.message);
    }
  };

  //model open

  const [showAddteam_membersForm, setShowAddteam_membersForm] =
    React.useState(false);
  const [showEditteam_membersModal, setShowEditteam_membersModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => {
  
    setShowAddteam_membersForm(true);
  };
  const handleAddClose = () => setShowAddteam_membersForm(false);
  const handleEditOpen = () => setShowEditteam_membersModal(true);
  const handleEditClose = () => setShowEditteam_membersModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const handleDeleteteam_membersModalClose = () => {
    setshowDeleteTeam_membersModal(false);
  };

  const [user_data, set_user_data] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [searchResults, setSearchResults] = useState([]);

  const handleNewUserSelect = (user) => {
    // Copy user_id from selectedUser to newworkspaceData
    setnewTeam_membersData({
      ...newTeam_membersData,
      team_member_id: user.team_member_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);
  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewTeam_membersData({
      ...newTeam_membersData,
      team_member_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  // const handleSnackbarOpen = (message) => {
  //   setSnackbarMessage(message);
  //   setSnackbarOpen(true);
  // };
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              underline="hover"
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/admin/teams"
              color="inherit"
              underline="hover"
            >
              My Teams
            </Link>
            <Typography color="textPrimary"> MyTeamMembers</Typography>
          </Breadcrumbs>
          <div className="admin_page_heading">
             Team Members
          </div>

          <div>
            <TableWithPagination
              data={team_members_data}
              fields={[
                {
                  type: "id",
                  field: "user_name",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 240,
                },
                {
                  type: "field",
                  field: "member_designation",
                  headerName: "Member Designation",
                  search_type: "designation_search",
                  minWidth: 235,
                  width: 240,
                  maxWidth: 250,
                },
                {
                  type: "field",
                  field: "credit_points_week_limit",
                  headerName: "Credit Points Week Limit",
                  search_type: "number_search",
                  minWidth: 270,
                  width: 270,
                  maxWidth: 290,
                },
                // {
                //   type: "field",
                //   field: "used_credit_points_this_week",
                //   headerName: "Used Credit Points This Week",
                //   minWidth: 280,
                //   width: 300,
                //   maxWidth: 310,
                // },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  minWidth: 120,
                  width: 120,
                  maxWidth: 200,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => {
                              handleEditteam_members(params);
                            }}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => {
                              handleIndividualDeleteClick(
                                params,
                                isOwnerOrManagerOrLead
                              );
                            }}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add New Team_members">
                        <div
                          onClick={() => {
                            handleAddOpen();
                          }}
                          className={`bulk_classify_button download_icons 
                          }`}
                        >
                          <RiAddLine />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Team_members">
                        <div
                          onClick={() => {
                            handleBulkTeam_membersDeleteClick(params);
                          }}
                          className={`bulk_delete_button download_icons `}
                        >
                          <MdOutlineDeleteSweep fontSize={30}/>
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={notificationMessage}
            />
            {/* Add team_members.... */}

            <Modal
              open={showAddteam_membersForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New Team Member</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Autocomplete
                      id="search_user"
                      options={searchResults}
                      getOptionLabel={(option) =>
                        `${option.user_name} - ${option.email_id}`
                      }
                      onChange={(event, newValue) => handleUserSelect(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search User"
                          variant="standard"
                          onChange={handleSearchInputChange}
                          className="modal_form_input"
                        />
                      )}
                    />
                  </Box>
                  {/* Render search results */}
                  {selectedUser && (
                    <div className="modal_text">
                      <Typography>
                        Selected User:
                        {selectedUser.user_name}
                      </Typography>

                      {/* Use the selected user's ID */}
                      <TextField
                        id="user_id"
                        variant="standard"
                        name="user_id"
                        value={newTeam_membersData.user_id}
                        disabled={!newTeam_membersData.user_id}
                        className="modal_form_input"
                      />
                    </div>
                  )}

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="member_designation"
                      select
                      label="Select Member Designation"
                      name="member_designation"
                      helperText="Please select your Member Designation"
                      value={newTeam_membersData.member_designation} // Make sure the value matches the selected role_name
                      onChange={handleNewInputChange}
                    >
                      <MenuItem value="Owner" disabled> Owner</MenuItem>
                      <MenuItem value="Lead"> Lead</MenuItem>
                      <MenuItem value="Manager"> Manager</MenuItem>
                      <MenuItem value="Member"> Member</MenuItem>
                    </TextField>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credit_points_week_limit"
                      label="Credit Points Week Limit"
                      variant="standard"
                      name="credit_points_week_limit"
                      value={newTeam_membersData.credit_points_week_limit}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="used_credit_points_this_week"
                      label="Used Credit Points This Week"
                      variant="standard"
                      name="used_credit_points_this_week"
                      value={newTeam_membersData.used_credit_points_this_week}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box> */}

                  <button
                    onClick={handleAddTeam_membersSubmit}
                    className="add-user-btn"
                  >
                    Add Team_members{" "}
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update.... */}

            <Modal
              open={showEditteam_membersModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Team_members</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="member_designation"
                      select
                      label="Select Member Designation"
                      name="member_designation"
                      helperText="Please select your Member Designation"
                      value={
                        selectedTeam_members
                          ? selectedTeam_members.member_designation
                          : ""
                      } // Make sure the value matches the selected role_name
                      onChange={handleInputChange}
                    >
                      <MenuItem value="Owner"> Owner</MenuItem>
                      <MenuItem value="Lead"> Lead</MenuItem>
                      <MenuItem value="Manager"> Manager</MenuItem>
                      <MenuItem value="Member"> Member</MenuItem>
                    </TextField>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credit_points_week_limit"
                      label="Credit Points Week Limit"
                      variant="standard"
                      name="credit_points_week_limit"
                      value={
                        selectedTeam_members
                          ? selectedTeam_members.credit_points_week_limit
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="used_credit_points_this_week"
                      label="Used Credit Points This Week"
                      variant="standard"
                      name="used_credit_points_this_week"
                      value={
                        selectedTeam_members
                          ? selectedTeam_members.used_credit_points_this_week
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box> */}

                  <button
                    onClick={handleEditteam_membersData}
                    className="add-user-btn"
                  >
                    Update Team_members
                  </button>
                </div>
              </Box>

              {/* delete... */}
            </Modal>

            {/* delete... */}

            <Modal
              open={showDeleteTeam_membersModal}
              onClose={handleDeleteteam_membersModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Team_members(s)</div>
                  {selectedTeam_membersForDelete.map((team_members) => (
                    <div key={team_members.team_members_id}>
                      <div className="modal_text">
                        Team_members: {team_members.team_member_id} (
                        {team_members.team_members_description})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team_members(s)?
                  </div>
                  <div className="form-group">
                    <Button
                      onClick={handleDeleteteam_membersConfirm}
                      color="error"
                    >
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleDeleteteam_membersModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Bulk delete.... */}

            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Team_members(s)</div>

                  {selectedTeam_membersForDelete.map((team_members) => (
                    <div key={team_members}>
                      <div className="modal_text">
                        Team_members Name: {team_members}{" "}
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team_members(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTeamMembers;
