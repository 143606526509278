import React from "react";
import ContactUs from "../LandingPage/ContactUsSection/ContactUs";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";

const CantactUsPage = ({toggleTheme}) => {
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
            <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default CantactUsPage;
