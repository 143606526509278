// LandingPage.js
import React , {useState , useEffect} from 'react';
import PrimarySection from '../LandingPage/PrimarySection/PrimarySection'
import useAuth from '../../hooks/authentication/useAuth';
import ProductSection from '../LandingPage/ProductSection/ProductSection';
import ProportionSection from '../LandingPage/ProportionSection/ProportionSection';
import AboutUs from '../LandingPage/AboutUsSection/AboutUs';
import FAQsSection from '../LandingPage/FAQsSection/FAQsSection'
import Footer from '../common/Footer/Footer';
import LeftSectionWithMenu from '../common/Header/LeftSectionWithMenu/LeftSectionWithMenu'
import Header from '../common/Header/Header';
import './LandingPage.css';
import { useTheme ,Button } from "@mui/material";
import ContactUs from './ContactUsSection/ContactUs';
import { GET_PASSCODE, getFullApiUrl } from '../../utils/apiPath';
import {useNavigate} from 'react-router-dom'
import Robo from '../common/Robo/Robo';


const LandingPage = ({ toggleTheme }) => {
  const { loggedIn , logout } = useAuth();
  const navigate = useNavigate();

  const goto = (page) =>
    {
      if (page == "link_insertion_campaign")
      {
        navigate(`/projects`);
      }
      else if (page == "link_exchange_campaign")
      {
        navigate(`/link_exchange_campaign`);
      }
  
    }
  
  
    
  const theme = useTheme();
  return (
    <div className='page_Section'>
      {loggedIn && (
        <div className='leftSection_withMenu'>
          <LeftSectionWithMenu />
        </div>
      )}
      <div className={loggedIn ? 'rightSection_withMenu' : 'rightSection_withMenu_without_loggedIn'}>
        <div className='headerSection_withMenu'>
          <Header onToggleTheme={toggleTheme} loggedIn={loggedIn} />
        </div>
        <div className={loggedIn ? 'bodySection' : 'bodySection_without_loggedIn'}>

          <PrimarySection />
          
          {/* <Robo /> */}
          {/* {loggedIn && <ProductSection />} */}
          {/* {loggedIn && <ProportionSection />} */}
          <AboutUs />
          <ContactUs />
          {/* <FAQsSection /> */}

          {/* <Footer /> */}
          {/* <TopButton /> */}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
