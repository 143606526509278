import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  BrowserRouter as Router,

} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Modal,
  Snackbar,
  CircularProgress,
  IconButton,
  Typography,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import {} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "./CheckActiveBloggersComponent.css";
import {
  BASE_API_URL_MAIN,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_POINTS_DETAILS,
  LOAD_PREVIOUSLY_CLASSIFIED_DATA,
  SAVE_BLOG_URLS_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  getFullApiUrl,
  CHECK_CREDIT_CONDITION,
} from "../../../utils/apiPath";

const CheckActiveBloggersComponentUsingApify = ({ props }) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [
    show_should_we_run_search_queries_apify_modal,
    set_show_should_we_run_search_queries_apify_modal,
  ] = useState(false);

  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }

      fetchpointsDetails();
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_run_search_queries_apify_close = () => {
    set_show_should_we_run_search_queries_apify_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setErrorMessage("");
  };

  const validate_run_search_queries_apify = async (button) => {
    const activeBloggerUrls = active_bloggers_urls
      .split("\n")
      .filter((url) => url.trim() !== "");
    const clientKeywords = keywords
      .split("\n")
      .filter((keyword) => keyword.trim() !== "");
    if (activeBloggerUrls.length === 0 || clientKeywords.length === 0) {
      setErrorMessage("Please enter active blogger URLs and client keywords.");
    }

    const button_point = await getCreditPointToDeductByButton(button);

    // const credits_to_be_deducted = parseInt(button_point);
    const credits_to_be_deducted = parseFloat((1 * parseFloat(button_point)).toFixed(3));
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
    }

    // If all conditions passed, open the modal
    set_show_should_we_run_search_queries_apify_modal(true);

    // Check if user has sufficient credit points
  };

  const [keywords, set_keywords] = useState("");
  const [active_bloggers_urls, set_active_bloggers_urls] = useState("");

  const [search_queries, set_search_queries] = useState("");

  const [search_results, set_search_results] = useState([]);
  const [blog_urls_list, set_blog_urls_list] = useState([]);

  const [blog_urls_data, set_blog_urls_data] = useState([]);

  const [saving, set_saving] = useState(false);

  useEffect(() => {}, []);

  const goto = (page) => {
    if (page == "check_active_bloggers") {
      //window.location.href = '/campaigns';
      //navigate(`/check_active_bloggers`);
      navigate(`/campaign/${campaignId}/check_active_bloggers`);
    }
  };

  const create_search_queries = () => {
    const activeBloggerUrls = active_bloggers_urls
      .split("\n")
      .filter((url) => url.trim() !== "");
    const clientKeywords = keywords
      .split("\n")
      .filter((keyword) => keyword.trim() !== "");
    if (activeBloggerUrls.length === 0 || clientKeywords.length === 0) {
      set_search_queries(
        "Please enter active blogger URLs and client keywords."
      );
      return;
    }
    let generatedQueries = "";
    activeBloggerUrls.forEach((url) => {
      clientKeywords.forEach((keyword) => {
        generatedQueries += `site:${url} ${keyword}\n`;
      });
    });
    if (generatedQueries.length > 0) {
      generatedQueries = generatedQueries.slice(0, -1);
    }
    set_search_queries(generatedQueries);
  };

  const run_search_queries_apify_update = () => {
    var request_data = {
      customDataFunction:
        "async ({ input, $, request, response, html }) => {\n  return {\n    pageTitle: $('title').text(),\n  };\n};",
      includeUnfilteredResults: false,
      maxPagesPerQuery: 1,
      mobileResults: false,
      queries: search_queries, // Change 'search_queries' to 'generated_queries'
      resultsPerPage: 5,
      saveHtml: false,
      saveHtmlToKeyValueStore: false,
    };

    fetch(
      "https://api.apify.com/v2/acts/apify~google-search-scraper/runs?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT",
      {
        method: "POST",
        body: JSON.stringify(request_data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((taskData) => {
        const checkTaskStatus = () => {
          fetch(
            `https://api.apify.com/v2/actor-runs/${taskData.data.id}?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT`
          )
            .then((response) => response.json())
            .then((taskStatus) => {
              if (taskStatus.data.status === "SUCCEEDED") {
                console.log("Task completed. Fetching results...");
                fetch(
                  `https://api.apify.com/v2/datasets/${taskStatus.data.defaultDatasetId}/items?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT`
                )
                  .then((response) => response.json())
                  .then((results) => {
                    const flattenedArray = results.reduce((acc, current) => {
                      return acc.concat(current.organicResults);
                    }, []);

                    console.log("converted to array");
                    console.log(flattenedArray);

                    const urlArray = flattenedArray.map((item) => item.url);
                    set_search_results(urlArray.join("\n"));

                    console.log("converted to array");
                    console.log(urlArray);
                    set_blog_urls_list(urlArray);
                  })
                  .catch((error) =>
                    console.error("Failed to fetch results:", error)
                  );
              } else if (
                taskStatus.data.status === "RUNNING" ||
                taskStatus.data.status === "RETRYING"
              ) {
                setTimeout(checkTaskStatus, 5000);
              } else {
                console.error("Task failed or has an unknown status.");
              }
            })
            .catch((error) =>
              console.error("Failed to check task status:", error)
            );
        };
        checkTaskStatus();
      })
      .catch((error) => {
        console.log("Data not got saved");
        //set_openDialog_MarkComplete(false);
      });
  };
  const processSearchQueryAndUpdate = async () => {
    await run_search_queries_apify_update();
    await updateCreditPoints(deductionPoints);
  };
  // serper
  const run_search_queries_2 = () => {
    var myHeaders = new Headers();
    myHeaders.append("X-API-KEY", "31fdf7ff042b58b13eb23a446d3df4be924ce6aa");
    myHeaders.append("Content-Type", "application/json");

    // Update the query parameters accordingly
    var raw = JSON.stringify({
      q: search_queries,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://google.serper.dev/search", requestOptions)
      .then((response) => response.json())
      .then((results) => {
        // Extract the organic results from the Serp API response
        const organicResults = results.organic;

        console.log("organicResults");
        console.log(organicResults);
        /*
          // Process the results as needed
          const flattenedArray = organicResults.reduce((acc, current) => {
            return acc.concat(current.sitelinks || []); // Adjust this based on your specific requirements
          }, []);

          console.log("flattenedArray");
          console.log(flattenedArray);
*/
        const urlArray = organicResults.map((item) => item.link);

        console.log("Converted to array");
        console.log(urlArray);

        // Assuming these are state-setting functions
        set_search_results(urlArray.join("\n"));
        set_blog_urls_list(urlArray);
      })
      .catch((error) => console.log("Error:", error));
  };

  const save_blog_urls = () => {
    //const existingUrlsData = rows.map((row) => row);
    const updatedUrls = search_results
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    var row_objects_array = [];
    var row_object = {};
    for (let i = 0; i < updatedUrls.length; i++) {
      row_object = {
        id: i + 1,
        url_id: i + 1,
        url: updatedUrls[i],
        scraped: 0,
        scrapedData: "",
      };
      row_objects_array.push(row_object);
    }
    console.log(row_objects_array);
    set_blog_urls_data(row_objects_array);
    saveRows(row_objects_array);
    saveProjectData(row_objects_array);
  };

  const saveRows = (data) => {
    set_saving(true);
    fetch(getFullApiUrl(LOAD_PREVIOUSLY_CLASSIFIED_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, blog_urls_data: data },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  const saveProjectData = (data) => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          check_active_bloggers_using_apify: 1,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  return (
    <>
      <div className="active_bloggers_Container">
        <div className="active_bloggers_Title">
          Search blogger for keywords with - Apify
        </div>
        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={keywords}
            onChange={(event) => set_keywords(event.target.value)}
            placeholder="Enter your client keywords"
            minRows={5}
            style={{ width: "100%" }}
            className="text_area_of_link_insertion"
          />
        </div>

        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={active_bloggers_urls}
            onChange={(event) => set_active_bloggers_urls(event.target.value)}
            placeholder="Enter active bloggers urls that we have"
            minRows={5}
            style={{ width: "100%" }}
            className="text_area_of_link_insertion"
          />
        </div>

        <Button
          sx={{
            marginRight: "10px",
          }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            create_search_queries();
          }}
        >
          Create Search Queries
        </Button>
        <br />
        <br />
        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={search_queries}
            onChange={(event) => set_search_queries(event.target.value)}
            placeholder="Here you would get generated search queries"
            minRows={5}
            style={{ width: "100%" }}
            className="text_area_of_link_insertion"
          />
        </div>

        <Button
          sx={{
            marginRight: "10px",
          }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={() =>
            validate_run_search_queries_apify("Run Search with Apify")
          }
        >
          Run Search
        </Button>

        <br />
        <br />

        <div className="check_active_blogger_text_area_container">
          <TextareaAutosize
            value={search_results}
            onChange={(event) => set_search_results(event.target.value)}
            placeholder="Here you would get generated search results"
            minRows={5}
            style={{ width: "100%" }}
            className="text_area_of_link_insertion"
          />
        </div>

        {saving == true ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => saveRows()}
            disabled
            endIcon={<CircularProgress size="20" />}
          >
            Saving . . . . . . . . . .
          </Button>
        ) : (
          <Button
            sx={{
              marginRight: "10px",
            }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              save_blog_urls();
            }}
          >
            Save
          </Button>
        )}
      </div>

      <Modal
        open={show_should_we_run_search_queries_apify_modal}
        onClose={handle_should_we_run_search_queries_apify_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handle_should_we_run_search_queries_apify_close}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits</div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
            </div>
          </div>
          {errorMessage !==
            "There are no blog URLs available for scraping at the moment." && (
            <div className="LinkExchange_modal_text_deduct">
              Performing this action will deduct{" "}
              <div className="LinkExchange_modal_deduct_number">
                {" "}
                {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
              </div>{" "}
              from your balance.
            </div>
          )}
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processSearchQueryAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
    </>
  );
};
export default CheckActiveBloggersComponentUsingApify;
