// PrimarySection.js
import React from 'react';
import { Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './PrimarySection.css';
import companyName from '../../../assets/images/List_Logo.png';

const PrimarySection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div className='primarySection' style={{
      background: `${theme.palette.background.default}`,
      color: `${theme.palette.text.primary}`,
    }}>
      <div className='contentContainer'>
        <div className='textBox'>
          <div className='textBox_heading'>Welcome to LiNKoMaTiC</div>
          <div className='textBox_desc'>
            <div className='textBox_desc_heading'>
              Streamline Link Inserts, <br /> Swaps and Prospecting
            </div>
            <div className='textBox_desc_slogan'>Pay as you go, No monthly fees</div>
            <div className='textBox_desc_button'>
              <Button
                style={{ background: "#6DB2E9", marginTop: "12px" }}
                variant="contained"
                onClick={handleRegisterClick}
              >
                Start For Free
              </Button>
            </div>
            <div className='textBox_desc_credit'>No credit card required</div>
          </div>
        </div>
        {/* <div className='aiImage'>
          <img 
            src={companyName}
            alt='name'
          />
        </div> */}
      </div>
    </div>
  );
};

export default PrimarySection;
