import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./CustomizedTables.css"; // Import CSS file

const CustomizedTables = ({ data, fieldsToShow }) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <TableContainer component={Paper} id="customized-table-container">
      <TableHead id="customized-table-header">
        <TableRow>
          {fieldsToShow.map((field, index) => (
            <TableCell key={index} id="customized-table-cell">
              {field}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody id="customized-table-body">
        {data.slice(0, 3).map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {fieldsToShow.map((field, cellIndex) => (
              <TableCell
                key={cellIndex}
                align="left"
                id="customized-table-cell"
              >
                {row[field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};

export default CustomizedTables;
