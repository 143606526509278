import * as React from 'react';
import { useState , useEffect } from 'react';
import { Button, IconButton, Tooltip , Typography , Divider , ListItemIcon , Menu , MenuItem , Avatar} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ThemeSwitcher from '../../../../components/ThemeSwitcher/ThemeSwitcher';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { MdSubscriptions } from "react-icons/md";
import useAuth from '../../../../hooks/authentication/useAuth';
import { useTheme } from '@mui/material';


const LoginRegisterHeader = ({ onToggleTheme }) => {
    const { loggedIn, logout } = useAuth();
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true); // State to track scroll position


    useEffect(() => {
        if (isLoggingOut) {
          window.location.reload();
        }
      }, [isLoggingOut]);

      const handleLogout = () => {
        setIsLoggingOut(true);
        logout();
    };
    const navigate = useNavigate();

    const subscriptionHandle = () => {
        navigate('/subscription');
    };

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleRegisterClick = () => {
        navigate('/register');
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const theme  = useTheme();

    return (
            <div className='headerSection ' >
                {loggedIn ? (
                    <div className='rightSection'>
                    <div className="customBoxStyles">
                        <IconButton>
                            <ThemeSwitcher onToggleTheme={onToggleTheme} />
                        </IconButton>
                    </div>
                </div>
                ) :(
                    <div className='rightSection '>
                    <div className="customBoxStyles header_buttons">
                        <IconButton>
                            <ThemeSwitcher onToggleTheme={onToggleTheme} />
                        </IconButton>
                    </div>
                </div>
                )}
            </div>
    );
}

export default LoginRegisterHeader;
