import React from 'react';
import { Link } from 'react-router-dom';
import LoginRegisterHeader from '../../common/Header/LoginRegisterHeader/LoginRegisterHeader';
import { useTheme } from '@mui/material';
import useAuth from '../../../hooks/authentication/useAuth';
import { MdLogin } from "react-icons/md";
import { FaHome } from "react-icons/fa";


const RegistrationSuccessPage = ({toggleTheme}) => {
    const { loggedIn } = useAuth()
    const theme = useTheme()
  return (
    <div className='page_Section'>
    <div className='rightSection_withMenu' style={{
      background: `${theme.palette.background.default}`,
      color: `${theme.palette.text.primary}`}}>
      <div className='headerSection_withMenu'>
        <LoginRegisterHeader onToggleTheme={toggleTheme}  loggedIn={loggedIn} />
      </div>
      <div className='bodySection'>
        <div className="registration-success-container">
            <div className="registration-success-content">
                <div className="registration-success-header">Registration Successful!</div>
                <div className="registration-success-text">Congratulations, your account has been successfully created.</div>
                <div className="registration-success-text">Now you're ready to explore and connect!</div>
                <div className="button-container">
                    <Link to="/login" className="login-button"><MdLogin className='icons' /></Link>
                    <Link to="/" className="home-button"><FaHome className='icons'/></Link>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RegistrationSuccessPage